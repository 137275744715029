import { getOrgsId } from '@wordup/apis'
import useSWR from 'swr'
import { apiCaller } from '../api'
import type { OrgTrackingTypes } from '@wordup/apis'

type GetOrgByIdResponseType = {
  data: {
    attributes: {
      name: string
    } & OrgTrackingTypes
    id: string
    type: string
  }
}

export const useSwrGetOrgById = (orgId: string) => {
  return useSWR(
    orgId
      ? getOrgsId({
          orgId,
        })
      : null,
    async params => {
      try {
        const res = await apiCaller<GetOrgByIdResponseType>(params)

        return res.data
      } catch (err) {
        return undefined
      }
    },
  )
}
