import styled from '@emotion/styled'
import { Icon } from '../icon'
import { format } from 'date-fns'

import themeVar from '../../theme/themeVar'
import { LangType } from '../auth-form/types'
import { OpenCourseLocalProvider, useOpenCourseLocale } from './local-provider'

export interface PropTypes {
  startsAt: string
  endsOn: string
  extraInfo?: string
  lang: LangType
  showTitle?: boolean
}

export const OpenCourseLocale = ({ lang = 'zh-TW', ...restProps }: PropTypes) => (
  <OpenCourseLocalProvider defaultLang={lang}>
    <OpenCourseInfo {...restProps} />
  </OpenCourseLocalProvider>
)

export const OpenCourseInfo = (props: Omit<PropTypes, 'lang'>) => {
  const { startsAt, endsOn, extraInfo, showTitle = true } = props
  const formatStartsAt = format(new Date(startsAt), 'yyyy/MM/dd HH:mma')
  const formatEndsOn = format(new Date(endsOn), 'yyyy/MM/dd')
  const { open_course } = useOpenCourseLocale()
  return (
    <Container>
      {showTitle ? <Title>{open_course.title}</Title> : null}
      <TimeInfoText>
        <Icon type='emoji_flags_filled' size={12} />
        {`${formatStartsAt} ${open_course.open_at}`}
      </TimeInfoText>
      <TimeInfoText>
        <Icon type='emoji_flags_filled' size={12} />
        {`${formatEndsOn} ${open_course.fully_open_at}`}
      </TimeInfoText>
      {extraInfo ? <ExtraInfo>{extraInfo}</ExtraInfo> : null}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 0.5rem;
  width: 100%;
  .icon {
    font-size: 0.75rem;
  }
`
const Title = styled.div`
  font-size: 1rem;
  font-weight: bold;
  color: ${themeVar.color.palette['grayscale-800']};
`

const TimeInfoText = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-size: 0.875rem;
  color: ${themeVar.color.palette['peach-500']};
  line-height: normal;
`

const ExtraInfo = styled(TimeInfoText)`
  color: ${themeVar.color.palette['grayscale-800']};
`
