import type { CouponListBlockType } from '../types'

export const createEmptyCouponListBlock = (): CouponListBlockType => ({
  id: '',
  type: 'coupon_list',
  attributes: {
    name: '',
    description: '',
    startsAt: '',
    endsAt: '',
    custom: { couponCodes: [], previews: [], scope: '' },
  },
})

export const createEmptyPreviewCouponListBlock = (): CouponListBlockType => ({
  id: '',
  type: 'coupon_list',
  attributes: {
    name: '',
    description: '',
    startsAt: '',
    endsAt: '',
    custom: {
      couponCodes: [],
      previews: [],
      scope: '',
    },
  },
})
