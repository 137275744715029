import {
  GetManageUserFeedbacksResponseTypes,
  getManageUserFeedbacks,
  GetManageUserFeedbacksQueryStringTypes,
} from '@wordup/apis'
import { useTranslation } from 'next-i18next'
import useSWR from 'swr'
import { apiCaller } from '../api'
import { Swal } from '@wordup/lib-portal/utils'
import axios from 'axios'

export const useGetManageUserFeedbacks = (props: GetManageUserFeedbacksQueryStringTypes) => {
  const { t } = useTranslation()
  const {
    data,
    mutate: mutateUserFeedbacks,
    isValidating,
  } = useSWR(
    props.orgId ? getManageUserFeedbacks(props) : null,
    async payloads => {
      try {
        const res = await apiCaller<GetManageUserFeedbacksResponseTypes>(payloads)
        return res
      } catch (err) {
        if (axios.isAxiosError(err)) {
          Swal.fire({
            icon: 'warning',
            text: err?.message,
            confirmButtonText: t('common.ok'),
          })
        }
      }
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateOnMount: true,
    },
  )
  const isLoading = isValidating
  const totalPages = data?.headers?.['total-pages']

  return {
    userFeedbacks: data?.data.data,
    ratingAvg: data?.data.meta.ratingAvg,
    mutateUserFeedbacks,
    totalPages,
    isLoading,
  }
}
