import { FieldArrayWithId } from 'react-hook-form'
import { BrandPageInputFormTypes, BrandPageBlockTypes, PreviewItemsFormTypes } from '../types'
import { snakeCase } from 'lodash-es'

export const getTypeText = (
  field: FieldArrayWithId<BrandPageInputFormTypes, 'inputItems'> | PreviewItemsFormTypes,
  t: any,
) => {
  const [[type]] = Object.entries(field) as [BrandPageBlockTypes, any][]
  const snakeCaseKeyType = snakeCase(type)

  switch (type) {
    case 'text':
    case 'brandInfo':
    case 'products':
    case 'gap':
    case 'couponList':
      return { text: t(`brand_management.${snakeCaseKeyType}.title`), type }
    case 'images':
      const imageFieldType = field.images?.type

      return { text: t(`brand_management.${snakeCaseKeyType}.${imageFieldType}`), type }
    case 'videos':
      const videoFieldType = field.videos?.type
      return { text: t(`brand_management.${snakeCaseKeyType}.${videoFieldType}`), type }

    default:
      return { text: '', type }
  }
}
