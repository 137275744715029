export const rateOptions = [
  { key: 0.5, text: '0.5x', value: 0.5 },
  { key: 0.8, text: '0.8x', value: 0.8 },
  { key: 1, text: '1.0x', value: 1 },
  { key: 1.2, text: '1.2x', value: 1.2 },
  { key: 1, text: '1.5x', value: 1.5 },
  { key: 2, text: '2.0x', value: 2 },
]

export const resolutionOptions = [
  { key: -1, text: 'auto', value: -1 },
  { key: 0, text: '400p', value: 0 },
  { key: 1, text: '480p', value: 1 },
  { key: 2, text: '640p', value: 2 },
  { key: 3, text: '960p', value: 3 },
  { key: 4, text: '1024p', value: 4 },
]
