import { getGtagBasicEventParams } from '@libs-components/features'
import type { Event } from './types'

const internal = {
  sendEvent: ({
    name,
    params = {},
    onSuccess,
    onFailure,
  }: {
    name: Event
    params?: object
    onSuccess?: () => void
    onFailure?: (error: unknown) => void
  }) => {
    console.log({ name, params }, 'sendEvent')
    try {
      window.gtag('event', name, { ...getGtagBasicEventParams(), ...params })

      if (typeof onSuccess === 'function') onSuccess()
    } catch (error) {
      if (typeof onFailure === 'function') onFailure(error)
    }
  },
}

export const sendGtagEvent = internal.sendEvent
