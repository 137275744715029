import { TextFieldProps } from '@mui/material'
import React from 'react'
import { twMerge } from 'tailwind-merge'

import { Textfield } from '.'
import WuButton, { WuButtonProps } from '../button'

type TextfieldWithButtonProps = TextFieldProps & {
  buttonProps?: WuButtonProps
  buttonText: string
}

const TextfieldWithButton = (props: TextfieldWithButtonProps, ref: React.Ref<any>) => {
  const { InputProps, buttonProps, buttonText, ...restProps } = props
  const { type, className } = buttonProps || {}

  return (
    <Textfield
      ref={ref}
      InputProps={{
        ...InputProps,
        endAdornment: (
          <WuButton
            className={twMerge(
              'h-full rounded-l-none min-w-[5.5rem] whitespace-nowrap min-h-[2.2694rem]',
              className,
            )}
            type={type ?? 'submit'}
            {...buttonProps}
          >
            {buttonText}
          </WuButton>
        ),
        style: {
          padding: 0,
          paddingLeft: '1rem',
        },
      }}
      {...restProps}
    />
  )
}

export default React.forwardRef(TextfieldWithButton)
