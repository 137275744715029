import type { Progress } from '@/components/video-player/types'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import ReactPlayer from 'react-player'
import { twMerge } from 'tailwind-merge'
import { useState, useEffect, useRef, forwardRef } from 'react'
import { isYoutubeUrl } from '../../utils'
import { rateOptions, resolutionOptions } from './constants'
import { handleVideoProgress, seekTo } from './utils'
import PlayButton from './player-button'

type PropTypes = {
  className?: string
  style?: Record<string, string>
  url: string
  imageUrl?: string
  muted?: boolean
  hideCustomControls?: boolean
  allowDelete?: boolean
  autoPlay?: boolean
  startTime?: string | number | null
  endTime?: string | number | null
  onPlay?: () => void
  onProgress?: (progress: Progress) => void
  onDuration?: (duration: number) => void
  onEnd?: () => void
  onDelete?: () => void
  onError?: () => void
  onReady?: () => void
  playerBtnClassName?: string
}

const VideoPlayer = forwardRef(
  (
    {
      className,
      style,
      url,
      imageUrl = '',
      muted = false,
      autoPlay = false,
      hideCustomControls = false,
      startTime,
      endTime,
      onPlay,
      onProgress,
      onDuration,
      onEnd,
      onError,
      onReady,
      playerBtnClassName,
    }: PropTypes,
    ref: React.ForwardedRef<ReactPlayer | null | undefined>,
  ) => {
    // if we don't set `playing` prop to be true with thumbnail enabled,
    // users need to click twice in order to play the video
    // -> first click to hide the thumbnail, load the video but not play
    // -> 2nd click to play
    const [rate, setRate] = useState<number>(1)
    const [quality, setQuality] = useState<number>(-1)
    const [hlsReady, setHlsReady] = useState<boolean>(false)
    const [isPlaying, setIsPlaying] = useState<boolean>(autoPlay || !!imageUrl)
    const playerRef = useRef<ReactPlayer | null | undefined>(null)

    const handleOnPause = () => {
      setIsPlaying(false)
    }

    const handleRateChange = (e: SelectChangeEvent<number>) => {
      setRate(e.target.value as number)
    }

    const changeResolution = (e: SelectChangeEvent<number>) => {
      setQuality(e.target.value as number)
      if (hlsReady && playerRef.current) {
        const internalPlayer = playerRef.current.getInternalPlayer('hls')
        if (internalPlayer) {
          internalPlayer.currentLevel = e.target.value
          internalPlayer.nextLevel = e.target.value
        }
      }
    }

    const setVideoTimeTo = (seconds: number) => {
      if (!playerRef?.current) return

      seekTo({
        playerRef,
        seconds,
      })
      setIsPlaying(true)
    }

    const handleProgress = (progress: Progress) => {
      handleVideoProgress({
        progress,
        playerRef,
        startTime: startTime ? Number(startTime) : null,
        endTime: endTime ? Number(endTime) : null,
        onProgress,
      })
    }

    const handleDuration = (seconds: number) => {
      if (typeof onDuration === 'function') onDuration(seconds)
    }

    useEffect(() => {
      if (!playerRef?.current) return
      if (startTime && endTime) {
        setVideoTimeTo(Number(startTime))
      }
    }, [startTime, endTime])

    return (
      <div style={style} className={twMerge('group relative pt-[56.25%]', className)}>
        {!hideCustomControls && url && !isYoutubeUrl(url) && (
          <div className='absolute right-4 top-3 z-20 text-white'>
            <div className='flex gap-x-2 rounded-sm bg-white p-2 opacity-0 transition-opacity duration-300 group-hover:opacity-100'>
              <FormControl>
                <InputLabel id='resolution-label'>畫質</InputLabel>
                <Select
                  size='small'
                  labelId='resolution-label'
                  label='畫質'
                  value={quality}
                  onChange={changeResolution}
                >
                  {resolutionOptions.map(opt => (
                    <MenuItem key={opt.key} value={opt.value}>
                      {opt.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl>
                <InputLabel id='rate'>播放速度</InputLabel>
                <Select
                  size='small'
                  labelId='rate-label'
                  label='播放速度'
                  value={rate}
                  onChange={handleRateChange}
                >
                  {rateOptions.map(opt => (
                    <MenuItem key={opt.key} value={opt.value}>
                      {opt.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        )}

        <ReactPlayer
          ref={player => {
            playerRef.current = player
            if (typeof ref === 'function') {
              ref(player)
            } else {
              if (ref !== null && ref.current !== undefined) {
                ref.current = player
              }
            }
          }}
          playbackRate={rate}
          url={url}
          controls
          pip
          width='100%'
          height='100%'
          className='absolute left-0 top-0'
          light={imageUrl}
          playing={isPlaying}
          onPause={handleOnPause}
          onPlay={onPlay}
          onProgress={handleProgress}
          onDuration={handleDuration}
          onError={onError}
          onEnded={onEnd}
          // according to the browser's spec, the autoPlay should go with muted
          // However, since the autoPlay seems doesn't work for Android devices now,
          // we don't use the `muted || autoPlay` yet
          muted={muted}
          playIcon={<PlayButton playerBtnClassName={playerBtnClassName} />}
          config={{
            file: {
              attributes: {
                controlsList: 'nodownload noplaybackrate',
              },
            },
          }}
          onReady={() => {
            setHlsReady(true)
            if (onReady) {
              onReady()
            }
          }}
        />
      </div>
    )
  },
)

VideoPlayer.displayName = 'VideoPlayer'

export { VideoPlayer }
