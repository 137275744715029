import { Icon } from '@libs-components/components/icon'

type RatingsProps = {
  rating: number
}

const Ratings = ({ rating }: RatingsProps) => {
  const stars = Array.from({ length: 5 }, (_, index) => index + 1)

  return (
    <div className='flex items-center justify-center gap-1'>
      {stars.map(star => (
        <Icon
          key={star}
          type='star_filled'
          color={star <= rating ? 'yellow-500' : 'grayscale-300'}
        />
      ))}
    </div>
  )
}

export { Ratings }
