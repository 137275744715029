import { Controller, useFormContext } from 'react-hook-form'
import type { BrandPageInputFormTypes } from '../../types'

import { useTranslation } from 'next-i18next'

import { Autocomplete } from '@wordup/lib-portal/components'
import { toast } from '@libs-components/index'
import { apiCaller } from '../../../api'
import { getManageCouponsById, GetManageCouponResponse } from '@wordup/apis'
import axios from 'axios'

type CouponListTypes = {
  index: number
}

export const CouponListForm = ({ index }: CouponListTypes) => {
  const { t } = useTranslation()
  const { control, setValue, getValues } = useFormContext<BrandPageInputFormTypes>()

  const checkCoupon = async (couponCode: string): Promise<boolean> => {
    try {
      const res = await apiCaller<GetManageCouponResponse>(getManageCouponsById(couponCode))

      if (res.status === 200) {
        const currentCouponPreviewCodes =
          getValues(`previewItems.${index}.couponList.attributes.custom.previews`) || []
        const updatedCouponCodes = [...currentCouponPreviewCodes, res.data.data].filter(
          (coupon, idx, self) =>
            self.findIndex(c => c.attributes.code === coupon.attributes.code) === idx,
        )

        setValue(`previewItems.${index}.couponList.attributes.custom.previews`, updatedCouponCodes)
        return true
      }
    } catch (error) {
      handleCouponError(error, couponCode)
      return false
    }

    return false
  }

  const handleCouponError = (error: unknown, couponCode: string) => {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.errors?.[0]?.detail || 'An error occurred'
      toast.error({ message: `${errorMessage} ${couponCode}` })
    } else {
      toast.error({ message: `Unexpected error: ${couponCode}` })
    }
  }

  return (
    <Controller
      name={`inputItems.${index}.couponList.attributes.custom.couponCodes`}
      control={control}
      render={({ field }) => {
        const previewPath = `previewItems.${index}.couponList.attributes.custom` as const

        const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.key === 'Enter') {
            const value = (event.target as HTMLInputElement).value.trim()
            const currentValues = field.value || []
            if (currentValues.includes(value)) {
              toast.error({ message: t('brand_management.coupon_list.duplicate_codes') })
            }
          }
        }

        const handleChange = async (
          _e: React.ChangeEvent<{}>,
          value:
            | NonNullable<string | { [key: string]: any }>
            | (string | { [key: string]: any })[]
            | null,
          reason: string,
        ) => {
          if (!Array.isArray(value)) return

          const currentCouponCodes = getValues(previewPath)

          if (!value.length) {
            setValue(previewPath, { couponCodes: [], previews: [], scope: '' })
            field.onChange(value)
            return
          }

          switch (reason) {
            case 'createOption': {
              const lastValue = value[value.length - 1]
              const isValid = await checkCoupon(lastValue.trim())
              if (!isValid) return
              break
            }

            case 'removeOption': {
              const updatedCouponCodes = currentCouponCodes.couponCodes.filter(coupon =>
                value.includes(coupon),
              )
              const previewCouponCodes = currentCouponCodes.previews.filter(coupon =>
                value.includes(coupon.attributes.code),
              )

              setValue(previewPath, {
                scope: '',
                previews: previewCouponCodes,
                couponCodes: updatedCouponCodes,
              })
              break
            }

            default:
              break
          }

          field.onChange(value)
        }

        return (
          <Autocomplete
            {...field}
            options={[]}
            onKeyUp={handleKeyUp}
            onChange={handleChange}
            value={field.value || []}
            textfieldProps={{
              placeholder: t('brand_management.coupon_list.fill_in_coupon_code'),
              label: t('brand_management.coupon_list.title'),
              helperText: t('brand_management.coupon_list.helper_text'),
            }}
            multiple
            freeSolo
          />
        )
      }}
    />
  )
}
