import { twMerge } from 'tailwind-merge'
import { Text } from '@libs-components/components/text'
import { Icon, IconTypes } from '@libs-components/components/icon'

export const EditorToolButton = ({
  iconProps,
  text,
  onClick,
  className,
}: {
  onClick: () => void
  iconProps: IconTypes
  text: string
  className?: string
}) => (
  <button
    className={twMerge(
      'border-grayscale-100 flex w-full flex-col items-center justify-center rounded-lg border-[1px] py-4',
      className,
    )}
    onClick={onClick}
  >
    <Icon {...iconProps} />
    <Text variant='body_m_500'>{text}</Text>
  </button>
)
