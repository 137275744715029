import ReactPlayer from 'react-player'
import { Progress } from '@/components/video-player/types'

export const onVideoPlayerProgress = ({
  playedSeconds,
  startTime,
  endTime,
  playerRef,
}: {
  playedSeconds: number
  startTime: number | null
  endTime: number | null
  playerRef: React.MutableRefObject<ReactPlayer | null | undefined>
}) => {
  if (startTime && endTime) {
    if (playedSeconds >= Number(endTime) || playedSeconds < Number(startTime)) {
      seekTo({
        playerRef,
        seconds: Number(startTime),
      })
    }
  } else if (startTime) {
    if (playedSeconds < Number(startTime)) {
      seekTo({
        playerRef,
        seconds: Number(startTime),
      })
    }
  } else if (endTime) {
    if (playedSeconds >= Number(endTime)) {
      seekTo({
        playerRef,
        seconds: 0,
      })
    }
  }
}

export const seekTo = ({
  playerRef,
  seconds,
}: {
  playerRef: React.MutableRefObject<ReactPlayer | null | undefined>
  seconds: number
}) => {
  if (!playerRef?.current) return

  const targetSeconds = seconds === 0 ? 0.001 : seconds

  playerRef.current.seekTo(targetSeconds, 'seconds')
}

export const handleVideoProgress = ({
  progress,
  onProgress,
  startTime,
  endTime,
  playerRef,
}: {
  progress: Progress
  onProgress?: (progress: Progress) => void
  startTime?: number | null
  endTime?: number | null
  playerRef: React.MutableRefObject<ReactPlayer | null | undefined>
}) => {
  const { playedSeconds } = progress

  onVideoPlayerProgress({
    playedSeconds,
    startTime: startTime ? Number(startTime) : null,
    endTime: endTime ? Number(endTime) : null,
    playerRef,
  })

  if (typeof onProgress === 'function') onProgress(progress)
}
