import { Text } from '@libs-components/components/text'
import DraggableItems from '../../../components/draggable-items'
import { useTranslation } from 'next-i18next'
import { DragAndDropBlockPropsTypes } from '../use-brand-page-init'
import { getTypeText } from '../utils'
import { WuTooltip } from '@wordup/lib-portal/components'
import { format } from 'date-fns'
import { FieldArrayWithId } from 'react-hook-form'
import { BrandPageInputFormTypes, BrandPageBlockTypes } from '../types'

const DragAndDropBlock = ({
  dragAndDropBlockProps,
}: {
  dragAndDropBlockProps: DragAndDropBlockPropsTypes
}) => {
  const { t } = useTranslation()

  const {
    inputItemsFields,
    handleMoveBlockItem,
    selectedBlockIndex,
    handleSelectedBlock,
    previewItemsFields,
  } = dragAndDropBlockProps

  const handleDragEnd = ({ fromIndex, toIndex }: { fromIndex: number; toIndex: number }) => {
    if (toIndex === undefined) return

    handleMoveBlockItem(fromIndex, toIndex)

    if (selectedBlockIndex === fromIndex || selectedBlockIndex === toIndex) {
      handleSelectedBlock(selectedBlockIndex === fromIndex ? toIndex : fromIndex, false)
    }
  }

  const renderTooltip = (type: BrandPageBlockTypes, index: number) => {
    if (!type || type === 'brandInfo' || type === 'gap') return null

    const item = previewItemsFields[index]?.[type]
    if (!item?.attributes) return null

    const { startsAt, endsAt } = item.attributes
    if (!startsAt && !endsAt) return null

    return (
      <WuTooltip
        title={
          <div className='flex flex-col gap-1'>
            {startsAt && (
              <Text variant='body_m_400' color='grayscale-000'>
                {`${t('brand_management.publish_at')}: ${format(
                  new Date(startsAt),
                  'yyyy-MM-dd kk:mm',
                )}`}
              </Text>
            )}
            {endsAt && (
              <Text variant='body_m_400' color='grayscale-000'>
                {`${t('brand_management.unpublish_at')}: ${format(
                  new Date(endsAt),
                  'yyyy-MM-dd kk:mm',
                )}`}
              </Text>
            )}
          </div>
        }
        type='hover'
      />
    )
  }
  const renderItem = ({
    item,
    index,
  }: {
    item: FieldArrayWithId<BrandPageInputFormTypes, 'inputItems'>
    index: number
  }) => {
    const { text, type } = getTypeText(item, t)

    return (
      <div
        className='flex w-full items-center justify-between gap-x-2 rounded-lg pr-2'
        key={item.id}
      >
        <Text variant='body_m_500'>{text}</Text>
        {renderTooltip(type, index)}
      </div>
    )
  }

  return (
    <div className='flex flex-col gap-2'>
      <Text variant='body_m_500'>{t('brand_management.block_order')}</Text>

      <DraggableItems
        items={inputItemsFields}
        onDragEnd={handleDragEnd}
        direction='vertical'
        dragDisabled={false}
        containerClassName='w-full'
        clickProps={{
          onClick: ({ index }) => handleSelectedBlock(index, true),
          clickedIndex: selectedBlockIndex,
        }}
        dragButtonContainerClassName={inputItemsFields.length === 0 ? 'hidden' : 'block'}
        hideDragButtons={[0]}
        renderItem={renderItem}
        draggableBlockClassName='hover:bg-grayscale-100 rounded-lg'
      />
    </div>
  )
}

export default DragAndDropBlock
