import { FILE_SIZE_UNITS, FileSizeUnitTypes } from '../constants'

export const getBytesFromFileSize = ([value, unit]: [
  number,
  FileSizeUnitTypes
]) => {
  const index = FILE_SIZE_UNITS.indexOf(unit)

  if (index === -1) {
    throw new Error(`Invalid size unit: ${unit}`)
  }

  return value * Math.pow(1024, index)
}
