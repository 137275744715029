import { Refresh } from '@mui/icons-material'
import { useState } from 'react'
import { useTranslation } from 'next-i18next'
import React from 'react'

import WuButton from '../button'
import { Textfield } from '../wu-inputs'

interface ThirdPartyVideoUrlTextfieldProps {
  initialValue: string
  onUpdate: (url: string) => void
  label?: string
  required?: boolean
  placeholder?: string
  disabled?: boolean
  error?: boolean
  helperText?: string
}

const ThirdPartyVideoUrlTextfield = ({
  initialValue,
  onUpdate,
  label,
  required,
  placeholder,
  disabled,
  error,
  helperText,
}: ThirdPartyVideoUrlTextfieldProps) => {
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState(initialValue ?? '')
  return (
    <div className='flex flex-col h-full w-full justify-between gap-2'>
      <Textfield
        placeholder={placeholder ?? t('material.video.text_field_plhdr')}
        onChange={e => {
          setInputValue(e.target.value)
        }}
        label={label ?? t('common.video_link')}
        value={inputValue}
        required={required}
        disabled={disabled}
        error={error}
        helperText={helperText}
      />

      <div className='flex w-full justify-end'>
        <WuButton
          className='text-grayscale-000 bg-grayscale-500 hover:bg-grayscale-600'
          startIcon={<Refresh />}
          onClick={() => {
            onUpdate(inputValue)
          }}
          disabled={disabled}
        >
          {t('material.video.update_URL')}
        </WuButton>
      </div>
    </div>
  )
}

export default ThirdPartyVideoUrlTextfield
