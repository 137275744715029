import {
  GetManageTagRatingsPayloadTypes,
  GetManageTagRatingsResponse,
  getManageTagRatings,
} from '@wordup/apis'
import { useTranslation } from 'next-i18next'
import useSWR from 'swr'
import { apiCaller } from '../api'
import { Swal } from '@wordup/lib-portal/utils'
import axios from 'axios'

export const useManageTagRatings = (props: GetManageTagRatingsPayloadTypes) => {
  const { t } = useTranslation()
  const {
    data,
    mutate: mutateTagRatings,
    isValidating,
  } = useSWR(
    props.tagName ? getManageTagRatings(props) : null,
    async payloads => {
      try {
        const res = await apiCaller<GetManageTagRatingsResponse>(payloads)
        return res
      } catch (err) {
        if (axios.isAxiosError(err)) {
          Swal.fire({
            icon: 'warning',
            text: err?.message,
            confirmButtonText: t('common.ok'),
          })
        }
      }
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateOnMount: true,
    },
  )
  const isLoading = !data?.data.data && isValidating
  const totalPages = data?.headers?.['total-pages']

  return { tagRatings: data?.data.data, mutateTagRatings, totalPages, isLoading }
}
