import { Skeleton } from '../../../skeleton'

const SwiperWithTitleAndControlsSkeleton = () => {
  return (
    <div className='space-y-4'>
      <div className='flex items-center justify-between'>
        <Skeleton className='h-8 w-1/4' />
        <div className='flex items-center justify-center gap-x-3'>
          <Skeleton className='h-6 w-6 rounded-full' />
          <Skeleton className='h-6 w-6 rounded-full' />
        </div>
      </div>
      <Skeleton className='h-8 w-3/4' />
      <Skeleton className='h-[400px]' />
      <Skeleton className='mx-auto h-8 w-1/2' />
      <Skeleton className='h-8 text-center' />
    </div>
  )
}

export { SwiperWithTitleAndControlsSkeleton }
