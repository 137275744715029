import dynamic from 'next/dynamic'

import { WuTooltipProps } from './types'

const WuIntercom = dynamic(() => import('./intercom'), { ssr: false })
const WuPopUp = dynamic(() => import('./pop-up'), { ssr: false })
const WuHover = dynamic(() => import('./hover'), { ssr: false })

const WuTooltip = (props: WuTooltipProps) => {
  const { type } = props

  switch (type) {
    case 'intercom': {
      return <WuIntercom {...props} />
    }
    case 'popUp': {
      return <WuPopUp {...props} />
    }

    default: {
      return <WuHover {...props} />
    }
  }
}

export { type WuTooltipProps, WuTooltip }
