import { MediaFileUploaderWithInfo, Textfield } from '@wordup/lib-portal/components'
import { useTranslation } from 'next-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import type { BrandPageInputFormTypes } from '../../../types'

type EditFormProps = {
  index: number
  nestedIndex: number
}

export const EditForm = ({ index, nestedIndex }: EditFormProps) => {
  const { t } = useTranslation()
  const { setValue } = useFormContext<BrandPageInputFormTypes>()

  return (
    <div className='flex flex-col gap-4'>
      <Controller
        name={`inputItems.${index}.videos.attributes.custom.data.${nestedIndex}.title`}
        render={({ field, fieldState: { error } }) => (
          <Textfield
            {...field}
            label={t('brand_management.videos.video_title')}
            placeholder={t('brand_management.videos.fill_in_video_title')}
            onChange={e => {
              const value = e.target.value
              field.onChange(value)
              setValue(
                `previewItems.${index}.videos.attributes.custom.data.${nestedIndex}.title`,
                value,
              )
            }}
          />
        )}
      />

      <Controller
        name={`inputItems.${index}.videos.attributes.custom.data.${nestedIndex}.url`}
        render={({ field, fieldState: { error } }) => (
          <Textfield
            {...field}
            label={t('brand_management.videos.youtube_vimeo_link')}
            placeholder={t('brand_management.videos.fill_in_youtube_vimeo_link')}
            onChange={e => {
              const value = e.target.value
              field.onChange(value)
              setValue(
                `previewItems.${index}.videos.attributes.custom.data.${nestedIndex}.url`,
                value,
              )
            }}
          />
        )}
      />

      <Controller
        name={`inputItems.${index}.videos.attributes.custom.data.${nestedIndex}.thumbnailUrl`}
        render={({ field, fieldState: { error } }) => {
          return (
            <MediaFileUploaderWithInfo
              mode='shop'
              namespace={'brand'}
              materialId=''
              type='image'
              formats={['png', 'jpg']}
              onChange={({ url }) => {
                field.onChange(url)
                setValue(
                  `previewItems.${index}.videos.attributes.custom.data.${nestedIndex}.thumbnailUrl`,
                  url,
                )
              }}
              initUrl={field.value ?? ''}
              label={t('brand_management.videos.video_thumbnail')}
              extraInfo={'1920*1080 PX'}
              maxSizeInMb={1}
              error={error?.type === 'required'}
            />
          )
        }}
      />
    </div>
  )
}
