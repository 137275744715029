import { BaseSyntheticEvent, useEffect, useMemo, useState } from 'react'
import { FieldArrayWithId, useFieldArray, useForm, UseFormReturn, useWatch } from 'react-hook-form'

import { useTranslation } from 'next-i18next'
import { apiCaller } from '@wordup/lib-portal/features'
import { dataAdapter, createEmptyBlock } from './utils'
import {
  BrandPageInputFormTypes,
  BrandPageBlockTypes,
  ProductListEditFormTypes,
  PreviewItemsFormTypes,
} from './types'
import { useScrollTo } from '@wordup/lib-portal/hooks'
import { useSwrFindPageIdByOrg, useSwrGetManagePageId } from './hooks'
import { createMangePage, updateManagePage } from '@wordup/apis'

import { toast } from '@libs-components/components/toast'
import axios from 'axios'
import { CategoryButtonTypes } from './components/category-button-group'
import { Swal } from '../../utils'
import { usePrompt } from '../../hooks'
import { DEFAULT_VALUES } from './constants'
import { shopBrandPage } from '@wordup/lib-portal/routes'
import { Text } from '@libs-components/components/text'

type EditStatusTypes = 'create' | 'default' | 'edit'

const SCROLLABLE_BLOCK_ITEM = 'scrollable-block-item'

export type DragAndDropBlockPropsTypes = {
  handleMoveBlockItem: (fromIndex: number, toIndex: number) => void
  handleSelectedBlock: (index: number, enableScrollToTarget: boolean) => void
  inputItemsFields: FieldArrayWithId<BrandPageInputFormTypes, 'inputItems'>[]
  selectedBlockIndex: number | undefined
  previewItemsFields: PreviewItemsFormTypes[]
}

export type PreviewItemPropsTypes = {
  handleSelectedBlock: (index: number, enableScrollToTarget: boolean) => void
  deleteBlockItem: (index: number) => void
  insertBlockItem: (index: number, type: BrandPageBlockTypes) => void
  resetTargetBlockItem: (index: number, type: BrandPageBlockTypes) => void
  scrollableBlockItem: string
  previewItemsFields: PreviewItemsFormTypes[]
  selectedBlockIndex: number | undefined
  isMobileScreen: boolean
}

export type BlockItemPropsTypes = {
  inputItemsFields: FieldArrayWithId<BrandPageInputFormTypes, 'inputItems'>[]
  editStatus: EditStatusTypes
  basicEditorButtons: CategoryButtonTypes[]
  advancedEditorButtons: CategoryButtonTypes[]
  selectedBlockIndex: number | undefined
  leaveEditBlock: () => void
}

export type PageInfoPropsTypes = {
  isMobileScreen: boolean
  isBlockItemEdited?: boolean
  pageInfoStatus: {
    pageInfoKeyDisabled?: boolean
    visible?: boolean
  }
  openPageInfoSettingModal: boolean
  handleSubmitBlockItems: (e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>
  handlePageInfoSubmit: (e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>
  onPageInfoPageEnable: () => void
  onPageInfoPageDisable: () => void
  handleOpenPageInfoSettingModal: () => void
  handleClosePageInfoSettingModal: () => void
  selectDesktopScreen: () => void
  selectMobileScreen: () => void
}

type BrandPageInitReturnTypes = {
  methods: UseFormReturn<BrandPageInputFormTypes, any, undefined>
  blockItemProps: BlockItemPropsTypes
  pageInfoProps: PageInfoPropsTypes
  previewItemProps: PreviewItemPropsTypes
  dragAndDropBlockProps: DragAndDropBlockPropsTypes
}

export const useBrandPageInit = ({ orgId }: { orgId: string }): BrandPageInitReturnTypes => {
  const { t } = useTranslation()

  const [editStatus, setEditStatus] = useState<EditStatusTypes>('default')
  const [selectedBlockIndex, setSelectedBlockIndex] = useState<number | undefined>(undefined)
  const [shouldScroll, setShouldScroll] = useState(false)
  const [openPageInfoSettingModal, setOpenPageInfoSettingModal] = useState(false)
  const [isMobileScreen, setIsMobileScreen] = useState(true)

  const { pageId, mutateFindPageIdByOrg } = useSwrFindPageIdByOrg(orgId)
  const { managePageData, mutateManagePage } = useSwrGetManagePageId(pageId, orgId)

  useScrollTo({
    id: `${SCROLLABLE_BLOCK_ITEM}-${String(selectedBlockIndex)}`,
    offset: 500,
    shouldScroll,
    onSuccess: () => {
      setShouldScroll(false)
    },
  })

  const methods = useForm<BrandPageInputFormTypes>({
    defaultValues: DEFAULT_VALUES,
  })

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { isDirty },
    reset,
  } = methods

  const {
    fields: inputItemsFields,
    append,
    move,
    insert,
    remove,
    update,
  } = useFieldArray({ control, name: 'inputItems' })

  const {
    append: appendPreview,
    move: movePreview,
    insert: insertPreview,
    remove: removePreview,
    update: updatePreview,
  } = useFieldArray({ control, name: 'previewItems' })

  const previewItemsFields = useWatch({ control, name: 'previewItems' })

  const pageInfoStatus = useMemo(() => {
    return {
      visible: managePageData?.meta?.page?.attributes?.visible,
      pageInfoKeyDisabled: !!managePageData?.meta?.page?.attributes?.key,
    }
  }, [managePageData])

  usePrompt({
    onBlock: async ({ unblock }) => {
      const res = await Swal.fire({
        title: t('admin_nav.product_management.has_unsaved_prompt.title'),
        text: t('admin_nav.product_management.has_unsaved_prompt.desc'),
        icon: 'warning',
        confirmButtonText: t('common.confirm'),
        showCancelButton: true,
        cancelButtonText: t('common.cancel'),
      })

      if (res.isConfirmed) {
        unblock()
      }
    },
    enableBlockRouteChange: isDirty,
  })

  useEffect(() => {
    if (inputItemsFields.length === 0) {
      append({ brandInfo: createEmptyBlock('brandInfo').data })
      appendPreview({
        brandInfo: createEmptyBlock('brandInfo').preview,
      })
    }
    if (editStatus === 'create' && inputItemsFields.length > 0) {
      setSelectedBlockIndex(inputItemsFields.length - 1)
      setShouldScroll(true)
    }
  }, [inputItemsFields, editStatus])

  useEffect(() => {
    const { data, meta } = managePageData || {}
    if (!data) return
    const { inputItems, previewItems } = dataAdapter(data, meta)
    const pageInfo = {
      key: meta?.page?.attributes?.key || '',
      description: meta?.page?.attributes?.description || '',
      ogImage: meta?.page?.attributes?.ogImage,
    }
    reset({
      ...DEFAULT_VALUES,
      pageInfo,
      inputItems,
      previewItems,
      initData: { pageInfo, inputItems, previewItems },
    })
  }, [managePageData])

  const basicEditorButtons: CategoryButtonTypes[] = [
    {
      text: t('brand_management.text.title'),
      iconProps: { type: 'text_fields' },
      onClick: () => {
        createNewBlock('text')
      },
    },
    {
      text: t('brand_management.images.title'),
      iconProps: { type: 'imagesmode' },
      onClick: () => {
        createNewBlock('images')
      },
    },
    {
      text: t('brand_management.videos.title'),
      iconProps: { type: 'video_library' },
      onClick: () => {
        createNewBlock('videos')
      },
    },
    {
      text: t('brand_management.products.title'),
      iconProps: { type: 'grid_view' },
      onClick: () => {
        createNewBlock('products')
      },
    },
    {
      text: t('brand_management.gap.title'),
      iconProps: { type: 'width' },
      onClick: () => {
        createNewBlock('gap')
      },
    },
  ]

  const advancedEditorButtons: CategoryButtonTypes[] = [
    {
      text: t('brand_management.coupon_list.title'),
      iconProps: { type: 'confirmation_number' },
      onClick: () => {
        createNewBlock('couponList')
      },
    },
  ]

  const onPageInfoSubmit = async (data: BrandPageInputFormTypes) => {
    try {
      const res = pageId
        ? await apiCaller(updateManagePage({ ...data.pageInfo, pageId, orgId }))
        : await apiCaller(
            createMangePage({
              ...data.pageInfo,
              name: '',
              orgId,
              kind: 'brand_page',
            }),
          )

      if (res.status === 200) {
        toast.success({ message: t('common.save_success') })
        mutateManagePage()
        mutateFindPageIdByOrg()
        setOpenPageInfoSettingModal(false)
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error({ message: error.response?.data?.errors?.[0]?.detail })
      }
    }
  }

  const onSubmitBlockItems = async (data: BrandPageInputFormTypes) => {
    let currentPageId = pageId

    try {
      if (!currentPageId) {
        const res = await apiCaller<{ pageId: string }>(
          createMangePage({ orgId, kind: 'brand_page', name: '' }),
        )
        currentPageId = res.data.pageId
        mutateFindPageIdByOrg()
      }

      const pageComponents = data.inputItems
        .map(item => {
          const type = Object.keys(item)[0]

          if (type === 'brandInfo') {
            return {
              id: item?.brandInfo?.id,
              kind: 'brand_basic_info',
              slotKey: 'brand_basic_info',
              custom: item?.brandInfo?.attributes.custom,
              visible: true,
            }
          }

          if (type === 'text') {
            return {
              id: item?.text?.id,
              kind: 'html_block',
              slotKey: 'html_block',
              custom: item?.text?.attributes.custom,
              startsAt: item?.text?.attributes.startsAt,
              endsAt: item?.text?.attributes.endsAt,
              visible: true,
            }
          }

          if (type === 'images') {
            return {
              id: item?.images?.id,
              kind: 'carousel_images',
              slotKey: 'carousel_images',
              custom: item?.images?.attributes.custom,
              startsAt: item?.images?.attributes.startsAt,
              endsAt: item?.images?.attributes.endsAt,
              name: item?.images?.attributes.name,
              description: item?.images?.attributes.description,
              visible: true,
            }
          }

          if (type === 'videos') {
            return {
              id: item?.videos?.id,
              kind: 'carousel_videos',
              slotKey: 'carousel_videos',
              custom: item?.videos?.attributes.custom,
              startsAt: item?.videos?.attributes.startsAt,
              endsAt: item?.videos?.attributes.endsAt,
              name: item?.videos?.attributes.name,
              description: item?.videos?.attributes.description,
              visible: true,
            }
          }
          if (type === 'gap') {
            return {
              id: item?.gap?.id,
              kind: 'gap',
              slotKey: 'gap',
              custom: item?.gap?.attributes.custom,
              visible: true,
            }
          }

          if (type === 'couponList') {
            return {
              id: item?.couponList?.id,
              kind: 'coupon_list',
              slotKey: 'coupon_list',
              custom: item?.couponList?.attributes.custom,
              startsAt: item?.couponList?.attributes.startsAt,
              endsAt: item?.couponList?.attributes.endsAt,
              name: item?.couponList?.attributes.name,
              description: item?.couponList?.attributes.description,
              visible: true,
            }
          }

          if (type === 'products') {
            const resourceIds = item?.products?.attributes.custom.map(
              (product: ProductListEditFormTypes) => product.productId,
            )
            return {
              id: item?.products?.id,
              kind: 'picked_product_list',
              slotKey: 'picked_product_list',
              startsAt: item?.products?.attributes.startsAt,
              endsAt: item?.products?.attributes.endsAt,
              name: item?.products?.attributes.name,
              description: item?.products?.attributes.description,
              resourceIds,
              visible: true,
            }
          }

          return null
        })
        .filter(item => item !== null)

      const res = await apiCaller(
        updateManagePage({
          ...data,
          pageId: currentPageId,
          pageComponents,
          orgId,
        }),
      )
      if (res.status === 200) {
        toast.success({ message: t('common.save_success') })
        mutateManagePage()
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error({ message: error.response?.data?.errors?.[0]?.detail })
      }
    }
  }

  const onPageInfoPageEnable = async () => {
    if (!pageId || !managePageData?.meta?.page.id) return setOpenPageInfoSettingModal(true)

    try {
      const res = await apiCaller(updateManagePage({ pageId, visible: true, orgId }))
      if (res.status === 200) {
        toast.success({ message: t('common.save_success') })
        mutateManagePage()

        return Swal.fire({
          icon: 'success',
          title: t('brand_management.publish_success'),
          confirmButtonText: t('common.got_it'),
          html: (
            <Text variant='body_m_400' color='grayscale-500'>
              {t('brand_management.publish_action')}
              <a
                href={`${shopBrandPage}/${managePageData?.meta?.page.id}`}
                target='_blank'
                rel='noreferrer noopener'
                className='text-primary-500 underline'
              >
                {t('brand_management.page')}
              </a>
              {t('brand_management.page_check')}
            </Text>
          ),
        })
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error({ message: error.response?.data?.errors?.[0]?.detail })
      }
    }
  }

  const onPageInfoPageDisable = async () => {
    if (!pageId) return

    try {
      const res = await apiCaller<any>(updateManagePage({ pageId, visible: false, orgId }))
      if (res.status === 200) {
        toast.success({ message: t('common.save_success') })
        mutateManagePage()
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error({ message: error.response?.data?.errors?.[0]?.detail })
      }
    }
  }

  const createNewBlock = (type: BrandPageBlockTypes) => {
    setEditStatus('create')
    switch (type) {
      case 'text':
        append({
          text: createEmptyBlock('text').data,
        })
        appendPreview({
          text: createEmptyBlock('text').preview,
        })
        break
      case 'images':
        append({
          images: createEmptyBlock('images').data,
        })
        appendPreview({
          images: createEmptyBlock('images').preview,
        })
        break
      case 'products':
        append({
          products: createEmptyBlock('products', orgId).data,
        })
        appendPreview({
          products: createEmptyBlock('products', orgId).preview,
        })
        break

      case 'videos':
        append({
          videos: createEmptyBlock('videos').data,
        })
        appendPreview({
          videos: createEmptyBlock('videos').data,
        })
        break
      case 'gap':
        append({
          gap: createEmptyBlock('gap').data,
        })
        appendPreview({
          gap: createEmptyBlock('gap').preview,
        })
        break
      case 'couponList':
        append({
          couponList: createEmptyBlock('couponList').data,
        })
        appendPreview({
          couponList: createEmptyBlock('couponList').preview,
        })
        break
      default:
        break
    }
  }

  const handleSelectedBlock = (index: number, enableScrollToTarget: boolean) => {
    setSelectedBlockIndex(index)
    setEditStatus('edit')
    setShouldScroll(enableScrollToTarget)
  }

  const deleteBlockItem = async (index: number) => {
    const result = await Swal.fire({
      icon: 'warning',
      title: t('playlist.confirm_delete.title'),
      confirmButtonText: t('common.confirm'),
      cancelButtonText: t('common.cancel'),
      showCancelButton: true,
    })

    if (!result.isConfirmed) return
    setSelectedBlockIndex(undefined)
    setEditStatus('default')
    remove(index)
    removePreview(index)
  }

  const handleBlock = (action: 'insert' | 'update', index: number, type: BrandPageBlockTypes) => {
    const { data, preview } = createEmptyBlock(type, orgId)

    if (data) {
      const method = action === 'insert' ? insert : update
      method(index, { [type]: data })
    }

    if (preview || data) {
      const previewMethod = action === 'insert' ? insertPreview : updatePreview

      previewMethod(index, { [type]: preview || data })
    }
  }

  const insertBlockItem = (index: number, type: BrandPageBlockTypes) => {
    handleBlock('insert', index, type)
  }

  const resetTargetBlockItem = async (index: number, type: BrandPageBlockTypes) => {
    const result = await Swal.fire({
      icon: 'warning',
      title: t('brand_management.confirm_undo'),
      text: t('brand_management.confirm_undo_description'),
      confirmButtonText: t('common.confirm'),
      cancelButtonText: t('common.cancel'),
      showCancelButton: true,
    })

    if (!result.isConfirmed) return

    handleBlock('update', index, type)
  }

  const handleMoveBlockItem = (fromIndex: number, toIndex: number) => {
    move(fromIndex, toIndex)
    movePreview(fromIndex, toIndex)
  }

  const leaveEditBlock = () => {
    setEditStatus('default')
    setSelectedBlockIndex(undefined)
  }

  const handleOpenPageInfoSettingModal = () => {
    setOpenPageInfoSettingModal(true)
  }

  const handleClosePageInfoSettingModal = () => {
    setOpenPageInfoSettingModal(false)
    setValue('pageInfo', getValues('initData')?.pageInfo)
  }

  const selectDesktopScreen = () => {
    setIsMobileScreen(false)
  }

  const selectMobileScreen = () => {
    setIsMobileScreen(true)
  }

  return {
    methods,
    blockItemProps: {
      inputItemsFields,
      editStatus,
      basicEditorButtons,
      advancedEditorButtons,
      selectedBlockIndex,
      leaveEditBlock,
    },
    pageInfoProps: {
      isMobileScreen,
      isBlockItemEdited: isDirty,
      pageInfoStatus,
      openPageInfoSettingModal,
      handleSubmitBlockItems: handleSubmit(onSubmitBlockItems),
      handlePageInfoSubmit: handleSubmit(onPageInfoSubmit),
      onPageInfoPageEnable,
      onPageInfoPageDisable,
      handleOpenPageInfoSettingModal,
      handleClosePageInfoSettingModal,
      selectDesktopScreen,
      selectMobileScreen,
    },
    previewItemProps: {
      handleSelectedBlock,
      deleteBlockItem,
      insertBlockItem,
      resetTargetBlockItem,
      scrollableBlockItem: SCROLLABLE_BLOCK_ITEM,
      previewItemsFields,
      selectedBlockIndex,
      isMobileScreen,
    },
    dragAndDropBlockProps: {
      handleMoveBlockItem,
      handleSelectedBlock,
      inputItemsFields,
      selectedBlockIndex,
      previewItemsFields,
    },
  }
}
