import { Text } from '@libs-components/components/text'
import { useTranslation } from 'next-i18next'
import { Divider } from '@mui/material'
import { WuModal, WuModalProps } from '@libs-components/components/modal'
import { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import WuButton, { WuButtonProps } from '../../../components/button'

type EditModalPropsTypes = {
  modalProps: Omit<WuModalProps, 'children'>
  title: string
  description: string
  actionProps: {
    onSubmitProps: WuButtonProps
    onCancelProps: WuButtonProps
    onSubmitText?: string
    leftActionElement?: React.ReactNode
  }
}

export const EditModal = ({
  modalProps,
  title,
  description,
  actionProps,
  children,
}: PropsWithChildren<EditModalPropsTypes>) => {
  const { t } = useTranslation()

  const {
    onSubmitProps,
    onCancelProps,
    onSubmitText = t('common.add'),
    leftActionElement,
  } = actionProps

  return (
    <WuModal {...modalProps} padding='0px' maxWidth='500px'>
      <div className='flex flex-col'>
        <div className='flex flex-col'>
          <div className='flex flex-col p-4'>
            <Text variant='heading_m_500'>{title}</Text>
            <Text variant='body_m_400' color='grayscale-500'>
              {description}
            </Text>
          </div>
          <Divider />

          <div className='p-4'>{children}</div>
          <Divider />

          <div className='flex justify-between p-4'>
            {leftActionElement}
            <div className='flex gap-3'>
              <WuButton {...onCancelProps} variant='text' color='info'>
                {t('common.cancel')}
              </WuButton>

              <WuButton {...onSubmitProps}>{onSubmitText}</WuButton>
            </div>
          </div>
        </div>
      </div>
    </WuModal>
  )
}
