import type { PickedProductListEditFormBlockType, PickedProductListBlockType } from '../types'
import { ProductType } from '@libs-components/types'
import type { PageComponentType } from '@libs-components/types'

export const createEmptyPickedProductListBlock = (): PickedProductListEditFormBlockType => ({
  type: 'picked_product_list',
  id: '',
  attributes: {
    name: '',
    description: '',
    startsAt: '',
    endsAt: '',
    custom: [],
  },
})

export const createEmptyPreviewPickedProductListBlock = (
  orgId: number,
): PickedProductListBlockType => ({
  type: 'picked_product_list',
  id: '',
  attributes: {
    name: '',
    description: '',
    startsAt: '',
    endsAt: '',
    custom: [],
    meta: createEmptyPickedProductListMeta(orgId),
  },
})

export const createEmptyPickedProductListCustomDataAttributes = (): ProductType.DataAttributes => ({
  productLine: 'course',
  materialComposableFeatures: [],
  productableType: 'Course',
  productableId: '',
  name: '',
  seoTitle: '',
  slogan: '',
  description: '',
  learnWith: '',
  carouselItems: [],
  suitableFor: [],
  factsLabels: [],
  factsLabelsV2: [],
  highlights: [],
  faq: [],
  infoDesktop: '',
  infoMobile: '',
  customCss: '',
  testimonial: [],
  showcaseVideos: {
    data: [],
  },
  articleLinks: {
    data: [],
  },
  coverImageUrl: '',
  coverImageUrlMobile: '',
  shopCoverImageUrl: '',
  examName: '',
  isbn: '',
  copiesSold: 0,
  iosVisible: false,
  androidVisible: false,
  shopPosition: 0,
  expiration: '',
  currency: '',
  listPrice: '',
  salePrice: '',
  salePriceEndsAt: '',
  crowdfunding: null,
  countdown: null,
  slug: '',
  isNewRelease: false,
  crowdfundingReleaseStartsAt: '',
  crowdfundingReleaseEndsOn: '',
  crowdfundingReleaseNote: '',
  tags: [],
  highlightedTags: [],
  categories: [],
  iosDei: false,
  allowTrial: false,
  publisherName: '',
  averageRating: 0,
  ratingCount: 0,
  showRating: false,
  publisherUrl: '',
  authorName: '',
  startsAt: '',
  endsAt: '',
  available: false,
  updatedAt: '',
  publishedAt: '',
  shopRenderer: 'v1',
  componentArrangement: { main: [] },
  materialToc: { type: 'root', name: '', componentsCount: 0, children: [], id: 0 },
  materialsToc: [],
  trialOnlyMaterialsToc: { type: 'root', name: '', componentsCount: 0, children: [], id: 0 },
  enabledDemoFeatures: [],
  qtyInput: '',
  variants: [],
  childrenProducts: {
    data: [],
  },
  redirect: undefined,
  orgId: '',
  isCashbackMission: false,
  referralName: null,
  referralDescription: null,
  userFeedbackCount: 0,
  userFeedbacks: [],
  showComments: false,
  products: null,
  squareImageUrl: null,
  shopListed: false,
  shopReady: false,
  showCommentTab: false,
})

export const createEmptyPickedProductListMeta = (orgId: number): PageComponentType.MetaType => ({
  page: {
    id: '',
    type: 'brand_page',
    attributes: {
      description: '',
      key: '',
      kind: 'brand_page',
      name: '',
      visible: false,
      orgId,
      imageUrls: {
        desktopBannerBgUrl: '',
        desktopBannerUrl: '',
        mobileBannerBgUrl: '',
        mobileBannerUrl: '',
        ogImageUrl: '',
      },
      introHtml: '',
      ogImage: '',
    },
  },
  seoTitle: '',
  seoDescription: '',
  categoryPath: null,
  materialCount: 0,
  totalStudentsCount: 0,
  averageRating: 0,
  ratingCount: 0,
  ratingBreakdown: {
    '1': 0,
    '2': 0,
    '3': 0,
    '4': 0,
    '5': 0,
  },
  extraTrackingPixel: '',
  extraTrackingGa: '',
  extraTrackingGoogleAds: {
    conversionId: '',
    addToCart: '',
    purchase: '',
    pageView: '',
  },
  org: {
    whiteLabelEnabled: false,
    id: '',
    name: '',
    logoUrl: '',
    taxIdNumber: '',
    materialCount: 0,
    totalStudentsCount: 0,
    averageRating: 0,
    ratingCount: 0,
    ratingBreakdown: { '1': 0, '2': 0, '3': 0, '4': 0, '5': 0 },
    extraTrackingPixel: '',
    extraTrackingGa: '',
    extraTrackingGoogleAds: { conversionId: '', addToCart: '', purchase: '', pageView: '' },
  },
})
