import React from 'react'
import { Icon } from '../icon'

export type ShowcaseVideoType = {
  length: string
  title: string
  url: string
  thumbnailUrl: string
}

export type ShowcaseVideosProps = {
  activeIndex: number
  showcaseVideos: ShowcaseVideoType[]
  onClickShowcaseItem: (index: number) => void
  showcaseItemClassName?: string
  showcaseWrapperClassName?: string
}

export const ShowcaseVideos = ({
  activeIndex,
  showcaseVideos,
  onClickShowcaseItem,
  showcaseItemClassName,
  showcaseWrapperClassName,
}: ShowcaseVideosProps) => {
  return (
    <ul className={showcaseWrapperClassName} style={{ margin: 0, padding: 0, listStyle: 'none' }}>
      {showcaseVideos.map((showcaseVideoItem, index) => {
        const { title, length } = showcaseVideoItem
        const isActive = activeIndex === index

        return (
          <li
            key={title}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 8,
              padding: '8px 16px',
              cursor: 'pointer',
              marginBottom: 12,
              pointerEvents: isActive ? 'none' : 'auto',
            }}
            className={showcaseItemClassName}
            onClick={() => onClickShowcaseItem(index)}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 8,
                fontSize: 14,
                lineHeight: '24.5px',
                letterSpacing: '0.035px',
                color: isActive ? '#72AFFF' : '#383B3D', // primary-500 : grayscale-800
              }}
            >
              <Icon type='video' size={20} />
              <span style={{ wordBreak: 'break-word' }}>{title}</span>
            </div>
            <span
              style={{
                fontSize: 12,
                lineHeight: '21px',
                letterSpacing: '0.06px',
                color: '#9DA1A1', // grayscale-400
                opacity: length ? 1 : 0,
              }}
            >
              {length}
            </span>
          </li>
        )
      })}
    </ul>
  )
}
