import { findPageIdByOrg, GetFindPageIdByOrgResponse } from '@wordup/apis'
import useSWR from 'swr'
import { apiCaller } from '../../api'

export const useSwrFindPageIdByOrg = (orgId: string) => {
  const { data, mutate: mutateFindPageIdByOrg } = useSWR(
    orgId ? findPageIdByOrg(orgId) : null,
    async payloads => {
      const res = await apiCaller<GetFindPageIdByOrgResponse>(payloads)
      return res.data
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  )
  return { pageId: data?.pageId, mutateFindPageIdByOrg }
}
