import type { CarouselVideosBlockType } from '../types'

export const createEmptyCarouselVideosBlock = (): CarouselVideosBlockType => ({
  id: '',
  type: 'single_video',
  attributes: {
    name: '',
    description: '',
    startsAt: '',
    endsAt: '',
    custom: {
      data: [createEmptyCarouselVideo()],
    },
  },
})

export const createEmptyCarouselVideo = () => ({
  title: '',
  url: '',
  thumbnailUrl: '',
})
