import { PropsWithChildren } from 'react'
import { useTranslation } from 'next-i18next'
import { Icon } from '@libs-components/components/icon'

import { twMerge } from 'tailwind-merge'

import WuButton from '@wordup/lib-portal/components/button'
import { WuModal } from '@libs-components/components/modal'

import { PageInfo } from '../edit-form-block/form/page-info'
import { PageInfoPropsTypes } from '../use-brand-page-init'
import Link from 'next/link'
import { AllPaths } from '../../../routes'

type BrandLayoutProps = {
  pageInfoProps: PageInfoPropsTypes
}

export const BrandLayout = ({ children, pageInfoProps }: PropsWithChildren<BrandLayoutProps>) => {
  const { t } = useTranslation()
  const {
    isMobileScreen,
    isBlockItemEdited,
    pageInfoStatus,
    openPageInfoSettingModal,
    handleOpenPageInfoSettingModal,
    handleClosePageInfoSettingModal,
    selectDesktopScreen,
    selectMobileScreen,
    onPageInfoPageDisable,
    onPageInfoPageEnable,
    handlePageInfoSubmit,
    handleSubmitBlockItems,
  } = pageInfoProps

  return (
    <div className='flex h-screen w-full flex-col'>
      <div className='z-[1201]'>
        <div className='relative w-full border-b border-gray-300 bg-white px-6 py-4'>
          <nav className='grid grid-cols-9 gap-2'>
            <div className='col-span-3'>
              <Link href={AllPaths.DASHBOARD_MATERIALS}>
                <WuButton
                  className='bg-grayscale-800 text-grayscale-000'
                  startIcon={<Icon type='logout' color='grayscale-000' />}
                  color='info'
                >
                  {t('brand_management.leave_edit')}
                </WuButton>
              </Link>
            </div>

            <div className='col-span-3 flex items-center justify-center'>
              <div className='h-full w-[200px] rounded-full bg-gray-100'>
                <WuButton
                  onClick={selectMobileScreen}
                  className={twMerge(
                    'absolute px-4 transition-all duration-300',
                    isMobileScreen ? 'z-20' : 'bg-grayscale-50 text-grayscale-400 z-10',
                  )}
                  startIcon={
                    <Icon
                      type='phone_iphone'
                      color={isMobileScreen ? 'grayscale-000' : 'grayscale-400'}
                    />
                  }
                  style={{
                    left: 'calc(50% - 6rem)',
                  }}
                >
                  {t('brand_management.mobile')}
                </WuButton>
                <WuButton
                  onClick={selectDesktopScreen}
                  className={twMerge(
                    'absolute right-1/3 z-10 px-4 transition-all duration-300',
                    isMobileScreen ? 'bg-grayscale-50 text-grayscale-400 z-10 ' : 'z-20',
                  )}
                  startIcon={
                    <Icon
                      type='desktop_mac'
                      color={isMobileScreen ? 'grayscale-400' : 'grayscale-000'}
                    />
                  }
                  style={{
                    right: 'calc(50% - 6rem)',
                  }}
                >
                  {t('brand_management.desktop')}
                </WuButton>
              </div>
            </div>

            <div className='col-span-3 flex items-center justify-end gap-x-2'>
              <WuButton
                onClick={handleOpenPageInfoSettingModal}
                className='bg-grayscale-100 text-grayscale-800'
                startIcon={<Icon type='setting' color='grayscale-800' />}
              >
                {t('brand_management.page_settings')}
              </WuButton>
              <WuButton onClick={handleSubmitBlockItems} disabled={!isBlockItemEdited}>
                {t('common.save')}
              </WuButton>
              {pageInfoStatus.visible ? (
                <WuButton onClick={onPageInfoPageDisable} variant='outlined' color='error'>
                  {t('brand_management.unpublish')}
                </WuButton>
              ) : (
                <WuButton onClick={onPageInfoPageEnable}>{t('brand_management.publish')}</WuButton>
              )}
            </div>
          </nav>
        </div>
      </div>

      <div className='relative h-full w-full overflow-hidden'>{children}</div>

      <WuModal
        open={openPageInfoSettingModal}
        maxWidth='700px'
        padding='0px'
        onClose={handleClosePageInfoSettingModal}
      >
        <PageInfo
          handleCancel={handleClosePageInfoSettingModal}
          handlePageInfoSubmit={handlePageInfoSubmit}
          pageInfoKeyDisabled={pageInfoStatus.pageInfoKeyDisabled}
        />
      </WuModal>
    </div>
  )
}
