export const AllPaths = {
  AUTH: '/auth',
  SIGN_UP: '/sign-up',
  SIGN_IN: '/sign-in',
  DASHBOARD: '/dashboard',
  HOME: '/',
  MATERIALS: '/materials',
  EXAM_PAPER: '/exam_paper',
  EXAM: '/exam',
  CHAPTER: '/chapter',
  COMPONENTS: '/components',
  INCORRECT_QUESTIONS: '/incorrect-questions',
  AI_PRACTICE: '/ai-practice',
  DECKS: '/decks',
  DECK_CARDS: '/cards',
  INTERESTS: '/interests',
  WUWOW: '/wuwow',
  AMC: '/amc',
  SCHOOL_TEACHERS: '/school_teachers',
  WUWOW_JR: '/wuwow?target=junior',
  CARDS_EDIT: '/cards/edit',

  ACCOUNT_SETTINGS: '/account/settings',
  ACCOUNT_ORDER_HISTORY: '/account/order-history',
  ACCOUNT_SUBSCRIPTION_ORDER_HISTORY: '/account/subscription-order-history',
  ACCOUNT_PREFERENCE: '/account/preference',
  ACCOUNT_REDEEM_CODE: '/account/redeem-code',
  ACCOUNT_CREDIT_CARD_MANAGEMENT: '/account/credit-card-management',
  ACCOUNT_CASHBACK_MISSIONS: '/account/cashback-missions',
  ACCOUNT_DELETE: '/account/delete',

  DASHBOARD_CLASSES: '/dashboard/classes',
  DASHBOARD_MATERIALS: '/dashboard/materials',
  DASHBOARD_SEATS: '/dashboard/seats',
  DASHBOARD_PRODUCTS: '/dashboard/products',
  DASHBOARD_SALES: '/dashboard/sales',
  DASHBOARD_SALES_SUMMARY: '/dashboard/sales/summary',
  DASHBOARD_COMMENTS: '/dashboard/comments',
  DASHBOARD_CREDIT_CARD_MANAGEMENT: '/dashboard/credit-card-management',
  DASHBOARD_CREDIT_PERSONAL_INFO_MANAGEMENT: '/dashboard/personal-info-management',
  DASHBOARD_BRAND_MANAGEMENT: '/dashboard/brand-management',
  DASHBOARD_ACCOUNTING_BANK_INFO: '/dashboard/accounting/bank-info',
  DASHBOARD_ORGS_MANAGEMENT: '/dashboard/orgs-management',
  DASHBOARD_MEMBERS_MANAGEMENT: '/dashboard/members-management',

  APP_DECKS: '/app/decks',
  CARDS: '/app/decks/:id/cards',
  CREATE_CARD: '/app/decks/:id/create/new',
  EDIT_CARD: '/app/decks/:id/cards/:id/edit',
  MOVE_CARDS: '/app/decks/:id/cards/move',
  QUERY_CARD: '/app/decks/:id/query/:word',
  REVIEW: '/app/decks/:id/review',
  REVIEW_TENSE: '/app/decks/:id/review/tense',
  REVIEW_HEARING: '/app/decks/:id/review/hearing',
  MATERIAL_DECKS: '/app/materials/:materialId/decks',
  FORGET_PASSWORD: '/app/forget_password',
  RESET_PASSWORD: '/app/reset_password',
  SETTING: '/app/setting/:type',
  SETTING_ACCOUNT: '/app/setting/account',
  SETTING_PREFERENCE: '/app/setting/preference',
  SETTING_ORDER_HISTORY: '/app/setting/order_history',
  SETTING_SUBSCRIPTION: '/app/setting/subscription',
  ACCOUNT_EXAM_HISTORY: '/app/exam_history',
  ACCOUNT_PROGRESS: '/app/progress',
  ANSWERS_INPUT: '/app/answers-input',
  ADMIN_ANSWERS_INPUT: '/app/admin/answers-input',
  ADMIN_INDEX: '/app/admin',
  ADMIN_IMPORT_STUDENTS: '/app/admin/students/import',
  ADMIN_UPLOAD_QUESTIONS: '/app/admin/upload-questions',
  ADMIN_CLASSES: '/app/admin/classes',
  ADMIN_SCHOOL_TEACHERS: '/app/admin/school_teachers',
  ADMIN_SCHOOL_TEACHERS_ADD: '/app/admin/school_teachers/new',
  ADMIN_MATERIALS: '/app/admin/materials',
  ADMIN_SEATS: '/app/admin/seats',
  SHOP: '/',
  FB_REDIRECT: `${process.env.SELF_DOMAIN}/app/fb_auth`,
  SUCCESS_FORM: '/success-form',

  OLD_WEB_ADMIN_HOME: `${process.env.NEXT_PUBLIC_WORDUP_OLD_WEB_DOMAIN}/app/admin`,

  INTERNAL: '/internal',
  INTERNAL_TAG_PAGE: '/internal/tag-page',
  INTERNAL_SHOP_ORDERS: '/internal/shop/orders',
  INTERNAL_TEACHER_SCHOOL: '/internal/teacher/school',
  INTERNAL_PAGE_COMPONENT: '/internal/page-component',
  INTERNAL_PARTNER_ENTITY: '/internal/partner-entity',
  INTERNAL_USER_ACCOUNT: '/internal/user-account',
  INTERNAL_MATERIALS_AND_ENROLLMENT: '/internal/materials-and-enrollment',
  INTERNAL_COUPONS: '/internal/coupons',
  INTERNAL_PUSH_NOTIFICATION: '/internal/push-notification',
  INTERNAL_COUPONS_CREATE: '/internal/coupons/create',
  INTERNAL_EXPENSES: '/internal/expenses',
  INTERNAL_SHOP_ADS: '/internal/shop-ads',
  INTERNAL_SHOP_ADS_APP_POPUP: '/internal/shop-ads/app-popup',
  INTERNAL_SHOP_ADS_APP_FLOAT_CAMPAIGN: '/internal/shop-ads/float-campaign',
  INTERNAL_SHOP_ADS_APP_LATEST_EVENTS: '/internal/shop-ads/latest-events',
  INTERNAL_SHOP_ADS_APP_POPULAR_EVENTS: '/internal/shop-ads/popular-events',
  INTERNAL_SHOP_ADS_CREATE: '/internal/shop-ads/create',
  INTERNAL_CAMPAIGNS: '/internal/campaigns',
  INTERNAL_CAMPAIGNS_CREATE: '/internal/campaigns/create',
  INTERNAL_PURCHASE_RIGHTS: '/internal/purchase-rights',
  INTERNAL_ROYALTY_RATES: '/internal/royalty-rates',

  TRIAL_EXAM_PAPER: '/trial/exam-paper',

  SUBSCRIPTION_DASHBOARD: '/subscription-dashboard',
  SUBSCRIPTION_DASHBOARD_FULL_TESTS: '/subscription-dashboard/full-tests',
  SUBSCRIPTION_DASHBOARD_WRONG_QUESTIONS: '/subscription-dashboard/wrong-questions',
}

export const shopPage = `${process.env.NEXT_PUBLIC_WU_SHOP_DOMAIN}/`
export const shopCartPage = `${process.env.NEXT_PUBLIC_WU_SHOP_DOMAIN}/cart`
export const shopBrandPage = `${process.env.NEXT_PUBLIC_WU_SHOP_DOMAIN}/brand`
