import { Controller, useFormContext } from 'react-hook-form'
import type { BrandPageInputFormTypes } from '../../types'
import { useTranslation } from 'next-i18next'
import { RichTextEditor } from '@wordup/lib-portal/components'

type TextFormProps = {
  index: number
}

export const TextForm = ({ index }: TextFormProps) => {
  const { t } = useTranslation()
  const { control, setValue } = useFormContext<BrandPageInputFormTypes>()

  return (
    <div className='flex flex-col'>
      <Controller
        name={`inputItems.${index}.text.attributes.custom.data.html`}
        control={control}
        render={({ field }) => {
          return (
            <RichTextEditor
              mode='shop'
              showPreviewButton={false}
              showToggleModeButton={false}
              handleEditorChange={value => {
                field.onChange(value)
                setValue(`previewItems.${index}.text.attributes.custom.data.html`, value)
              }}
              placeholder={t('common.please_enter_text')}
              editorHeight={300}
              editorMinHeight={300}
              textOnlyEditor
              value={field.value}
            />
          )
        }}
      />
    </div>
  )
}
