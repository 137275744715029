import ClearIcon from '@mui/icons-material/Clear'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Autocomplete, AutocompleteProps, OutlinedTextFieldProps } from '@mui/material'
import { Icon } from '@libs-components/components/icon'

import Chip from '@mui/material/Chip'
import React from 'react'
import { useTranslation } from 'next-i18next'

import { Textfield } from '.'

import TailwindConfig from 'tailwind.config'

const TailwindColor = TailwindConfig.theme.extend.colors

interface PropTypes<T>
  extends Omit<AutocompleteProps<T, boolean, boolean, boolean>, 'renderInput'> {
  enterTags?: Function
  style?: React.CSSProperties
  className?: string
  disableClearable?: boolean
  freeSolo?: boolean
  autoHighlight?: boolean
  noOptionsText?: string
  disableCloseOnSelect?: boolean
  isLoading?: boolean
  fieldTestId?: string
  textfieldProps?: Omit<OutlinedTextFieldProps, 'variant'>
  forcePopupIcon?: boolean
  multiple?: boolean
  disabled?: boolean
}

const WuAutocomplete = <T extends { [key: string]: any }>(
  {
    options = [],
    freeSolo = true,
    noOptionsText,
    disableCloseOnSelect = true,
    isLoading,
    fieldTestId,
    textfieldProps,
    forcePopupIcon,
    multiple,
    ...restProps
  }: PropTypes<T>,
  ref: React.Ref<any>,
) => {
  const { t } = useTranslation()
  return (
    <Autocomplete
      forcePopupIcon={forcePopupIcon}
      data-testid={fieldTestId}
      sx={{
        '& .MuiChip-deleteIcon': {
          color: TailwindColor['grayscale-700'],
          fontSize: '1rem',
        },
      }}
      multiple={multiple}
      options={options}
      freeSolo={freeSolo}
      renderTags={(value: readonly T[], getTagProps) =>
        value.map((option: T, index: number) => (
          <Chip
            sx={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: TailwindColor['grayscale-100'],
              borderColor: TailwindColor['grayscale-100'],
              '.MuiChip-label': {
                whiteSpace: 'normal',
                wordBreak: 'break-word',
                fontSize: '0.75rem',
              },
            }}
            label={option.label || option.toString()}
            style={{ margin: '0', minHeight: '1.5rem' }}
            deleteIcon={<Icon type='cancel_filled' />}
            {...getTagProps({ index })}
            className='my-auto leading-tight'
            key={index}
          />
        ))
      }
      renderInput={params => <Textfield {...params} {...textfieldProps} inputRef={ref} />}
      disableCloseOnSelect={disableCloseOnSelect}
      noOptionsText={noOptionsText ?? t('common.no_options')}
      popupIcon={<ExpandMoreIcon />}
      className='h-full w-full p-0'
      {...restProps}
    />
  )
}

export default React.forwardRef(WuAutocomplete)
