import type { BrandBasicInfoType } from '../types'

export const createEmptyBrandBasicInfoBlock = (): BrandBasicInfoType => ({
  id: '',
  type: 'brand_basic_info',
  attributes: {
    custom: {
      data: {
        banner: { mobile: '', desktop: '' },
        avatar: '',
        name: '',
        description: '',
        intro: '',
        socialLinks: [
          { type: 'instagram', link: '' },
          { type: 'facebook', link: '' },
          { type: 'line', link: '' },
        ],
      },
    },
  },
})
