import { useTranslation } from 'next-i18next'
import { snakeCase } from 'lodash-es'

import { BackButton, CollapseBlock } from '../../components'
import { DateTimePicker, LocalizationProvider, renderTimeViewClock } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { toDate } from 'date-fns'
import { Controller, useFormContext } from 'react-hook-form'
import type { BrandPageInputFormTypes } from '../../types'
import { Textfield } from '@wordup/lib-portal/components'
import { TextForm } from './text'
import { ImagesForm } from './images'
import { VideoForm } from './videos'
import { ProductsForm } from './products'
import { GapForm } from './gap'
import { CouponListForm } from './coupon-list'

type EditFormWithCollapseBlockProps = {
  type: keyof Pick<
    BrandPageInputFormTypes['inputItems'][number],
    'images' | 'products' | 'text' | 'videos' | 'gap' | 'couponList'
  >
  handlePrevious: () => void
  index: number
  orgId: string
}

export const EditFormWithCollapseBlock = ({
  type,
  handlePrevious,
  index,
  orgId,
}: EditFormWithCollapseBlockProps) => {
  const { t } = useTranslation()
  const { control, setValue } = useFormContext<BrandPageInputFormTypes>()

  const timeFieldsName = (
    index: number,
  ): {
    startsAt: `${number}.${Exclude<typeof type, 'gap'>}.attributes.startsAt`
    endsAt: `${number}.${Exclude<typeof type, 'gap'>}.attributes.endsAt`
  } | null => {
    switch (type) {
      case 'text':
        return {
          startsAt: `${index}.${type}.attributes.startsAt`,
          endsAt: `${index}.${type}.attributes.endsAt`,
        }
      case 'products':
        return {
          startsAt: `${index}.${type}.attributes.startsAt`,
          endsAt: `${index}.${type}.attributes.endsAt`,
        }
      case 'images':
        return {
          startsAt: `${index}.${type}.attributes.startsAt`,
          endsAt: `${index}.${type}.attributes.endsAt`,
        }
      case 'videos':
        return {
          startsAt: `${index}.${type}.attributes.startsAt`,
          endsAt: `${index}.${type}.attributes.endsAt`,
        }
      case 'couponList':
        return {
          startsAt: `${index}.${type}.attributes.startsAt`,
          endsAt: `${index}.${type}.attributes.endsAt`,
        }
      case 'gap':
        return null
      default:
        return {
          startsAt: `${index}.${type}.attributes.startsAt`,
          endsAt: `${index}.${type}.attributes.endsAt`,
        }
    }
  }

  const sectionFieldsName = (
    index: number,
  ): {
    name: `${number}.${Exclude<typeof type, 'text' | 'gap'>}.attributes.name`
    description: `${number}.${Exclude<typeof type, 'text' | 'gap'>}.attributes.description`
  } | null => {
    switch (type) {
      case 'text':
      case 'gap':
        return null
      case 'products':
        return {
          name: `${index}.products.attributes.name`,
          description: `${index}.products.attributes.description`,
        }
      case 'images':
        return {
          name: `${index}.images.attributes.name`,
          description: `${index}.images.attributes.description`,
        }
      case 'videos':
        return {
          name: `${index}.videos.attributes.name`,
          description: `${index}.videos.attributes.description`,
        }
      case 'couponList':
        return {
          name: `${index}.couponList.attributes.name`,
          description: `${index}.couponList.attributes.description`,
        }
      default:
        return {
          name: `${index}.products.attributes.name`,
          description: `${index}.products.attributes.description`,
        }
    }
  }

  const contentFields = (index: number) => {
    switch (type) {
      case 'text':
        return <TextForm index={index} />

      case 'images':
        return <ImagesForm index={index} />

      case 'videos':
        return <VideoForm index={index} />
      case 'products':
        return <ProductsForm index={index} orgId={orgId} />

      case 'gap':
        return <GapForm index={index} />

      case 'couponList':
        return <CouponListForm index={index} />

      default:
        break
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className='flex h-full flex-col'>
        <div className='border-grayscale-100 border-b p-4'>
          <BackButton
            handlePrevious={handlePrevious}
            text={`${t('common.edit')}${t(`brand_management.${snakeCase(type)}.title`)}`}
          />
        </div>
        <div className='flex flex-col overflow-auto pb-6'>
          {sectionFieldsName(index) ? (
            <CollapseBlock title={t('brand_management.block_info')}>
              <div className='flex flex-col gap-4'>
                <Controller
                  name={`inputItems.${sectionFieldsName(index)!.name}`}
                  control={control}
                  render={({ field }) => (
                    <Textfield
                      {...field}
                      onChange={e => {
                        field.onChange(e)
                        setValue(`previewItems.${sectionFieldsName(index)!.name}`, e.target.value)
                      }}
                      placeholder={t('brand_management.fill_in_title')}
                      label={t('common.title')}
                    />
                  )}
                />

                <Controller
                  name={`inputItems.${sectionFieldsName(index)!.description}`}
                  control={control}
                  render={({ field }) => {
                    return (
                      <Textfield
                        {...field}
                        onChange={e => {
                          field.onChange(e)
                          setValue(
                            `previewItems.${sectionFieldsName(index)!.description}`,
                            e.target.value,
                          )
                        }}
                        placeholder={t('brand_management.fill_in_block_description')}
                        label={t('brand_management.block_description')}
                        multiline
                        rows={4}
                        sx={{
                          '& .MuiOutlinedInput-input': {
                            maxHeight: '50px',
                          },
                        }}
                      />
                    )
                  }}
                />
              </div>
            </CollapseBlock>
          ) : null}

          {contentFields(index) ? (
            <CollapseBlock title={t('common.content')}>{contentFields(index)}</CollapseBlock>
          ) : null}

          {timeFieldsName(index) ? (
            <CollapseBlock title={t('common.schedule')}>
              <div className='flex flex-col gap-4'>
                <Controller
                  name={`inputItems.${timeFieldsName(index)!.startsAt}`}
                  control={control}
                  render={({ field }) => (
                    <DateTimePicker
                      views={['year', 'month', 'day', 'hours', 'minutes']}
                      openTo='day'
                      value={field.value ? toDate(new Date(field.value)) : null}
                      onChange={date => {
                        field.onChange(date)
                        setValue(
                          `previewItems.${timeFieldsName(index)!.startsAt}`,
                          date ? date.toISOString() : '',
                        )
                      }}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      slots={{ textField: Textfield }}
                      slotProps={{
                        textField: {
                          label: t('brand_management.block_publish'),
                          placeholder: t('brand_management.block_publish_placeholder'),
                          helperText: t('brand_management.block_publish_reminder'),
                        },
                        field: { clearable: true },
                      }}
                    />
                  )}
                />

                <Controller
                  name={`inputItems.${timeFieldsName(index)!.endsAt}`}
                  control={control}
                  render={({ field }) => (
                    <DateTimePicker
                      views={['year', 'month', 'day', 'hours', 'minutes']}
                      openTo='day'
                      value={field.value ? toDate(new Date(field.value)) : null}
                      onChange={date => {
                        field.onChange(date)
                        setValue(
                          `previewItems.${timeFieldsName(index)!.endsAt}`,
                          date ? date.toISOString() : '',
                        )
                      }}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      slots={{ textField: Textfield }}
                      slotProps={{
                        textField: {
                          label: t('brand_management.block_ubpublish'),
                          placeholder: t('brand_management.block_unpublish_placeholder'),
                        },
                        field: { clearable: true },
                      }}
                    />
                  )}
                />
              </div>
            </CollapseBlock>
          ) : null}
        </div>
      </div>
    </LocalizationProvider>
  )
}
