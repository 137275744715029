import type { MCompNavigationApiResponseType, MCompType } from '@wordup/apis'
import { getCompNavigation } from '@wordup/apis'
import useSWR from 'swr'
import { apiCaller } from '../api'

type Props = {
  materialId?: string | null
  componentId?: string | null
  composableType?: MCompType
  unfinished?: boolean
}

export const useMaterialComponentNavigation = ({
  materialId,
  componentId,
  composableType,
  unfinished,
}: Props) => {
  const { data } = useSWR(
    materialId && componentId
      ? getCompNavigation({
          materialId,
          componentId,
          composableType: composableType,
          unfinished: unfinished,
        })
      : null,
    async params => {
      try {
        const res = await apiCaller<MCompNavigationApiResponseType>(params)

        return res.data.data
      } catch (err) {
        return undefined
      }
    },
  )

  return { data }
}

export default useMaterialComponentNavigation
