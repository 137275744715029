import dynamic from 'next/dynamic'
import { getTypeText } from '../utils'
import { PreviewItemPropsTypes } from '../use-brand-page-init'
import { useTranslation } from 'next-i18next'
import { twMerge } from 'tailwind-merge'
import { BrandBasicInfoType } from '../types'

import { BlockItem } from './components/block-item'
import { PreviewBlockLayout } from './components'

const BannerPortalImage = dynamic(
  () =>
    import(
      '@libs-components/features/page-components/components/brand-basic-info/banner-portal-image'
    ).then(mod => mod.BannerPortalImage),
  { ssr: false },
)

type PreviewProps = {
  previewItemProps: PreviewItemPropsTypes
}

const PreviewBlock = ({ previewItemProps }: PreviewProps) => {
  const { t } = useTranslation()
  const { isMobileScreen, previewItemsFields } = previewItemProps

  if (!previewItemsFields.length) return null

  const firstPreviewItem = previewItemsFields.filter(item => item?.brandInfo)[0]
  const brandInfo = firstPreviewItem?.brandInfo as BrandBasicInfoType

  return (
    <div
      className={twMerge(
        'h-full w-full overflow-auto pt-10 transition-all',
        isMobileScreen ? 'max-w-[400px]' : '',
      )}
    >
      <div className='bg-grayscale-000 h-auto w-full'>
        <PreviewBlockLayout
          isMobileScreen={isMobileScreen}
          headerChildren={
            brandInfo.attributes.custom.data.banner.desktop ? (
              <BannerPortalImage
                alt={brandInfo.attributes.custom.data.name + ' banner'}
                type={'desktop'}
                src={brandInfo.attributes.custom.data.banner.desktop}
              />
            ) : null
          }
          leftContentChildren={
            <BlockItem
              previewItemProps={previewItemProps}
              inputField={firstPreviewItem}
              text={t(`brand_management.brand_info.title`)}
              type={'brandInfo'}
              index={0}
              className='sticky top-0 col-span-5'
            />
          }
          mainContentChildren={previewItemsFields.map((inputField, index) => {
            const { text, type } = getTypeText(inputField, t)

            if (type === 'brandInfo' && !isMobileScreen) return null

            return (
              <BlockItem
                key={`${inputField}-${index}-${type}`}
                previewItemProps={previewItemProps}
                inputField={inputField}
                text={text}
                type={type}
                index={index}
              />
            )
          })}
        />
      </div>
    </div>
  )
}

export default PreviewBlock
