import { PreviewItemsFormTypes, InputItemsFormTypes } from '../../types'
import { PageComponentImpl } from '@libs-components/features/page-components/components'
import type { PropsWithChildren } from 'react'
import './styles.css'
import dynamic from 'next/dynamic'

const BasicInfo = dynamic(
  () =>
    import('@libs-components/features/page-components/components/brand-basic-info/basic-info').then(
      mod => mod.BasicInfo,
    ),
  { ssr: false },
)

const BannerPortalImage = dynamic(
  () =>
    import(
      '@libs-components/features/page-components/components/brand-basic-info/banner-portal-image'
    ).then(mod => mod.BannerPortalImage),
  { ssr: false },
)

const BlockCardRendererWrapper = ({
  children,
  type,
}: PropsWithChildren<{ type: keyof InputItemsFormTypes }>) => {
  if (type === 'brandInfo') {
    return children
  } else {
    return <div className='h-full w-full px-4'>{children}</div>
  }
}

export const BlockCardRenderer = ({
  inputField,
  type,
  isMobileScreen,
}: {
  inputField: PreviewItemsFormTypes
  type: keyof InputItemsFormTypes
  isMobileScreen?: boolean
}) => {
  const renderContent = () => {
    switch (type) {
      case 'brandInfo': {
        const brandInfo = inputField?.brandInfo
        if (!brandInfo) return null

        const customData = brandInfo.attributes.custom.data

        return isMobileScreen ? (
          <>
            <BannerPortalImage
              alt={customData?.name + ' banner'}
              type={'mobile'}
              src={brandInfo.attributes.custom.data.banner.mobile}
            />
            <BasicInfo
              custom={{ data: customData }}
              meta={brandInfo.attributes.meta}
              showSkeleton
            />
          </>
        ) : (
          <BasicInfo custom={{ data: customData }} meta={brandInfo.attributes.meta} showSkeleton />
        )
      }
      case 'text':
        return inputField.text ? (
          <PageComponentImpl.html_block custom={inputField.text?.attributes.custom} showSkeleton />
        ) : null
      case 'images':
        return inputField.images ? (
          <PageComponentImpl.carousel_images
            {...inputField.images?.attributes}
            custom={{
              data: inputField.images?.attributes.custom.data.map(item => ({
                ...item,
                imageUrlDesktop: isMobileScreen
                  ? ''
                  : item?.imageUrlDesktop || item?.imageUrlMobile,
                imageUrlMobile: item?.imageUrlMobile,
              })),
            }}
            showSkeleton
          />
        ) : null
      case 'videos':
        return inputField.videos ? (
          <PageComponentImpl.carousel_videos
            {...inputField.videos?.attributes}
            custom={inputField.videos?.attributes.custom}
            showSkeleton
          />
        ) : null
      case 'gap':
        return inputField.gap?.attributes.custom ? (
          <PageComponentImpl.gap custom={inputField.gap?.attributes.custom} showSkeleton />
        ) : null
      case 'couponList':
        return inputField.couponList?.attributes?.custom ? (
          <PageComponentImpl.coupon_list
            {...inputField.couponList?.attributes}
            custom={inputField.couponList?.attributes.custom}
            showSkeleton
          />
        ) : null
      case 'products':
        return inputField.products?.attributes.custom ? (
          <PageComponentImpl.picked_product_list
            {...inputField.products?.attributes}
            custom={inputField.products?.attributes.custom}
            showSkeleton
          />
        ) : null
      default:
        return null
    }
  }

  return renderContent() ? (
    <BlockCardRendererWrapper type={type}>{renderContent()}</BlockCardRendererWrapper>
  ) : null
}
