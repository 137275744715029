import { PropsWithChildren, useState } from 'react'
import { Icon } from '@libs-components/components/icon'
import { Text } from '@libs-components/components/text'
import { twMerge } from 'tailwind-merge'

type CollapseProps = {
  title: string
}

export const CollapseBlock = ({ children, title }: PropsWithChildren<CollapseProps>) => {
  const [isCollapse, setIsCollapse] = useState(true)

  return (
    <div className='flex flex-col'>
      <div
        className='bg-background-light flex cursor-pointer items-center justify-start gap-2 p-4'
        onClick={() => {
          setIsCollapse(prev => !prev)
        }}
      >
        <Icon
          type='arrow_drop_down'
          className={twMerge('transition-all', isCollapse ? 'rotate-0' : 'rotate-180')}
        />
        <Text variant='body_s_400'>{title}</Text>
      </div>

      <div className={twMerge('h-auto w-full p-4 transition-all', isCollapse ? 'block' : 'hidden')}>
        {children}
      </div>
    </div>
  )
}
