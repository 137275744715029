import { OutlinedInputProps, OutlinedTextFieldProps, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'

import TailwindConfig from 'tailwind.config'

export type WuTextfieldProps = Omit<OutlinedTextFieldProps, 'variant'>

const TailwindColor = TailwindConfig.theme.extend.colors
const TailwindFontFamily = TailwindConfig.theme.extend.fontFamily

const useStyles = makeStyles(() => ({
  root: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::placeholder': {
      height: '100%',
    },
  },
  labelStyles: {
    transform: 'none',
    display: 'flex',
    flexShrink: 0,
    position: 'relative',
    color: `${TailwindColor['grayscale-800']}!important`,
    fontWeight: 500,
    fontFamily: TailwindFontFamily['noto'].join(','),
    fontSize: '0.875rem',
    lineHeight: '1.14',
    letterSpacing: '0.5px',
    paddingBottom: '0.5rem',
  },
  outlineStyles: {
    borderColor: TailwindColor['grayscale-200'],
  },
  outlineStylesDisabled: {
    backgroundColor: TailwindColor['grayscale-100'],
  },
  labelAsteriskStyle: {
    color: TailwindColor['peach-500'],
  },
}))

const WuTextfield = React.forwardRef(
  (props: Omit<OutlinedTextFieldProps, 'variant'>, ref: React.Ref<any>) => {
    const {
      size,
      fullWidth,
      InputProps = {},
      inputProps,
      classes,
      InputLabelProps,
      disabled,
      ...restProps
    } = props

    const useStyleClasses = useStyles()

    return (
      <TextField
        variant='outlined'
        size={size ?? 'small'}
        fullWidth={fullWidth !== undefined ? fullWidth : true}
        disabled={disabled}
        InputProps={{
          ...(InputProps as OutlinedInputProps),
          style: {
            padding: '0.688rem 1rem',
            gap: '0.5rem',
            height: '100%',
            backgroundColor: TailwindColor[disabled ? 'grayscale-100' : 'grayscale-000'],
            overflow: 'hidden',
            ...InputProps.style,
          },
          sx: {
            ...InputProps.sx,
            borderRadius: '8px',
          },
          notched: false,
          classes: {
            notchedOutline: useStyleClasses.outlineStyles,
            disabled: useStyleClasses.outlineStylesDisabled,
          },
        }}
        inputProps={{
          style: { padding: 0, height: '100%', fontSize: '0.875rem' },
          sx: {
            '&::placeholder': {
              color: TailwindColor['grayscale-300'],
              fontWeight: 500,
              opacity: 1,
            },
          },

          ...inputProps,
        }}
        classes={{ ...classes, root: useStyleClasses.root }}
        ref={ref}
        InputLabelProps={{
          shrink: true,
          classes: {
            root: useStyleClasses.labelStyles,
            asterisk: useStyleClasses.labelAsteriskStyle,
          },
          ...InputLabelProps,
        }}
        sx={{
          '& .MuiInputBase-root.Mui-disabled': {
            '& > fieldset': {
              borderColor: TailwindColor['grayscale-100'],
            },
          },
          '& .MuiInputBase-root.Mui-focused': {
            '& > fieldset': {
              borderWidth: '1px',
            },
          },
          '& .MuiOutlinedInput-notchedOutline>legend>span': {
            display: props.label ? 'none' : 'inline-block',
          },
        }}
        FormHelperTextProps={{
          sx: { margin: '4px 0 0 0' },
        }}
        {...restProps}
      />
    )
  },
)

export default WuTextfield
