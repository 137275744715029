import { MediaFileUploaderWithInfo, Textfield } from '@wordup/lib-portal/components'
import { useTranslation } from 'next-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import type { BrandPageInputFormTypes } from '../../../types'

type EditFormProps = {
  index: number
  nestedIndex: number
}

export const EditForm = ({ index, nestedIndex }: EditFormProps) => {
  const { t } = useTranslation()
  const { setValue, getValues } = useFormContext<BrandPageInputFormTypes>()
  const imageType = getValues(`inputItems.${index}.images.type`)

  return (
    <div className='flex flex-col gap-4'>
      <Controller
        name={`inputItems.${index}.images.attributes.custom.data.${nestedIndex}.title`}
        render={({ field }) => (
          <Textfield
            {...field}
            onChange={e => {
              const value = e.target.value
              field.onChange(value)
              setValue(
                `previewItems.${index}.images.attributes.custom.data.${nestedIndex}.title`,
                value,
              )
            }}
            label={t('brand_management.images.image_title')}
            placeholder={t('brand_management.images.fill_in_image_title')}
          />
        )}
      />

      <Controller
        name={`inputItems.${index}.images.attributes.custom.data.${nestedIndex}.description`}
        render={({ field }) => (
          <Textfield
            {...field}
            onChange={e => {
              const value = e.target.value

              field.onChange(value)

              setValue(
                `previewItems.${index}.images.attributes.custom.data.${nestedIndex}.description`,
                value,
              )
            }}
            label={t('brand_management.images.image_description')}
            placeholder={t('brand_management.images.fill_in_image_description')}
          />
        )}
      />
      <Controller
        name={`inputItems.${index}.images.attributes.custom.data.${nestedIndex}.url`}
        render={({ field }) => (
          <Textfield
            {...field}
            onChange={e => {
              const value = e.target.value
              field.onChange(value)

              setValue(
                `previewItems.${index}.images.attributes.custom.data.${nestedIndex}.url`,
                value,
              )
            }}
            label={t('brand_management.images.image_link')}
            placeholder={t('brand_management.images.fill_in_image_link')}
          />
        )}
      />

      <Controller
        name={`inputItems.${index}.images.attributes.custom.data.${nestedIndex}.imageUrlMobile`}
        render={({ field, fieldState: { error } }) => {
          return (
            <MediaFileUploaderWithInfo
              mode='shop'
              namespace={'brand'}
              materialId=''
              type='image'
              formats={['png', 'jpg']}
              onChange={({ url }) => {
                field.onChange(url)
                setValue(
                  `previewItems.${index}.images.attributes.custom.data.${nestedIndex}.imageUrlMobile`,
                  url,
                )
              }}
              initUrl={field.value ?? ''}
              label={t('brand_management.images.mobile')}
              extraInfo={
                imageType === 'carousel_images'
                  ? t('brand_management.images.aspect_ratio_mobile')
                  : ''
              }
              maxSizeInMb={1}
            />
          )
        }}
      />

      <Controller
        name={`inputItems.${index}.images.attributes.custom.data.${nestedIndex}.imageUrlDesktop`}
        render={({ field }) => (
          <MediaFileUploaderWithInfo
            mode='shop'
            namespace={'brand'}
            materialId=''
            type='image'
            formats={['png', 'jpg']}
            onChange={({ url }) => {
              field.onChange(url)
              setValue(
                `previewItems.${index}.images.attributes.custom.data.${nestedIndex}.imageUrlDesktop`,
                url,
              )
            }}
            initUrl={field.value ?? ''}
            label={t('brand_management.images.desktop')}
            extraInfo={
              imageType === 'carousel_images'
                ? t('brand_management.images.aspect_ratio_desktop')
                : ''
            }
            maxSizeInMb={1}
            helperText={t('brand_management.images.desktop_reminder')}
          />
        )}
      />
    </div>
  )
}
