import { Fragment } from 'react'
import { BrandPageBlockTypes } from '../types'
import { BrandInfoForm, EditFormWithCollapseBlock } from './form'

type EditFormBlockProps = {
  index: number
  type: BrandPageBlockTypes
  handlePrevious: () => void
  orgId: string
  selectedBlockIndex?: number
}

const EditFormBlock = ({
  index,
  type,
  handlePrevious,
  selectedBlockIndex,
  orgId,
}: EditFormBlockProps) => {
  if (index !== selectedBlockIndex) return null

  const renderForm = () => {
    if (type === 'brandInfo') {
      return <BrandInfoForm index={index} handlePrevious={handlePrevious} />
    }

    const editableTypes: BrandPageBlockTypes[] = [
      'text',
      'images',
      'videos',
      'products',
      'gap',
      'couponList',
    ]

    if (editableTypes.includes(type)) {
      return (
        <EditFormWithCollapseBlock
          type={type}
          index={index}
          handlePrevious={handlePrevious}
          orgId={orgId}
        />
      )
    }

    return null
  }

  return <Fragment>{renderForm()}</Fragment>
}

export default EditFormBlock
