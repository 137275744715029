import { twMerge } from 'tailwind-merge'

type PreviewBlockLayoutProps = {
  isMobileScreen?: boolean
  mainContentChildren: React.ReactNode
  headerChildren?: React.ReactNode
  leftContentChildren?: React.ReactNode
}

export const PreviewBlockLayout = ({
  isMobileScreen,
  headerChildren,
  leftContentChildren,
  mainContentChildren,
}: PreviewBlockLayoutProps) => {
  return (
    <div className='flex flex-col'>
      {isMobileScreen ? null : headerChildren}
      <div
        className={twMerge(
          'w-full',
          isMobileScreen
            ? 'flex flex-col'
            : 'grid grid-cols-12 items-start justify-center gap-10 px-20 pt-10',
        )}
      >
        {isMobileScreen ? null : leftContentChildren}

        <div className={twMerge('flex w-full flex-col', isMobileScreen ? '' : 'col-span-7')}>
          {mainContentChildren}
        </div>
      </div>
    </div>
  )
}
