import { Icon } from '@libs-components/components/icon'

type StatusIndicatorProps = {
  status: 'approved' | 'rejected'
}

const StatusIndicator = ({ status }: StatusIndicatorProps) => {
  if (status === 'approved') {
    return (
      <div className='flex items-center justify-center rounded-full bg-green-100 p-1'>
        <Icon type='check' color='green-500' />
      </div>
    )
  }

  return (
    <div className='flex items-center justify-center rounded-full bg-yellow-100 p-1'>
      <Icon type='close' color='yellow-700' />
    </div>
  )
}

export { StatusIndicator }
