import { getManageMaterialTagGroups, GetManageMaterialTagGroupsResponse } from '@wordup/apis'
import { useTranslation } from 'next-i18next'
import useSWR from 'swr'
import { apiCaller } from '../api'
import { Swal } from '@wordup/lib-portal/utils'
import axios from 'axios'

export const useManageMaterialTagGroups = (materialId?: string) => {
  const { t } = useTranslation()
  const { data: materialTagGroups, mutate: mutateMaterialTagGroups } = useSWR(
    materialId ? getManageMaterialTagGroups({ materialId }) : null,
    async payloads => {
      try {
        const res = await apiCaller<GetManageMaterialTagGroupsResponse>(payloads)
        return res.data
      } catch (err) {
        if (axios.isAxiosError(err)) {
          Swal.fire({
            icon: 'warning',
            text: err?.message,
            confirmButtonText: t('common.ok'),
          })
        }
      }
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateOnMount: true,
    },
  )

  return { materialTagGroups, mutateMaterialTagGroups }
}
