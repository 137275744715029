import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDebounce } from '@wordup/utils'
import { useGetManageProducts } from '@wordup/lib-portal/api'
import { useInView } from 'react-intersection-observer'
import { ProductListEditFormTypes } from '../../../types'
import { toast } from '@libs-components/index'
import { useTranslation } from 'next-i18next'

import { useFieldArray, useFormContext } from 'react-hook-form'
import type { BrandPageInputFormTypes } from '../../../types'
import { createEmptyPickedProductListCustomDataAttributes } from '../../../utils'
import { PickedProductListCustom } from '@libs-components/types/page-components/components/picked-product-list'

const maxCount = 20

export const useProductFormHandler = (index: number, orgId: string) => {
  const { t } = useTranslation()

  const { control, getValues, setValue } = useFormContext<BrandPageInputFormTypes>()
  const { fields, move, replace, remove } = useFieldArray({
    control,
    name: `inputItems.${index}.products.attributes.custom`,
  })

  const { move: movePreview, remove: removePreview } = useFieldArray({
    control,
    name: `previewItems.${index}.products.attributes.custom`,
  })

  const [openProductModal, setOpenProductModal] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [selectedProducts, setSelectedProducts] = useState<ProductListEditFormTypes[]>([])

  const [isInitial, setIsInitial] = useState(false)

  const debounceSearchValue = useDebounce(searchValue, 500)

  const { products, isValidating, setNextPage, currentPage, totalPages } = useGetManageProducts({
    queryString: debounceSearchValue,
    orgId,
    shopReady: true,
    shopListed: true,
  })

  const { ref, inView } = useInView({ threshold: 0.9 })

  const selectedProductCount = useMemo(() => selectedProducts.length, [selectedProducts])

  const closeAddModal = () => {
    setOpenProductModal(false)
  }

  const openAddModal = () => {
    if (selectedProductCount >= maxCount) {
      toast.error({
        message: t('brand_management.products.max_count', {
          maxCount,
        }),
      })
      return
    }
    setOpenProductModal(true)
  }
  const handleCancel = () => {
    closeAddModal()
    const initData = getValues(`inputItems.${index}.products.attributes.custom`)
    const initDataPreview = getValues(`previewItems.${index}.products.attributes.custom`)
    setSelectedProducts(initData)
    setValue(`previewItems.${index}.products.attributes.custom`, initDataPreview)
  }

  const handleMoveItem = (from: number, to: number) => {
    move(from, to)
    movePreview(from, to)
  }

  const handleDeleteItem = (index: number) => {
    remove(index)
    removePreview(index)
    updateSelectedProducts({
      productId: fields[index].productId,
      coverImageUrl: fields[index].coverImageUrl,
      name: fields[index].name,
      isSelected: true,
    })
  }

  const lastItem = products?.[products.length - 1]

  const getIsSelected = useCallback(
    (productId: string) => {
      return selectedProducts.some(p => p.productId === productId)
    },
    [selectedProducts],
  )

  const updateSelectedProducts = ({
    productId,
    coverImageUrl,
    name,
    isSelected,
  }: {
    productId: string
    coverImageUrl: string
    name: string
    isSelected?: boolean
  }) => {
    setSelectedProducts(prev =>
      isSelected
        ? prev.filter(p => p.productId !== productId)
        : [...prev, { productId, coverImageUrl, name, shopListed: true }],
    )
  }

  const handleSubmitProduct = useCallback(() => {
    if (selectedProductCount > maxCount) {
      toast.error({
        message: t('brand_management.products.max_count', {
          maxCount,
        }),
      })
      return
    }

    closeAddModal()
    replace(selectedProducts)
    const updatePreviewProducts: PickedProductListCustom = selectedProducts.map(product => {
      const targetProduct = products.find(p => p.id === product.productId)

      return {
        id: product.productId,
        type: 'saleable_product',
        attributes: {
          ...createEmptyPickedProductListCustomDataAttributes(),
          productLine: targetProduct?.attributes.productLine || 'course',
          listPrice: targetProduct?.attributes.listPrice || '',
          salePrice: targetProduct?.attributes.salePrice || '',
          publisherName: targetProduct?.attributes.publisherName || '',
          authorName: targetProduct?.attributes.authorName || '',
          copiesSold: targetProduct?.attributes.copiesSold || 0,
          name: product.name,
          coverImageUrl: product.coverImageUrl,
          shopCoverImageUrl: product.coverImageUrl,
        },
      }
    })

    setValue(`previewItems.${index}.products.attributes.custom`, updatePreviewProducts)
  }, [selectedProducts])

  useEffect(() => {
    if (isValidating) return
    if (products && products.length > 0 && inView && currentPage < totalPages) {
      setNextPage(currentPage + 1)
    }
  }, [products, inView, currentPage, totalPages, isValidating])

  useEffect(() => {
    if (isInitial) return

    if (fields.length > 0) {
      const initProducts = fields.map(field => ({
        productId: field.productId,
        coverImageUrl: field.coverImageUrl,
        name: field.name,
        shopListed: field.shopListed,
      }))

      setSelectedProducts(initProducts)
      setIsInitial(true)
    }
  }, [fields, isInitial])

  return {
    fields,
    openProductModal,
    searchValue,
    products,
    lastItem,
    selectedProductCount,
    isValidating,
    maxCount,
    ref,
    getIsSelected,
    updateSelectedProducts,
    closeAddModal,
    openAddModal,
    handleCancel,
    setSearchValue,
    handleMoveItem,
    handleSubmitProduct,
    handleDeleteItem,
  }
}
