import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import type { BrandPageInputFormTypes } from '../../types'
import { MediaFileUploaderWithInfo, Textfield } from '@wordup/lib-portal/components'
import { Text } from '@libs-components/components/text'
import { useTranslation } from 'next-i18next'
import { BackButton } from '../../components'

type BrandInfoProps = {
  index: number
  handlePrevious: () => void
}

export const BrandInfoForm = ({ index, handlePrevious }: BrandInfoProps) => {
  const { t } = useTranslation()
  const { control, setValue } = useFormContext<BrandPageInputFormTypes>()

  const { fields } = useFieldArray({
    control,
    name: `inputItems.${index}.brandInfo.attributes.custom.data.socialLinks`,
  })

  return (
    <div className='flex h-full flex-col'>
      <div className='border-grayscale-100 border-b p-4'>
        <BackButton
          handlePrevious={handlePrevious}
          text={`${t('common.edit')}${t('brand_management.brand_info.title')}`}
        />
      </div>

      <div className='flex flex-col gap-4 overflow-auto p-4 pb-6'>
        <Controller
          name={`inputItems.${index}.brandInfo.attributes.custom.data.banner.mobile`}
          control={control}
          render={({ field }) => {
            return (
              <MediaFileUploaderWithInfo
                mode='shop'
                namespace={'brand'}
                materialId=''
                type='image'
                formats={['png', 'jpg']}
                onChange={({ url }) => {
                  field.onChange(url)
                  setValue(
                    `previewItems.${index}.brandInfo.attributes.custom.data.banner.mobile`,
                    url,
                  )
                }}
                initUrl={field.value ?? ''}
                label={t('brand_management.brand_info.banner_mobile')}
                extraInfo={'393*197 PX'}
                maxSizeInMb={1}
              />
            )
          }}
        />

        <Controller
          name={`inputItems.${index}.brandInfo.attributes.custom.data.banner.desktop`}
          control={control}
          render={({ field }) => {
            return (
              <MediaFileUploaderWithInfo
                mode='shop'
                namespace={'brand'}
                materialId=''
                type='image'
                formats={['png', 'jpg']}
                onChange={({ url }) => {
                  field.onChange(url)
                  setValue(
                    `previewItems.${index}.brandInfo.attributes.custom.data.banner.desktop`,
                    url,
                  )
                }}
                initUrl={field.value ?? ''}
                label={t('brand_management.brand_info.banner_desktop')}
                extraInfo={'1512*287 PX'}
                maxSizeInMb={1}
              />
            )
          }}
        />

        <Controller
          name={`inputItems.${index}.brandInfo.attributes.custom.data.avatar`}
          control={control}
          render={({ field }) => {
            return (
              <MediaFileUploaderWithInfo
                mode='shop'
                namespace={'brand'}
                materialId=''
                type='image'
                formats={['png', 'jpg']}
                onChange={({ url }) => {
                  field.onChange(url)
                  setValue(`previewItems.${index}.brandInfo.attributes.custom.data.avatar`, url)
                }}
                initUrl={field.value ?? ''}
                label={t('brand_management.brand_info.avatar')}
                extraInfo={'60*60 PX'}
                maxSizeInMb={1}
              />
            )
          }}
        />
        <Controller
          name={`inputItems.${index}.brandInfo.attributes.custom.data.name`}
          control={control}
          render={({ field }) => {
            return (
              <Textfield
                label={t('brand_management.brand_info.brand_name')}
                placeholder={t('brand_management.brand_info.fill_in_brand_name')}
                onChange={e => {
                  field.onChange(e.target.value)
                  setValue(
                    `previewItems.${index}.brandInfo.attributes.custom.data.name`,
                    e.target.value,
                  )
                }}
                value={field.value || ''}
              />
            )
          }}
        />
        <Controller
          name={`inputItems.${index}.brandInfo.attributes.custom.data.description`}
          control={control}
          render={({ field }) => {
            return (
              <Textfield
                label={t('brand_management.brand_info.brand_description')}
                placeholder={t('brand_management.brand_info.fill_in_brand_description')}
                onChange={e => {
                  field.onChange(e.target.value)
                  setValue(
                    `previewItems.${index}.brandInfo.attributes.custom.data.description`,
                    e.target.value,
                  )
                }}
                value={field.value || ''}
              />
            )
          }}
        />
        <Controller
          name={`inputItems.${index}.brandInfo.attributes.custom.data.intro`}
          control={control}
          render={({ field }) => {
            return (
              <Textfield
                label={t('brand_management.brand_info.brand_intro')}
                placeholder={t('brand_management.brand_info.fill_in_brand_intro')}
                onChange={e => {
                  field.onChange(e.target.value)
                  setValue(
                    `previewItems.${index}.brandInfo.attributes.custom.data.intro`,
                    e.target.value,
                  )
                }}
                value={field.value || ''}
              />
            )
          }}
        />
        <Text variant='heading_m_500'>{t('brand_management.social_links')}</Text>

        {fields.map((item, nestedIndex) => {
          const { type } = item
          let label = 'IG'
          switch (type) {
            case 'facebook':
              label = 'FB'
              break
            case 'instagram':
              label = 'IG'
              break
            case 'line':
              label = 'line'
              break
          }
          return (
            <Controller
              key={item.id}
              name={`inputItems.${index}.brandInfo.attributes.custom.data.socialLinks.${nestedIndex}`}
              control={control}
              render={({ field }) => {
                return (
                  <Textfield
                    label={label}
                    placeholder={t('brand_management.fill_in_link')}
                    onChange={e => {
                      const link = e.target.value

                      field.onChange({
                        type,
                        link,
                      })
                      setValue(
                        `previewItems.${index}.brandInfo.attributes.custom.data.socialLinks.${nestedIndex}`,
                        {
                          type,
                          link,
                        },
                      )
                    }}
                    value={field.value.link || ''}
                  />
                )
              }}
            />
          )
        })}
      </div>
    </div>
  )
}
