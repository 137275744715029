import { getManageProducts, GetManageProductsResponse } from '@wordup/apis'
import { apiCaller } from '../features'
import useSWRInfinite from 'swr/infinite'

export const useGetManageProducts = ({
  queryString,
  orgId,
  shopReady,
  shopListed,
}: {
  queryString?: string
  orgId: string
  shopReady?: boolean
  shopListed?: boolean
}) => {
  const getKey = (pageIndex: number) => {
    return getManageProducts({ q: queryString, page: pageIndex + 1, orgId, shopReady, shopListed })
  }
  const { data, error, mutate, size, setSize, isValidating } = useSWRInfinite(
    getKey,
    async payload => {
      if (!orgId) return

      const res = await apiCaller<GetManageProductsResponse>(payload)
      if (res.status === 200) {
        return { data: res.data.data, header: res.headers as any }
      }
    },
  )

  const totalPages = (data as any)?.[0]?.header?.['total-pages'] || 0

  const products =
    data
      ?.map(data => data?.data)
      .flat()
      .filter(product => product !== undefined) || []

  return {
    products,
    totalPages,
    error,
    mutate,
    currentPage: size,
    setNextPage: setSize,
    isValidating,
  }
}
