import type { DynamicOptionsLoadingProps } from 'next/dynamic'
import { Skeleton } from '../../../../components/skeleton'

const BrandBasicInfoSkeleton = (_: DynamicOptionsLoadingProps) => {
  return (
    <div>
      {/* banner/hero image */}
      <div className='relative aspect-video'>
        <Skeleton className='absolute inset-0 h-full w-full ' />
      </div>
      <div className='flex flex-col justify-center gap-y-5 p-4'>
        {/* logo + information */}
        <div className='flex items-center gap-x-4'>
          <Skeleton className='h-[60px] w-[60px] flex-shrink-0 rounded-full' />
          <div className='w-full'>
            <Skeleton className='mb-4 h-6 w-full' />
            <Skeleton className='h-12 w-full' />
          </div>
        </div>

        {/* intro */}
        <Skeleton className='h-16 w-full' />

        {/* overview */}
        <div className='flex items-center gap-x-4'>
          <div>
            <Skeleton className='h-4 w-[62px]' />
            <Skeleton className='h-4 w-[62px]' />
          </div>

          <div>
            <Skeleton className='h-4 w-[62px]' />
            <Skeleton className='h-4 w-[62px]' />
          </div>

          <div>
            <Skeleton className='h-4 w-[83px]' />
            <Skeleton className='h-4 w-[83px]' />
          </div>
        </div>

        {/* links */}
        <ul className='m-0 flex list-none items-center gap-x-4 p-0'>
          <Skeleton className='h-7 w-7 rounded-full' />
          <Skeleton className='h-7 w-7 rounded-full' />
          <Skeleton className='h-7 w-7 rounded-full' />
        </ul>
      </div>
    </div>
  )
}

export { BrandBasicInfoSkeleton }
