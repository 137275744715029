import { getFeedbackForFeatures } from '@wordup/apis'
import { apiCaller } from '../../api'
import useSWR from 'swr'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'

export const useUserHasSubmittedFeedbackForAiSentence = () => {
  const { data: userFeedbackForAiSentence, mutate } = useSWR(
    getFeedbackForFeatures({ kind: 'ai_sentence' }),
    async params => {
      try {
        const res = await apiCaller(params)

        return res.data as {
          data: {
            attributes: {
              content: string
              createdAt: string
              device: string
              kind: string
              rating: number
              willingToInterview: boolean
            }
            id: string
            type: 'user_feedback_for_feature'
          }
        }
      } catch (err) {
        console.log('get feedback for features error', err)
      }
    },
  )
  const hasSubmitted = useMemo(() => {
    return !isEmpty(userFeedbackForAiSentence?.data)
  }, [userFeedbackForAiSentence?.data])

  return {
    hasSubmitted,
    refresh: mutate,
  }
}
