import { getManagePageById, GetManagePageResponse } from '@wordup/apis'
import { useTranslation } from 'next-i18next'
import useSWR from 'swr'
import { apiCaller } from '../../api'
import { Swal } from '@wordup/lib-portal/utils'
import axios from 'axios'
import { BlocksType } from '../types'
import type { PageComponentType } from '@libs-components/types'

export const useSwrGetManagePageId = (pageId?: string | null, orgId?: string) => {
  const { t } = useTranslation()
  const { data: managePageData, mutate: mutateManagePage } = useSWR(
    pageId && orgId ? getManagePageById(pageId, orgId) : null,
    async payloads => {
      try {
        const res = await apiCaller<
          GetManagePageResponse<BlocksType[], PageComponentType.MetaType>
        >(payloads)
        return res.data
      } catch (err) {
        if (axios.isAxiosError(err)) {
          Swal.fire({
            icon: 'warning',
            text: err?.message,
            confirmButtonText: t('common.ok'),
          })
        }
      }
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateOnMount: true,
    },
  )
  return { managePageData, mutateManagePage }
}
