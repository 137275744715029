import {
  createEmptyHtmlBlockBlock,
  createEmptyBrandBasicInfoBlock,
  createEmptyCarouselImagesBlock,
  createEmptyPickedProductListBlock,
  createEmptyCarouselVideosBlock,
  createEmptyGapBlock,
  createEmptyCouponListBlock,
  createEmptyPreviewPickedProductListBlock,
  createEmptyPreviewCouponListBlock,
} from '.'
import { BrandPageBlockTypes } from '../types'

export const createEmptyBlock = (
  type: BrandPageBlockTypes,
  orgId?: string,
): { data: any; preview: any } => {
  switch (type) {
    case 'brandInfo':
      return {
        data: createEmptyBrandBasicInfoBlock(),
        preview: createEmptyBrandBasicInfoBlock(),
      }
    case 'text':
      return {
        data: createEmptyHtmlBlockBlock(),
        preview: createEmptyHtmlBlockBlock(),
      }
    case 'images':
      return {
        data: createEmptyCarouselImagesBlock(),
        preview: createEmptyCarouselImagesBlock(),
      }
    case 'videos':
      return {
        data: createEmptyCarouselVideosBlock(),
        preview: createEmptyCarouselVideosBlock(),
      }
    case 'gap':
      return {
        data: createEmptyGapBlock(),
        preview: createEmptyGapBlock(),
      }
    case 'couponList':
      return {
        data: createEmptyCouponListBlock(),
        preview: createEmptyPreviewCouponListBlock(),
      }
    case 'products':
      return {
        data: createEmptyPickedProductListBlock(),
        preview: createEmptyPreviewPickedProductListBlock(orgId ? +orgId : 0),
      }
    default:
      return { data: null, preview: null }
  }
}
