import { BlocksType, CarouselImagesFormTypes, CarouselVideosFormTypes } from '../types'
import type { PageComponentType } from '@libs-components/types'

export const dataAdapter = (data?: BlocksType[], meta?: PageComponentType.MetaType) => {
  if (!data) {
    return {
      inputItems: [],
      previewItems: [],
    }
  }

  const mapInputItem = (item: BlocksType) => {
    const { id, attributes, type } = item

    switch (type) {
      case 'brand_basic_info':
        return {
          brandInfo: {
            type,
            id,
            attributes: {
              custom: attributes.custom,
            },
          },
        }

      case 'html_block':
        const { startsAt, endsAt, custom } = attributes
        return {
          text: {
            type,
            id,
            attributes: { startsAt, endsAt, custom },
          },
        }

      case 'carousel_images': {
        const { custom, description, name, startsAt, endsAt } = attributes
        const isSingleImage = custom?.data?.length === 1
        return {
          images: {
            type: isSingleImage ? 'single_image' : ('carousel_images' as CarouselImagesFormTypes),
            id,
            attributes: { startsAt, endsAt, custom, name, description },
          },
        }
      }

      case 'carousel_videos': {
        const { custom, description, name, startsAt, endsAt } = attributes
        const isSingleVideo = custom?.data?.length === 1
        return {
          videos: {
            type: isSingleVideo ? 'single_video' : ('carousel_videos' as CarouselVideosFormTypes),
            id,
            attributes: { startsAt, endsAt, custom, name, description },
          },
        }
      }

      case 'picked_product_list': {
        const { custom, description, name, startsAt, endsAt } = attributes
        const customData = custom.map(({ id, attributes: itemAttr }: any) => ({
          productId: id,
          coverImageUrl: itemAttr.coverImageUrl,
          name: itemAttr.name,
          shopListed: itemAttr.shopListed,
        }))
        return {
          products: {
            type,
            id,
            attributes: { startsAt, endsAt, custom: customData, name, description },
          },
        }
      }

      case 'gap':
        return {
          gap: { type, id, attributes },
        }

      case 'coupon_list': {
        const { custom, description, name, startsAt, endsAt } = attributes
        const couponCodes = Array.isArray(custom.couponCodes) ? custom.couponCodes : []
        return {
          couponList: {
            type,
            id,
            attributes: { startsAt, endsAt, custom: { couponCodes }, name, description },
          },
        }
      }

      default:
        return {}
    }
  }

  const mapPreviewItem = (item: BlocksType) => {
    const { type } = item

    switch (type) {
      case 'brand_basic_info':
        return { brandInfo: { ...item, attributes: { ...item.attributes, meta } } }
      case 'html_block':
        return { text: item }
      case 'carousel_images': {
        const isSingleImage = item.attributes.custom.data.length === 1
        return {
          images: {
            ...item,
            type: isSingleImage ? 'single_image' : ('carousel_images' as CarouselImagesFormTypes),
          },
        }
      }
      case 'carousel_videos': {
        const isSingleVideo = item.attributes.custom.data.length === 1
        return {
          videos: {
            ...item,
            type: isSingleVideo ? 'single_video' : ('carousel_videos' as CarouselVideosFormTypes),
          },
        }
      }
      case 'picked_product_list':
        return { products: { ...item, attributes: { ...item.attributes, meta } } }
      case 'gap':
        return { gap: item }
      case 'coupon_list':
        return { couponList: item }
      default:
        return {}
    }
  }

  const inputItems = data.map(mapInputItem).filter(item => Object.keys(item).length > 0)
  const previewItems = data.map(mapPreviewItem).filter(item => Object.keys(item).length > 0)

  return { inputItems, previewItems }
}
