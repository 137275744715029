import { Icon } from '@libs-components/components/icon'
import { Text } from '@libs-components/components/text'
import { VideoPlayer } from '../../../../../components'
import { WuTooltip, WuTooltipProps } from '../../../../../components'
import { twMerge } from 'tailwind-merge'
import { Skeleton } from '@libs-components/components/skeleton'

type VideoPreviewProps = {
  type: 'video'
  videoUrl: string
  imgUrl?: string
}

type ImagePreviewProps = {
  type: 'image'
  imgUrl?: string
  imgSize?: 'square' | 'rectangle'
}

type LeftItemPreviewProps = VideoPreviewProps | ImagePreviewProps

type PreviewItemProps = {
  handleDelete?: () => void
  title?: string
  description?: string
  handleEdit?: () => void
  rightColum?: React.ReactNode | null
  titleLineClamp?: number
  tooltip?: WuTooltipProps
  disabled?: boolean
  leftItem: LeftItemPreviewProps
}

export const PreviewItem = ({
  title,
  description,
  handleEdit,
  handleDelete,
  rightColum,
  titleLineClamp,
  tooltip,
  disabled,
  leftItem,
}: PreviewItemProps) => {
  const stringLineClamp = titleLineClamp ? `line-clamp-${titleLineClamp}` : 'line-clamp-1'

  return (
    <div className='group relative flex w-full justify-between gap-2'>
      <div className={twMerge('flex w-full gap-2', disabled ? 'opacity-50' : '')}>
        <LeftItem {...leftItem} />

        <div className='flex w-full items-center justify-between gap-2'>
          <div className='flex w-full flex-col break-all text-left'>
            <Text variant='body_m_500' className={twMerge('break-words', stringLineClamp)}>
              {title}
            </Text>
            {description ? (
              <Text variant='body_m_400' className='line-clamp-1 break-words'>
                {description}
              </Text>
            ) : null}
          </div>

          {rightColum ? rightColum : null}
        </div>
      </div>
      <div className='bg-grayscale-000 absolute right-0 hidden translate-y-1/2 gap-2 group-hover:flex'>
        {typeof handleEdit === 'function' ? (
          <button onClick={handleEdit} disabled={disabled}>
            <Icon type='edit' />
          </button>
        ) : null}
        {typeof handleDelete === 'function' ? (
          <button onClick={handleDelete}>
            <Icon type='delete' />
          </button>
        ) : null}

        {tooltip ? <WuTooltip icon={<Icon type='info' color='peach-500' />} {...tooltip} /> : null}
      </div>
    </div>
  )
}

const LeftItem = (leftItem: LeftItemPreviewProps) => {
  switch (leftItem.type) {
    case 'video':
      return (
        <div className='pointer-events-none h-auto w-full max-w-[70px] flex-shrink-0'>
          {leftItem.videoUrl ? (
            <VideoPlayer url={leftItem.videoUrl} imageUrl={leftItem.imgUrl} hideCustomControls />
          ) : (
            <Skeleton className='flex aspect-video w-full items-center justify-center'>
              <Icon type='play_outlined' />
            </Skeleton>
          )}
        </div>
      )
    case 'image':
      const imgSize = leftItem.imgSize || 'square'
      const squareSize = 'max-w-[50px] aspect-[1/1]'
      const rectangleSize = 'max-w-[70px] aspect-[16/9]'

      return leftItem.imgUrl ? (
        <img
          src={leftItem.imgUrl}
          className={twMerge(
            'h-auto w-full flex-shrink-0 rounded-lg object-cover',
            imgSize === 'square' ? squareSize : rectangleSize,
          )}
        />
      ) : (
        <EmptyImageView imgSize={imgSize === 'square' ? squareSize : rectangleSize} />
      )
    default:
      return null
  }
}

const EmptyImageView = ({ imgSize }: { imgSize?: string }) => {
  return (
    <Skeleton
      className={twMerge(
        'bg-grayscale-100 flex w-full animate-pulse items-center justify-center rounded-lg',
        imgSize,
      )}
    >
      <Icon type='imagesmode' />
    </Skeleton>
  )
}
