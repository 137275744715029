import MoreTimeIcon from '@mui/icons-material/MoreTime'

export const UploadAudioIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='19' height='19'>
      <g transform='matrix(0.019791666 0 0 0.019791666 -0 -1.9)'>
        <path
          d='M427 864C 454.33334 864 477.6667 854.563 497 835.689C 509.88892 823.10645 519.55554 799.8767 526 766L526 766L526 564L642 564L642 504L486 504L486 691C 478.6667 685 469.8717 680.3334 459.615 677C 449.359 673.6667 438.48734 672 427 672C 400.82202 672 378.77768 681.1667 360.867 699.5C 342.9557 717.8334 334 740.1667 334 766.5C 334 784.05554 342.9557 806.8889 360.867 835C 378.77768 854.3334 400.82202 864 427 864zM220 976C 204 976 190 970 178 958C 170 950 164 936 160 916L160 916L160 236C 160 220.00002 166 206.00002 178 194C 186 186 200 180 220 176L220 176L581 176L800 395L800 916C 800.00006 932 794.00006 946 782 958C 774 966 760 972 740 976L740 976L220 976zM551 422L551 236L220 236L220 916L740 916L740 422L551 422zM220 236L220 422L220 236L220 916L220 236z'
          stroke='none'
        />
      </g>
    </svg>
  )
}

export const AddTimeStampIcon = () => <MoreTimeIcon className='text-lg' />
