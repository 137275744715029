import type { LineItemInfoModalBodyProps } from '.'
import React from 'react'
import { Icon } from '../../../../icon'
import { BUTTON_RESET_STYLE } from '../constants'

const GiftModalBody = ({ item, clickMap, t }: LineItemInfoModalBodyProps) => {
  if (!item) return null

  return (
    <div
      style={{
        textAlign: 'center',
        color: 'rgb(56,59,61)', // text-grayscale-800
      }}
    >
      <Icon
        type='check_circle'
        size={60}
        style={{
          color: '#16C172', // green-500
          margin: '0 auto 8px',
        }}
      />
      <div
        style={{
          fontSize: '1.5rem',
          lineHeight: '2rem', // text-2xl
          marginBottom: 8,
        }}
      >
        {t('crowdin:wu-components.line-item-info.redeem-code.gift-popup.title')}
      </div>
      <button
        style={{
          ...BUTTON_RESET_STYLE,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 4,
          width: '100%',
          height: 28,
          fontSize: '0.75rem',
          lineHeight: '1rem',
          marginBottom: 8,
          borderRadius: 8,
          backgroundColor: 'rgb(244,245,245)', //bg-grayscale-50
        }}
        onClick={clickMap?.onCopy}
      >
        {item.code}
        <Icon type='content_copy' color='grayscale-800' />
      </button>
      <div
        style={{
          fontSize: '1rem',
          lineHeight: '1.5rem', // text-base
          color: 'rgb(157,161,161)', // grayscale-400
          marginBottom: 4,
        }}
      >
        {t('crowdin:wu-components.line-item-info.redeem-code.gift-popup.description')}
      </div>
      <div
        style={{
          fontSize: '0.75rem',
          lineHeight: '1rem', // text-xs
          color: '#F06449', // peach-400
          marginBottom: 12,
        }}
      >
        {t('crowdin:wu-components.line-item-info.redeem-code.gift-popup.notice')}
      </div>
      <button
        style={{
          ...BUTTON_RESET_STYLE,
          width: '100%',
          height: 36,
          color: 'white',
          backgroundColor: 'rgb(114,175,255)', //bg-primary-500
          borderRadius: 18,
        }}
        onClick={clickMap?.onClose}
      >
        {t('crowdin:common.know-it')}
      </button>
    </div>
  )
}

export default GiftModalBody
