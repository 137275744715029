import { Icon } from '@libs-components/components/icon'
import { Text } from '@libs-components/components/text'

type BackButtonProps = {
  handlePrevious: () => void
  text: string
}
export const BackButton = ({ handlePrevious, text }: BackButtonProps) => {
  return (
    <button onClick={handlePrevious} className='flex items-center justify-start gap-2'>
      <Icon type='chevron_left' color='grayscale-800' />
      <Text variant='body_m_400'>{text}</Text>
    </button>
  )
}
