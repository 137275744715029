import { CarouselImagesCustom } from '@libs-components/types/page-components/components/carousel-images'
import type { CarouselImagesBlockType } from '../types'

export const createEmptyCarouselImagesBlock = (): CarouselImagesBlockType => ({
  id: '',
  type: 'single_image',
  attributes: {
    name: '',
    description: '',
    startsAt: '',
    endsAt: '',
    custom: {
      data: [createEmptyCarouselImage()],
    },
  },
})

export const createEmptyCarouselImage = () => ({
  title: '',
  description: '',
  imageUrlMobile: '',
  imageUrlDesktop: '',
  url: '',
})
