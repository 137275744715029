export const customCss = `
/* 圖片樣式 */
img {
  max-width: 100%;
  height: auto;
}

img.img-responsive {
  width: 100%;
  height: auto;
}

/* 段落樣式 */
p {
  margin: 0;
}

p > img {
  vertical-align: middle;
}

p:first-child {
  margin-top: 0;
}

p:last-child {
  margin-bottom: 0;
}

/* 標題樣式 */
h1, h2, h3 {
  letter-spacing: 1px;
  font-weight: bold;
  margin: 0.83em auto;
}

h1 {
  font-size: 1.5rem;
}

h1:first-child {
  margin-top: 0;
}

h2 {
  font-size: 1.25rem;
}

h3 {
  font-size: 1rem;
}

/* 鏈接樣式 */
a {
  color: #42badb;
  text-decoration: underline;
}

/* 無序列表樣式 */
ul {
  padding-left: 20px;
}
`
