import type { CredentialsType } from '@wordup/apis'
import type { AuthType, LangType, LoginTypes, SocialProviderType } from './types'
import styled from '@emotion/styled'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { ReactNode } from 'react'
import { Stage } from '../../utils/constants'
import { createCredentialStorage } from '../../utils/credential'
import { LocaleProvider, locales } from './local-provider'

import { createAuthInstance } from '../../utils/auth-utils'
import { Text } from '../text'
import ForgetPasswordLayout from './forget-password'
import PageDescription from './page-description'
import ResetPasswordForm from './reset-password-form'
import SignInForm from './sign-in-form'
import SignUpForm from './sign-up-form'
import SocialSignIn from './social'
import FormTitle from './title'
import './style.css'
import { twMerge } from 'tailwind-merge'

export interface AuthFormProps {
  logo?: ReactNode
  lang?: LangType
  stage: Stage
  domain: string
  className?: string
  termUrl?: string
  layout: AuthType
  setLayout: (mode: AuthType) => void
  apiDomain: string
  fbAppId?: string
  fbState?: string
  fbRedirectUri?: string
  googleClientId?: string
  appleClientId?: string
  appleRedirectUri?: string
  deviceIdentifier?: string
  onRequest?: () => void
  onSuccess: (res: any) => void
  onError: (info: {
    type: LoginTypes
    errCode?: any
    msg?: string
    res?: unknown
    status?: number
  }) => void
  onforgetPasswordSuccess: (msg: string) => void
  onforgetPasswordError: (info: { res?: unknown; status?: number }) => void
  onresetPasswordSuccess?: (msg: string) => void
  onresetPasswordError?: (info: {
    res?: unknown
    status?: number
    msg?: 'missing_credential'
  }) => void
  clientInfo?: string
  endUserClientInfo?: string
  forgetPasswordLink: string
}

export interface SignInFormData {
  email: string
  password: string
}
export interface SignUpFormData {
  name: string
  email: string
  password: string
}
export interface ForgetPasswordFormData {
  email: string
}
export interface ResetPasswordFormData {
  password: string
  confirmPassword: string
}

export const AuthForm = ({
  logo,
  stage,
  domain,
  className,
  apiDomain,
  layout = 'sign-in',
  termUrl,
  setLayout,
  lang = 'zh-TW',
  fbAppId,
  fbState,
  fbRedirectUri,
  googleClientId,
  appleClientId,
  appleRedirectUri,
  onError,
  onSuccess,
  onRequest,
  deviceIdentifier,
  clientInfo,
  endUserClientInfo,
  forgetPasswordLink,
  onforgetPasswordSuccess,
  onforgetPasswordError,
  onresetPasswordSuccess,
  onresetPasswordError,
}: AuthFormProps) => {
  const CredentialStorage = createCredentialStorage(stage, domain)
  const { requestHeaders } = createAuthInstance(domain)

  const forgetPwdApi = (data: ForgetPasswordFormData) => {
    return fetch(`${apiDomain}/v1/auth/password`, {
      method: 'POST',
      headers: requestHeaders({ lang, clientInfo, endUserClientInfo }),
      body: JSON.stringify({
        email: data.email,
        // this url is controlled by web FE project
        // if you wanna modify it, please ask FE engineer first
        redirect_url: forgetPasswordLink,
      }),
    }).then(async res => {
      const resData = await res.json()

      if (res.ok && resData.success) {
        onforgetPasswordSuccess(resData.message)
      } else {
        onforgetPasswordError({ res: resData, status: res.status })
      }
    })
  }

  const resetPwdApi = (data: ResetPasswordFormData) => {
    const searchParams = Object.fromEntries(new URLSearchParams(window.location.search).entries())
    const { uid, client, token } = searchParams

    if (!(uid && client && token)) {
      if (onresetPasswordError) {
        onresetPasswordError({ msg: 'missing_credential' })
      }
      return
    }

    const credentials: CredentialsType = {
      uid,
      client,
      'access-token': token,
    }

    return fetch(`${apiDomain}/v1/auth/password`, {
      method: 'PATCH',
      headers: requestHeaders({
        lang,
        clientInfo,
        endUserClientInfo,
        credentials,
      }),
      body: JSON.stringify({
        password: data.password,
        password_confirmation: data.confirmPassword,
      }),
    }).then(async res => {
      const resData = await res.json()

      if (res.ok && resData.success && onresetPasswordSuccess) {
        onresetPasswordSuccess(resData.message)
      } else {
        if (onresetPasswordError) onresetPasswordError({ res: resData, status: res.status })
      }
    })
  }

  const onAuthViaEmail = (type: AuthType) => (data: SignInFormData | SignUpFormData) => {
    if (typeof onRequest === 'function') onRequest()

    return fetch(`${apiDomain}/v1/auth${type === 'sign-up' ? '' : '/sign_in'}`, {
      method: 'POST',
      headers: requestHeaders({ lang, clientInfo, endUserClientInfo }),
      body: JSON.stringify(data),
    }).then(async res => {
      const resData = await res.json()

      if (res.ok) {
        CredentialStorage.update(res)
        if (typeof onSuccess === 'function') {
          onSuccess({ type, data: resData })
        }
        return
      }

      if (typeof onError === 'function') {
        onError({
          res: resData,
          status: res.status,
          type: 'email',
          errCode: resData.errors?.[0]?.code,
          msg: resData.errors?.[0]?.detail,
        })
        return
      }
    })
  }

  const onAuthViaSocialProvider = async (type: SocialProviderType, data: object) => {
    let apiPath
    switch (type) {
      case 'fb':
        apiPath = '/v1/facebooks'
        break
      case 'google':
        apiPath = '/v1/third_parties/google/signin_with_google_v2'
        break
      case 'apple':
        apiPath = '/v1/third_parties/apple/signin_with_apple'
        break
    }

    if (typeof onRequest === 'function') onRequest()

    return fetch(`${apiDomain}${apiPath}`, {
      method: 'POST',
      headers: requestHeaders({ lang, clientInfo, endUserClientInfo }),
      body: JSON.stringify({
        ...data,
        ...(deviceIdentifier && { device_identifier: deviceIdentifier }),
      }),
    }).then(async res => {
      const resData = await res.json()

      if (res.ok) {
        CredentialStorage.update(res)
        if (typeof onSuccess === 'function') {
          onSuccess({ type, data: resData })
        }
      } else {
        if (typeof onError === 'function') {
          onError({
            res: resData,
            status: res.status,
            type: 'email',
            errCode: resData.errors?.[0]?.code,
            msg: resData.errors?.[0]?.detail,
          })
        }
      }
    })
  }

  if (layout === 'forget-password') {
    return <ForgetPasswordLayout setLayout={setLayout} onSubmit={forgetPwdApi} />
  }

  const defaultLang = lang || 'zh-TW'

  if (layout === 'reset-password') {
    return (
      <LocaleProvider defaultLang={defaultLang}>
        <ResetPasswordForm onSubmit={resetPwdApi} />
      </LocaleProvider>
    )
  }

  const sigInOrSignUpI18nKey = layout === 'sign-in' ? 'signin' : 'signup'

  return (
    <LocaleProvider defaultLang={defaultLang}>
      <div className={twMerge('flex flex-col gap-y-5', className)}>
        {logo}

        <FormTitle layout={layout} setLayout={setLayout} />

        {layout === 'sign-up' && <PageDescription text={locales[defaultLang].signup.offer} />}

        <ProviderDivider>
          <div className='divider' />
          <Text variant='body_s_400'>{locales[defaultLang][sigInOrSignUpI18nKey].oneClick}</Text>
          <div className='divider' />
        </ProviderDivider>

        <GoogleOAuthProvider clientId={googleClientId ?? ''}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <SocialSignIn
              onSuccess={onAuthViaSocialProvider}
              onError={onError}
              fbAppId={fbAppId}
              fbRedirectUri={fbRedirectUri}
              fbState={fbState}
              googleClientId={googleClientId}
              appleClientId={appleClientId}
              appleRedirectUri={appleRedirectUri}
            />
          </div>
        </GoogleOAuthProvider>

        <ProviderDivider>
          <div className='divider' />
          <Text variant='body_s_400'>{locales[defaultLang][sigInOrSignUpI18nKey].useEmail}</Text>
          <div className='divider' />
        </ProviderDivider>

        {layout === 'sign-in' && (
          <SignInForm handleAuth={onAuthViaEmail('sign-in')} setLayout={setLayout} />
        )}

        {layout === 'sign-up' && (
          <SignUpForm handleAuth={onAuthViaEmail('sign-up')} termUrl={termUrl} />
        )}
      </div>
    </LocaleProvider>
  )
}

const ProviderDivider = styled.div`
  display: flex;
  align-items: center;
  justify-content: 'center';
  column-gap: 0.75rem;

  .divider {
    flex-grow: 1;
    height: 1px;
    border-top: 1px solid #e5e5e5;
  }
`
