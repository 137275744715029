import { twMerge } from 'tailwind-merge'
import { BrandPageBlockTypes, PreviewItemsFormTypes } from '../../types'
import { PreviewItemPropsTypes } from '../../use-brand-page-init'
import { BlockCard } from './block-card'
import { BlockCardRenderer } from './block-card-renderer'

type BlockItemProps = {
  previewItemProps: Pick<
    PreviewItemPropsTypes,
    | 'scrollableBlockItem'
    | 'handleSelectedBlock'
    | 'deleteBlockItem'
    | 'resetTargetBlockItem'
    | 'selectedBlockIndex'
    | 'isMobileScreen'
    | 'insertBlockItem'
    | 'previewItemsFields'
  >
  text: string
  type: BrandPageBlockTypes
  index: number
  inputField: PreviewItemsFormTypes
  className?: string
}

export const BlockItem = ({
  previewItemProps,
  inputField,
  text,
  type,
  index,
  className,
}: BlockItemProps) => {
  const {
    scrollableBlockItem,
    handleSelectedBlock,
    deleteBlockItem,
    resetTargetBlockItem,
    selectedBlockIndex,
    isMobileScreen,
    insertBlockItem,
  } = previewItemProps

  return (
    <div className={twMerge('relative [&_a]:pointer-events-none', className)}>
      <div id={`${scrollableBlockItem}-${index}`} className='absolute left-0 top-[-200px]'></div>
      <BlockCard
        onClick={() => {
          handleSelectedBlock(index, false)
        }}
        isSelected={index === selectedBlockIndex}
        actionElementProps={{
          text,
          type,
          index,
          resetTargetBlockItem,
          insertBlockItem,
          deleteBlockItem,
        }}
      >
        <BlockCardRenderer inputField={inputField} type={type} isMobileScreen={isMobileScreen} />
      </BlockCard>
    </div>
  )
}
