import type { GapBlockType } from '../types'

export const createEmptyGapBlock = (): GapBlockType => ({
  id: '',
  type: 'gap',
  attributes: {
    custom: {
      data: { height: 16 },
    },
  },
})
