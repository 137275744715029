import themeVar from '../../theme/themeVar'
import { Text } from '../text'
import { Icon } from '../icon'

type Props = {
  text: string
}

const PageDescription = (props: Props) => {
  const { text } = props
  return (
    <div className='bg-primary-100 flex gap-x-1 rounded-lg px-3 py-2'>
      <Icon
        type='featured_seasonal_and_gifts'
        color='primary-700'
        style={{ marginTop: '0.25rem' }}
      />
      <Text variant='body_s_400' color='primary-700'>
        {text}
      </Text>
    </div>
  )
}

export default PageDescription
