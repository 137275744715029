import type { HtmlBlockType } from '../types'

export const createEmptyHtmlBlockBlock = (): HtmlBlockType => ({
  id: '',
  type: 'html_block',
  attributes: {
    startsAt: '',
    endsAt: '',
    custom: {
      data: { html: '' },
    },
  },
})
