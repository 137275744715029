import { AxiosResponse } from 'axios'

import {
  GetPresignedImageUrlResponse,
  GetPresignedUrlResponse,
  ToVideoConvertResponse,
} from './types'
import { UploaderModeTypes, GetAssetUrlsPayloadTypes } from './types'
import { apiCaller } from '@wordup/lib-portal/features'

const manageMaterialAssetsUrlService = {
  getPresignedUrl: ({
    materialId,
    fileName,
    mode,
    userId,
    ownerType,
    ownerId,
    namespace,
  }: {
    materialId: string
    fileName: string
    mode?: UploaderModeTypes
    userId?: string
    ownerId?: string
    ownerType?: 'user' | 'org'
    namespace?: string
  }) =>
    apiCaller<GetPresignedImageUrlResponse>({
      method: 'post',
      url: '/manage/asset_urls',
      data: {
        file_name: fileName,
        mode: mode,
        material_id: mode === 'material' ? materialId : undefined,
        product_id: mode === 'product' ? materialId : undefined,
        user_id: userId,
        owner_type: ownerType,
        owner_id: ownerId,
        namespace,
      },
    }),
  getAsGuestPresignedUrl: ({
    mode,
    ownerType,
    fileName,
  }: {
    mode: 'partner_entity'
    ownerType: 'guest'
    fileName: string
  }) =>
    apiCaller<GetPresignedImageUrlResponse>({
      method: 'post',
      url: '/manage/asset_urls/as_guest',
      data: {
        file_name: fileName,
        mode: mode,
        owner_type: ownerType,
      },
    }),
  getVideoPierPresignedUrl: (materialId: string, compId: string, fileName: string) =>
    apiCaller<GetPresignedImageUrlResponse>({
      method: 'post',
      url: `/manage/materials/${materialId}/images/video_pier`,
      data: {
        component_id: compId,
        file_name: fileName,
      },
    }),
  toVideoConvert: (materialId: string, compId: string, inputKey: string) =>
    apiCaller<ToVideoConvertResponse>({
      method: 'post',
      url: `/manage/materials/${materialId}/images/start_video_convert`,
      data: {
        component_id: compId,
        inputKey,
      },
    }),
  getAssetUrl: ({
    materialId,
    fileName,
    mode,
    userId,
    filePath,
    downloadFilename,
  }: GetAssetUrlsPayloadTypes) =>
    apiCaller<GetPresignedUrlResponse>({
      method: 'post',
      url: '/asset_urls',
      data: {
        file_name: fileName,
        mode: mode,
        material_id: materialId,
        user_id: userId,
        file_path: filePath,
        download_filename: downloadFilename,
      },
    }),
}

export default manageMaterialAssetsUrlService
