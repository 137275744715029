import dynamic from 'next/dynamic'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { IconButton } from '@mui/material'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { v4 as uuidv4 } from 'uuid'

import { AssetUploader } from './asset-urls/uploader'
import { UploaderModeTypes } from '@/apis/manage-material/asset-urls/types'
import { toast } from '@libs-components/components/toast'

import { ErrorNotifier, getUrlFilename } from '../../utils'
import { getVideoThumbnailFromFile } from '../../utils'
import { fileMeetsRequirements, getAcceptedFormats, getDefaultMaxSizeInMb } from './utils'

const AudioPlayer = dynamic(() => import('../audio-player'), { ssr: false })
const WuModal = dynamic(() => import('../modal'), { ssr: false })
const WuProgressBar = dynamic(() => import('../progress-bar'), { ssr: false })
const FileIcon = dynamic(() => import('./file-icon'), { ssr: false })

export const acceptedAudioFormats = ['mp3'] as const
export const acceptedVideoFormats = ['mp4'] as const
export const acceptedImageFormats = ['png', 'jpg', 'gif'] as const
export const acceptedDocumentFormats = ['pdf', 'csv'] as const
export const acceptedSubtitlesFormats = ['vtt'] as const
const allAcceptedFilesFormats = [
  ...acceptedAudioFormats,
  ...acceptedVideoFormats,
  ...acceptedImageFormats,
  ...acceptedSubtitlesFormats,
  ...acceptedDocumentFormats,
]

export type AllAcceptedFileTypes = (typeof allAcceptedFilesFormats)[number]

export const AllFileTypes = {
  video: 'video',
  audio: 'audio',
  image: 'image',
  document: 'document',
  subtitles: 'subtitles',
} as const

interface VideoTypeProps extends BasicProps {
  type: typeof AllFileTypes.video
  formats: (typeof acceptedVideoFormats)[number][]
  componentId: string
  materialId: string
  allowUploadThumbnail?: boolean
  thumbnailWidth?: number
  thumbnailHeight?: number
  thumbnailTimestampSeconds?: number
  onChange?: ({
    displayUrl,
    assetUrl,
    videoThumbnailUrl,
  }: {
    displayUrl: string
    assetUrl: string
    videoThumbnailUrl?: string
  }) => void
}
interface AudioTypeProps extends BasicProps {
  type: typeof AllFileTypes.audio
  formats: (typeof acceptedAudioFormats)[number][]
  materialId: string
  onChange?: ({ url }: { url: string; file: File | null }) => void
  namespace?: string
}
interface ImageTypeProps extends BasicProps {
  type: typeof AllFileTypes.image
  formats: (typeof acceptedImageFormats)[number][]
  materialId: string
  onChange?: ({ url }: { url: string; file: File | null }) => void
  namespace?: string
}
interface DocumentTypeProps extends BasicProps {
  type: typeof AllFileTypes.document
  formats: (typeof acceptedDocumentFormats)[number][]
  onChange?: ({ file }: { file: File | null }) => void
}

interface SubtitlesTypeProps extends BasicProps {
  type: typeof AllFileTypes.subtitles
  formats: (typeof acceptedSubtitlesFormats)[number][]
  onChange?: ({ file }: { file: File | null }) => void
}

export type MediaFileUploaderProps =
  | VideoTypeProps
  | AudioTypeProps
  | ImageTypeProps
  | DocumentTypeProps
  | SubtitlesTypeProps

interface CustomUploadResType {
  displayUrl: string
  assetUrl: string
  thumbnailUrl?: string
}

export type CustomUploadHandlerType = ({
  file,
  setUploadProgress,
  userId,
}: {
  file: File
  setUploadProgress: (value: React.SetStateAction<number>) => void
  userId: string | undefined
}) => Promise<CustomUploadResType | undefined>

export interface BasicProps {
  maxSizeInMb?: number
  initUrl?: string
  customUploadHandler?: CustomUploadHandlerType
  error?: boolean
  extraInfo?: string
  mode: UploaderModeTypes
  userId?: string
  disabledRemoveFile?: boolean
  disabled?: boolean
}

/**
 *
 * @param  props.maxSizeInMb - Max file size in MB. Defaults to: Audio - 3MB , Video - 1000MB, Image - 3MB, Document - 3MB, Subtitles - 60MB
 * @param props.type - Type of file
 * @param  props.formats - Array of accepted file formats
 * @param  props.initUrl - Initial file url
 * @param  props.onChange - Callback function that will be called when file is changed
 * @param  props.materialId - Material ID, required for most type uploading
 * @param  props.componentId - Component ID, required for video type uploading
 * @param props.customUploadHandler - Custom upload handler, if you want to customize the api call, uploading to a different location, or mocking the upload process. Receives the file and a function to set the upload progress. Should return a promise with the displayUrl and assetUr.
 */

const MediaFileUploader = (props: MediaFileUploaderProps) => {
  const {
    type,
    formats,
    maxSizeInMb = getDefaultMaxSizeInMb(type),
    error,
    initUrl = '',
    extraInfo,
    mode,
    userId,
    customUploadHandler,
    disabledRemoveFile = false,
    disabled = false,
  } = props
  const { t } = useTranslation()
  const inputId = uuidv4()
  const uploader = AssetUploader()
  const inputRef = useRef<HTMLInputElement>(null)

  const [dragActive, setDragActive] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [files, setFiles] = useState<File[]>([])
  const [fileUrl, setFileUrl] = useState(initUrl)
  const [openPreviewModal, setOpenPreviewModal] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)

  const urlOrObjectUrl = useMemo(() => {
    if (fileUrl) {
      return fileUrl
    }
    if (files.length > 0) {
      return URL.createObjectURL(files[0])
    }
    return ''
  }, [files, fileUrl])

  const fileName = useMemo(() => {
    if (files.length > 0) {
      return files[0].name
    }
    if (fileUrl) {
      return getUrlFilename(fileUrl)
    }
    return ''
  }, [files, fileUrl])

  useEffect(() => {
    if (fileUrl === initUrl) return

    setFileUrl(initUrl)
  }, [initUrl, fileUrl])

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    if (isUploading) return
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0]
      if (!(await fileMeetsRequirements({ file, type, formats, maxSize: maxSizeInMb }))) return

      uploadFile(file)
    }
  }

  const handleInputFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isUploading) return
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0]
      if (!(await fileMeetsRequirements({ file, type, formats, maxSize: maxSizeInMb }))) return
      uploadFile(file)
    }
  }

  const uploadFile = async (file: File) => {
    try {
      setIsUploading(true)
      let res
      let displayUrl = ''
      let assetUrl = ''
      let thumbnailUrl = ''
      if (customUploadHandler !== undefined) {
        const customUploadRes = await customUploadHandler({
          file,
          setUploadProgress,
          userId: userId,
        })
        if (!customUploadRes) throw new Error('custom upload error')
        res = true
        displayUrl = customUploadRes.displayUrl
        assetUrl = customUploadRes.assetUrl
        if (customUploadRes.thumbnailUrl) {
          thumbnailUrl = customUploadRes.thumbnailUrl
        }
      } else {
        switch (type) {
          case 'video': {
            if (props.allowUploadThumbnail) {
              const thumbnail = await getVideoThumbnailFromFile({
                file,
                thumbnailWidth: props.thumbnailWidth ?? 320,
                thumbnailHeight: props.thumbnailHeight ?? 180,
                thumbnailTimestampSeconds: props.thumbnailTimestampSeconds,
              })

              thumbnailUrl = await uploader.upload({
                file: thumbnail,
                isPublic: userId && mode === 'user' ? false : true,
                materialId: props.materialId,
                mode: mode,
                userId: userId,
              })
            }

            res = await uploader.uploadVideo({
              file,
              materialId: props.materialId,
              componentId: props.componentId,
              setProgress: setUploadProgress,
            })

            displayUrl = res.displayUrl
            assetUrl = res.assetUrl
            break
          }
          case 'audio':
          case 'image': {
            res = await uploader.upload({
              file,
              isPublic: userId && mode === 'user' ? false : true,
              materialId: props.materialId,
              mode: mode,
              setProgress: setUploadProgress,
              userId: userId,
              namespace: props.namespace,
            })
            assetUrl = res
            break
          }
          case 'subtitles':
          case 'document': {
            displayUrl = URL.createObjectURL(file)
            res = true
            break
          }
          default:
            return
        }

        if (!res) throw new Error('upload error')
      }
      setFiles([file])
      setFileUrl(displayUrl)
      handleOnChange({ displayUrl, assetUrl, file, videoThumbnailUrl: thumbnailUrl })
    } catch (err) {
      if (err instanceof Error) {
        ErrorNotifier.notify({
          err,
          context: {
            key: 'MediaFileUploader upload error',
            props,
            uploadProgress,
          },
        })
      }
      toast.error({ message: t('uploader.error.upload_failed') })
    } finally {
      setUploadProgress(0)
      setIsUploading(false)
    }
  }

  const typeText = useMemo(() => {
    switch (type) {
      case 'audio': {
        return t('uploader.add_audio')
      }
      case 'video': {
        return t('uploader.add_video')
      }

      case 'document': {
        return t('uploader.add_file')
      }

      case 'subtitles': {
        return t('uploader.add_subtitles')
      }

      case 'image': {
        return t('uploader.add_image')
      }

      default: {
        return ''
      }
    }
  }, [type, t])

  const filePreviewable = useMemo(() => {
    switch (type) {
      case 'image':
      case 'audio': {
        return true
      }

      default: {
        return false
      }
    }
  }, [type])

  const PreviewIcon = useMemo(() => {
    switch (type) {
      case 'image':
        return VisibilityOutlinedIcon

      default:
        return PlayArrowIcon
    }
  }, [type])

  const removeFile = () => {
    setFiles([])
    setFileUrl('')
    resetInputValue()
    handleOnChange({ displayUrl: '', assetUrl: '', file: null })
  }

  const resetInputValue = () => {
    if (!inputRef.current) return
    inputRef.current.value = ''
  }

  const handleOnChange = ({
    displayUrl,
    assetUrl,
    file,
    videoThumbnailUrl,
  }: {
    displayUrl: string
    assetUrl: string
    file: File | null
    videoThumbnailUrl?: string
  }) => {
    switch (type) {
      case 'video': {
        if (props.onChange !== undefined) {
          props.onChange({
            displayUrl: displayUrl,
            assetUrl: assetUrl,
            videoThumbnailUrl,
          })
        }
        break
      }
      case 'audio':
      case 'image': {
        if (props.onChange !== undefined) {
          props.onChange({ url: assetUrl, file })
        }
        break
      }

      case 'subtitles':
      case 'document': {
        if (props.onChange !== undefined) {
          props.onChange({ file })
        }
        break
      }

      default:
        break
    }
  }

  return (
    <>
      <div
        className={`flex min-h-[6.75rem] w-full items-center justify-center overflow-hidden rounded-lg p-3 transition-colors duration-300 ${
          dragActive ? 'bg-grayscale-300' : 'bg-grayscale-100'
        } ${error ? 'border-peach-500 border' : ''}`}
        onSubmit={e => e.preventDefault()}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        <input
          className='hidden'
          id={inputId}
          ref={inputRef}
          onClick={() => {}}
          onChange={handleInputFileChange}
          accept={getAcceptedFormats(formats)}
          type='file'
          disabled={disabled}
          multiple={false}
        />

        {isUploading ? (
          <div className='flex h-full w-full flex-col items-center justify-center gap-y-2'>
            <div className=' text-grayscale-800 text-sm'>{t('uploader.uploading')}</div>
            <WuProgressBar value={uploadProgress} showProgressNumber fixColor />
          </div>
        ) : fileUrl || files.length > 0 ? (
          <div className='flex h-full w-full items-center justify-between'>
            <div className='flex w-full items-center gap-x-1 truncate'>
              <FileIcon type={formats[0]} className='text-grayscale-800 h-6 w-6 p-1' />
              <div className='truncate text-sm font-medium  underline'>{fileName}</div>
            </div>

            <div className='flex items-center gap-x-2'>
              {filePreviewable && (
                <IconButton
                  onClick={() => {
                    setOpenPreviewModal(true)
                  }}
                >
                  <PreviewIcon className='text-grayscale-1000 text-base' />
                </IconButton>
              )}

              {disabledRemoveFile ? null : (
                <IconButton onClick={removeFile}>
                  <DeleteOutlineOutlinedIcon className='text-peach-500 text-base' />
                </IconButton>
              )}
            </div>
          </div>
        ) : (
          <label
            htmlFor={inputId}
            className='flex h-full w-full cursor-pointer select-none flex-col items-center justify-center gap-y-1 rounded-lg'
          >
            <div className='flex items-center gap-x-1'>
              <FileIcon
                type={formats[0]}
                className={`h-6 w-6 p-1 ${
                  dragActive ? 'text-grayscale-1000' : 'text-grayscale-800'
                }`}
              />

              <div
                className={` text-sm font-medium ${
                  dragActive ? 'text-grayscale-1000' : 'text-grayscale-800'
                }`}
              >
                {typeText}
              </div>
            </div>
            <div
              className={` text-center text-xs  font-medium transition-colors ${
                dragActive ? 'text-grayscale-600' : 'text-grayscale-400'
              }`}
            >
              {t('uploader.alphanumeric_name_accepted')}・{t('uploader.size_limit')} {maxSizeInMb}{' '}
              MB・
              {t('uploader.format')} {formats.map(e => e.toUpperCase()).join(', ')}
              {extraInfo && `・${extraInfo}`}
            </div>
          </label>
        )}
      </div>

      {filePreviewable && urlOrObjectUrl && (
        <WuModal
          open={openPreviewModal}
          onClose={() => {
            setOpenPreviewModal(false)
          }}
        >
          <div className='flex items-center justify-center'>
            {type === 'image' && (
              <img src={urlOrObjectUrl} alt='uploaded file' className='max-w-xs' />
            )}

            {type === 'audio' && <AudioPlayer src={urlOrObjectUrl} controllable />}
          </div>
        </WuModal>
      )}
    </>
  )
}

export default MediaFileUploader
