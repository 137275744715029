import { PropsWithChildren } from 'react'
import { twMerge } from 'tailwind-merge'
import { Text } from '@libs-components/components/text'
import { BrandPageBlockTypes } from '../../types'
import { Icon } from '@libs-components/components/icon'
import { WuTooltip } from '../../../../components'
import { useTranslation } from 'next-i18next'

type BlockCardProps = {
  actionElementProps: ActionElementProps
  onClick: () => void
  isSelected: boolean
}

export const BlockCard = ({
  onClick,
  isSelected,
  children,
  actionElementProps,
}: PropsWithChildren<BlockCardProps>) => {
  return (
    <div
      className='group relative flex h-full w-full cursor-pointer flex-col justify-start'
      onClick={onClick}
    >
      <div
        className={twMerge(
          'bg-primary-700 absolute top-[-2.5rem] z-50 flex gap-4 p-2 opacity-0 transition-all group-hover:opacity-100',
          isSelected ? 'opacity-100' : '',
        )}
      >
        <ActionElement {...actionElementProps} />
      </div>
      <div
        className={twMerge(
          'group-hover:border-primary-700 h-auto w-full transition-all group-hover:border-2',
          isSelected ? 'border-primary-700 border-2' : '',
        )}
      >
        {children}
      </div>
    </div>
  )
}

type ActionElementProps = {
  text: string
  type: BrandPageBlockTypes
  index: number
  resetTargetBlockItem: (index: number, type: BrandPageBlockTypes) => void
  insertBlockItem: (index: number, type: BrandPageBlockTypes) => void
  deleteBlockItem: (index: number) => void
}

const ActionElement = ({
  text,
  type,
  index,
  resetTargetBlockItem,
  insertBlockItem,
  deleteBlockItem,
}: ActionElementProps) => {
  const { t } = useTranslation()
  return (
    <>
      <Text variant='body_m_400' color='grayscale-000'>
        {text}
      </Text>

      <WuTooltip
        type='hover'
        title={t('common.undo')}
        onClick={e => {
          e.stopPropagation()
          resetTargetBlockItem(index, type)
        }}
        icon={
          <div className='cursor-pointer'>
            <Icon type='undo' color='grayscale-000' />
          </div>
        }
      />

      {type === 'brandInfo' ? null : (
        <>
          <WuTooltip
            type='hover'
            title={t('common.copy')}
            onClick={e => {
              e.stopPropagation()
              insertBlockItem(index + 1, type)
            }}
            icon={
              <div className='cursor-pointer'>
                <Icon type='content_copy' color='grayscale-000' />
              </div>
            }
          />
          <WuTooltip
            type='hover'
            title={t('common.delete')}
            onClick={e => {
              e.stopPropagation()
              deleteBlockItem(index)
            }}
            icon={
              <div className='cursor-pointer'>
                <Icon type='delete' color='grayscale-000' />
              </div>
            }
          />
        </>
      )}
    </>
  )
}
