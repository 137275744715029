import { sendGtagEvent } from './utils'

type SendUseAiGeneratedSentenceParams = {
  usedCount: number
  limitCount: number
  userId: string
}

export const sendUseAiGeneratedSentence = ({
  usedCount,
  limitCount,
  userId,
}: SendUseAiGeneratedSentenceParams) => {
  sendGtagEvent({
    name: 'use_ai_generated_sentence',
    params: { used_count: usedCount, limit_count: limitCount, user_id: userId },
  })
}
