import { twMerge } from 'tailwind-merge'
import { Icon } from '@libs-components/components/icon'

const PlayButton = ({ playerBtnClassName }: { playerBtnClassName?: string }) => {
  return (
    <button
      type='button'
      className={twMerge(
        'absolute bottom-0 left-0 right-0 top-0 flex h-auto max-h-[60px] w-full max-w-[100px] items-center justify-center rounded-md bg-black bg-opacity-70 text-white hover:bg-opacity-90',
        playerBtnClassName,
      )}
    >
      <Icon type='play' />
    </button>
  )
}

export default PlayButton
