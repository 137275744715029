import { useBrandPageInit } from './use-brand-page-init'
import { BrandPageBlockTypes } from './types'
import { BrandLayout } from './layout'
import { EditorToolPanel } from './components'
import { FormProvider } from 'react-hook-form'
import PreviewBlock from './preview-block'

import DragAndDropBlock from './drag-and-drop-block'
import EditFormBlock from './edit-form-block'

type BrandPageProps = { orgId: string }

export const BrandPage = ({ orgId }: BrandPageProps) => {
  const { methods, blockItemProps, pageInfoProps, previewItemProps, dragAndDropBlockProps } =
    useBrandPageInit({ orgId })

  const {
    basicEditorButtons,
    advancedEditorButtons,
    selectedBlockIndex,
    inputItemsFields,
    editStatus,
    leaveEditBlock,
  } = blockItemProps

  return (
    <FormProvider {...methods}>
      <BrandLayout pageInfoProps={pageInfoProps}>
        <div className='relative grid h-full w-full grid-cols-12'>
          <div className='bg-grayscale-000 col-span-2 h-full overflow-auto p-6'>
            <DragAndDropBlock dragAndDropBlockProps={dragAndDropBlockProps} />
          </div>

          <div className='bg-grayscale-50 col-span-7 flex  items-center justify-center overflow-hidden px-10 pb-5 pt-2'>
            <PreviewBlock previewItemProps={previewItemProps} />
          </div>
          <div className='bg-grayscale-000 col-span-3 flex h-full flex-col gap-4 overflow-hidden'>
            {editStatus === 'create' || editStatus === 'edit'
              ? inputItemsFields.map((inputField, index) => {
                  const [[type]] = Object.entries(inputField) as [BrandPageBlockTypes, any][]

                  return (
                    <EditFormBlock
                      key={inputField.id || `fallback-key-${index}`}
                      index={index}
                      type={type}
                      handlePrevious={leaveEditBlock}
                      selectedBlockIndex={selectedBlockIndex}
                      orgId={orgId}
                    />
                  )
                })
              : null}

            {editStatus === 'default' ? (
              <EditorToolPanel
                basicButtons={basicEditorButtons}
                advancedButtons={advancedEditorButtons}
              />
            ) : null}
          </div>
        </div>
      </BrandLayout>
    </FormProvider>
  )
}
