export type FileSizeUnitTypes =
  | 'bytes'
  | 'KB'
  | 'MB'
  | 'GB'
  | 'TB'
  | 'PB'
  | 'EB'
  | 'ZB'
  | 'YB'
export const FILE_SIZE_UNITS: FileSizeUnitTypes[] = [
  'bytes',
  'KB',
  'MB',
  'GB',
  'TB',
  'PB',
  'EB',
  'ZB',
  'YB',
]
