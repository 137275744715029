import dynamic from 'next/dynamic'
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { Skeleton } from '@mui/material'
import { Editor, IAllProps } from '@tinymce/tinymce-react'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { renderToString } from 'react-dom/server'
import { useTranslation } from 'next-i18next'
import { twMerge } from 'tailwind-merge'
import { Editor as TinyMCEEditor } from 'tinymce'
import { v4 as uuidv4 } from 'uuid'
import { getBytesFromFileSize } from '@libs-components/utils/file'

import { AssetUploader } from '../media-file-uploader/asset-urls/uploader'
import { UploaderModeTypes } from '@/apis/manage-material/asset-urls/types'
import { Controller, useForm, useWatch } from 'react-hook-form'
import WuButton from '../button'
import { Textfield } from '../wu-inputs'
import CustomModalFooterButtons from './modal-footer-buttons'
import { AddTimeStampIcon, UploadAudioIcon } from './toolbar-icons'
import { validTimestampRegex } from '../../utils'
import { customCss } from './rich-editor-styles'
import { useConst } from '@wordup/utils'

const MediaFileUploaderWithInfo = dynamic(
  () =>
    import('../media-file-uploader').then(
      ({ MediaFileUploaderWithInfo }) => MediaFileUploaderWithInfo,
    ),
  { ssr: false },
)
const WuModal = dynamic(() => import('../modal'), { ssr: false })

const TEXT_ONLY_EDITOR =
  'formatselect | forecolor backcolor alignleft aligncenter alignright | bold italic underline strikethrough | link |'

const ADVANCED_EDITOR =
  'removeformat | fontselect | fontsizeselect | formatselect | numlist bullist | table'

const EditorModes = {
  BASIC: 'basic',
  NORMAL: 'normal',
  ADVANCED: 'advanced',
  TEXT_ONLY: 'textOnly',
} as const

type EditorModeTypes = (typeof EditorModes)[keyof typeof EditorModes]
type ToolbarModalTypes = 'upload-audio' | 'insert-timestamp'

interface FormData {
  inputValues: {
    timestamp: string
    customUploadAudioUrl: string
  }
}

interface PropTypes extends IAllProps {
  materialId?: string
  isImgPublic?: boolean
  editorHeight?: number
  editorMinHeight?: number
  isFloatingHeight?: boolean
  handleEditorChange: (val: string) => void
  mode: UploaderModeTypes
  content?: string
  placeholder?: string
  showPreviewButton?: boolean
  showToggleModeButton?: boolean
  contentStyle?: string
  containerClassName?: string
  showSourceCodeButton?: boolean
  textOnlyEditor?: boolean
  showInsertTimestampButton?: boolean
  maxSize?: Parameters<typeof getBytesFromFileSize>[0]
}

export const RichTextEditor: React.FC<PropTypes> = ({
  materialId = '',
  handleEditorChange,
  content,
  placeholder = '',
  mode,
  isImgPublic = true,
  editorHeight,
  editorMinHeight = 200,
  isFloatingHeight = false,
  showPreviewButton = true,
  contentStyle = '',
  containerClassName,
  showToggleModeButton = true,
  showSourceCodeButton = false,
  textOnlyEditor,
  showInsertTimestampButton,
  maxSize,
  ...otherProps
}) => {
  const [editorMode, setEditorMode] = useState<EditorModeTypes>(EditorModes.BASIC)
  const { t } = useTranslation()
  const [editorIsLoaded, setEditorIsLoaded] = useState(false)
  const editorRef = useRef<null | TinyMCEEditor>(null)
  const [clientViewHeight, setClientViewHeight] = useState<number>(editorHeight ?? 500)
  const [modalMode, setModalMode] = useState<ToolbarModalTypes>('upload-audio')
  const { control, trigger, reset } = useForm<FormData>({
    defaultValues: {
      inputValues: {
        timestamp: '',
        customUploadAudioUrl: '',
      },
    },
  })

  const formInputValues = useWatch({
    control: control,
    name: 'inputValues',
  })

  const [modalIsOpen, setModalIsOpen] = useState(false)

  const editorId = useMemo(() => uuidv4(), [])

  const togglePreview = () => {
    if (editorRef.current) {
      editorRef.current.editorCommands.execCommand('mcepreview')
    }
  }

  const setToolbarType = (type: EditorModeTypes) => {
    const thisEditor = document.getElementById(editorId)
    if (!thisEditor) return
    const el = thisEditor.querySelectorAll('.tox-toolbar')
    if (!(el[0] && el[1])) return
    switch (type) {
      case 'textOnly':
        el[0].setAttribute('style', 'display: none')
        el[1].setAttribute('style', 'display: none')
        el[2].setAttribute('style', 'display: auto')
        break

      case 'normal': {
        el[0].setAttribute('style', 'display: auto')
        el[1].setAttribute('style', 'display: none')
        break
      }
      case 'advanced': {
        el[1].setAttribute('style', 'display: auto')
        break
      }
      default:
        el[0].setAttribute('style', 'display: none')
        el[1].setAttribute('style', 'display: none')
        el[2].setAttribute('style', 'display: none')
        break
    }
  }
  const getScreenHeight = () => {
    if (window.innerWidth) {
      const WY: number = document.body.clientHeight
      setClientViewHeight(Math.floor(WY * 0.67))
    }
  }

  useEffect(() => {
    setToolbarType(editorMode)
  }, [editorMode])

  useEffect(() => {
    updateContentStyle()
    getScreenHeight()
  }, [contentStyle, clientViewHeight])

  const updateContentStyle = () => {
    // Ref: https://stackoverflow.com/questions/54672782/tinymce-update-content-style
    if (editorRef.current) {
      const eles = editorRef.current.iframeElement?.contentDocument?.getElementsByTagName('style')
      if (eles?.[0]) {
        eles[0].innerHTML = `${customCss} ${contentStyle} `
      }
    }
  }

  const SIMPLE_EDITOR = useConst(() => {
    return `undo redo | forecolor backcolor | bold italic underline strikethrough blockquote | alignleft aligncenter alignright | image media upload-audio ${
      showInsertTimestampButton ? 'insert-timestamp' : ''
    } link ${showSourceCodeButton ? 'code' : ''} toggleModeButton`
  })

  const isBasicMode = editorMode === 'basic'

  const openModal = useCallback(
    (modalType: ToolbarModalTypes) => {
      setModalIsOpen(true)
      setModalMode(modalType)
    },
    [setModalIsOpen, setModalMode],
  )

  const closeModal = useCallback(() => {
    setModalIsOpen(false)
    setModalMode('upload-audio')
    reset({
      inputValues: {
        timestamp: '',
        customUploadAudioUrl: '',
      },
    })
  }, [setModalIsOpen, setModalMode, reset])

  const insertUploadedAudio = () => {
    if (!formInputValues.customUploadAudioUrl || !editorRef.current) return

    const html = `<audio src="${formInputValues.customUploadAudioUrl}" controls></audio>`

    editorRef.current?.insertContent(html)
    closeModal()
  }

  const insertTimestamp = async () => {
    const result = await trigger('inputValues.timestamp')

    if (!formInputValues.timestamp || !editorRef.current || !result) return

    editorRef.current?.insertContent(formInputValues.timestamp)
    closeModal()
  }

  const renderModalContent = useCallback(() => {
    switch (modalMode) {
      case 'upload-audio': {
        return (
          <div className='flex flex-col gap-4'>
            <Controller
              control={control}
              name='inputValues.customUploadAudioUrl'
              rules={{
                required: {
                  value: true,
                  message: t('manage-product.article_link.required_field_empty'),
                },
              }}
              render={({ field: { onChange } }) => {
                return (
                  <MediaFileUploaderWithInfo
                    label={t('material.audio.upload')}
                    mode='material'
                    type='audio'
                    formats={['mp3']}
                    maxSizeInMb={60}
                    materialId={materialId}
                    initUrl={''}
                    onChange={({ url }) => {
                      onChange(url)
                    }}
                  />
                )
              }}
            />

            <CustomModalFooterButtons
              cancelButtonText={t('common.cancel')}
              submitButtonText={t('common.embed')}
              onSubmit={insertUploadedAudio}
              onCancel={closeModal}
              submitDisabled={!formInputValues.customUploadAudioUrl}
            />
          </div>
        )
      }

      case 'insert-timestamp': {
        return (
          <div className='flex flex-col gap-4'>
            <Controller
              control={control}
              name='inputValues.timestamp'
              rules={{
                maxLength: 8,
                minLength: 5,
                pattern: validTimestampRegex,
              }}
              render={({ field, fieldState }) => {
                return (
                  <Textfield
                    label={t('material.video.insert_timestamp')}
                    {...field}
                    placeholder={`${t('material.video.enter_timestamp')} (${t(
                      'material.video.timestamp_example',
                    )})`}
                    helperText={
                      fieldState.error
                        ? `${t('material.video.enter_valid_timestamp')} (${t(
                            'material.video.timestamp_example',
                          )})`
                        : ''
                    }
                    error={Boolean(fieldState.error)}
                  />
                )
              }}
            />

            <CustomModalFooterButtons
              cancelButtonText={t('common.cancel')}
              submitButtonText={t('common.embed')}
              onSubmit={insertTimestamp}
              onCancel={closeModal}
              submitDisabled={!formInputValues.timestamp}
            />
          </div>
        )
      }

      default: {
        return <div />
      }
    }
  }, [modalMode, formInputValues, closeModal, insertUploadedAudio, insertTimestamp])

  return (
    <>
      {!editorIsLoaded && (
        <Skeleton className='w-full rounded-md' height={editorHeight} variant='rectangular' />
      )}

      <div
        id={editorId}
        className={twMerge(
          `animate-fade-in-300 h-full rounded-md border ${editorIsLoaded ? 'relative' : 'hidden'}`,
          containerClassName,
        )}
      >
        <Editor
          apiKey={process.env.NEXT_PUBLIC_TINYMCE_API_KEY}
          value={content}
          textareaName='editable-introduction'
          onInit={(_evt, editor) => {
            editorRef.current = editor
            updateContentStyle()
            setEditorIsLoaded(true)
          }}
          init={{
            skin: 'snow',
            icons: 'thin',
            content_style: contentStyle,
            init_instance_callback: () => {
              if (textOnlyEditor) {
                return setToolbarType('textOnly')
              }
              setToolbarType && setToolbarType('basic')
            },
            resize: 'both',
            toolbar_mode: 'sliding',
            height: editorHeight || '100%',
            min_height: editorMinHeight,
            max_height: clientViewHeight,
            menubar: false,
            placeholder,
            toolbar1: SIMPLE_EDITOR,
            toolbar2: ADVANCED_EDITOR,
            toolbar3: TEXT_ONLY_EDITOR,
            plugins: [
              'advlist preview autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              `insertdatetime media mediaembed table advtable paste code help wordcount template ${
                isFloatingHeight ? 'autoresize' : ''
              }`,
            ],
            block_formats: 'Normal Text=div; Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3;',
            fontsize_formats: '10px 12px 14px 16px 18px 20px 22px 24px 36px 48px',
            font_formats:
              'Arial=arial;Noto Sans Traditional Chinese=Noto Sans TC; Noto Serif Traditional Chinese=Noto Serif TC;Georgia=georgia',
            paste_data_images: true,
            draggable_modal: false,
            statusbar: false,
            file_picker_types: 'image',
            image_title: false,
            image_description: true,
            media_live_embeds: true,
            object_resizing: false,
            images_upload_handler: async (blobInfo, success, failure) => {
              try {
                const fileBlob = blobInfo.blob()
                const file = new File([fileBlob], blobInfo.filename(), {
                  type: fileBlob.type,
                })

                if (maxSize) {
                  const maxBytes = getBytesFromFileSize(maxSize)
                  if (file.size > maxBytes) {
                    return failure(
                      'File size is too large. Maximum file size is ' + maxSize.join(' '),
                      { remove: true },
                    )
                  }
                }

                const getUrl = await AssetUploader().upload({
                  file,
                  materialId,
                  isPublic: isImgPublic,
                  mode: mode,
                })
                if (!getUrl) return failure(`Image upload error.`, { remove: true })
                success(getUrl)
              } catch (err) {
                if (err instanceof Error) {
                  failure(err.message, { remove: true })
                }
              }
            },
            image_class_list: [
              { title: 'Full Width', value: 'img-responsive' },
              { title: 'none', value: '' },
            ],
            setup: editor => {
              editor.ui.registry.addToggleButton('toggleModeButton', {
                icon: 'more-drawer',
                active: false,
                onAction: api => {
                  const isActive = api.isActive()
                  setEditorMode(isActive ? EditorModes.NORMAL : EditorModes.ADVANCED)
                  api.setActive(!isActive)
                },
              })
              editor.ui.registry.addButton('upload-audio', {
                icon: 'upload-audio',
                tooltip: 'Upload Audio',
                onAction: () => {
                  openModal('upload-audio')
                },
              })
              editor.ui.registry.addIcon('upload-audio', renderToString(<UploadAudioIcon />))
              editor.ui.registry.addButton('insert-timestamp', {
                icon: 'insert-timestamp',
                tooltip: 'Insert Timestamp',
                onAction: () => {
                  openModal('insert-timestamp')
                },
              })
              editor.ui.registry.addIcon('insert-timestamp', renderToString(<AddTimeStampIcon />))
            },
          }}
          // Ref: https://github.com/instructure-react/react-tinymce/issues/76#issuecomment-570202694
          onEditorChange={handleEditorChange}
          {...otherProps}
        />

        <WuModal open={modalIsOpen} onClose={closeModal}>
          {renderModalContent()}
        </WuModal>

        {showToggleModeButton && (isBasicMode || showPreviewButton) ? (
          <WuButton
            color='info'
            variant='outlined'
            className='text-grayscale-800 bg-grayscale-000 hover:bg-grayscale-100 absolute bottom-6 right-6 z-10 rounded-full'
            onClick={() => {
              if (isBasicMode) {
                setEditorMode(EditorModes.NORMAL)
              } else {
                togglePreview()
              }
            }}
            startIcon={
              isBasicMode ? (
                <MoreHorizOutlinedIcon className='text-grayscale-800 text-base' />
              ) : (
                <VisibilityOutlinedIcon className='text-grayscale-800 text-base' />
              )
            }
          >
            {isBasicMode
              ? t('material.rich_text.advanced_editor')
              : t('material.rich_text.preview')}
          </WuButton>
        ) : null}
      </div>
    </>
  )
}
