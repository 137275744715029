import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { ListSubheader } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import { isEmpty } from 'lodash-es'
import React, { useCallback } from 'react'
import { useTranslation } from 'next-i18next'

import { Textfield } from '.'
import { WuTextfieldProps } from './textfield'

export interface SelectGroupOptionTypes {
  [key: string]: { name: string; value: string; id?: number }[]
}
interface SelectProps extends WuTextfieldProps {
  options?: SelectGroupOptionTypes
}

const SelectWithGroup = (
  { options, label, placeholder, SelectProps, value, ...restProps }: SelectProps,
  ref: React.Ref<any>,
) => {
  const { t } = useTranslation()

  const renderSelectGroup = (product: {
    value: { name: string; value: string; id?: number }[]
    key: string
  }) => {
    const items = product.value.map(cat => {
      return (
        <MenuItem key={cat.value} value={cat.value} className='text-sm'>
          {cat.name}
        </MenuItem>
      )
    })
    if (items.length > 0) {
      return [
        <ListSubheader key={product.key} className='text-xs text-grayscale-300 my-3'>
          {product.key}
        </ListSubheader>,
        items,
      ]
    }
  }

  const renderValue = useCallback(
    (value: any) => {
      if (value && options) {
        return (
          <div className='flex gap-2 items-center'>
            {Object.values(options).map(i => i.find(o => o.value === value)?.name)}
          </div>
        )
      } else {
        return <div className='text-grayscale-300  text-sm'>{placeholder}</div>
      }
    },
    [SelectProps, value],
  )

  return (
    <Textfield
      select
      sx={{
        '& .MuiSelect-select': {
          padding: 0,
          borderRadius: '8px',
        },
      }}
      InputProps={{ style: { padding: 0, fontSize: '0.875rem' } }}
      label={label}
      SelectProps={{
        displayEmpty: true,
        IconComponent: ExpandMoreIcon,
        renderValue: renderValue,
        ...SelectProps,
      }}
      value={value}
      placeholder={placeholder}
      ref={ref}
      {...restProps}
    >
      {isEmpty(options) ? (
        <MenuItem value='' disabled>
          {t('common.no_options')}
        </MenuItem>
      ) : (
        Object.entries(options ?? {}).map(([key, value]) => {
          if (value.length > 0) {
            return renderSelectGroup({ key, value })
          }
          return null
        })
      )}
    </Textfield>
  )
}

export default React.forwardRef(SelectWithGroup)
