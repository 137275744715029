import { i18n } from 'next-i18next'
import { AllFileTypes } from './media-file-uploader'
import { toast } from '@libs-components/components/toast'

import { Swal } from '.././../utils'
import { validateFileSize } from '../../utils'

export const getAcceptedFormats = (types: string[]) => {
  if (!types) return ''

  return types.map(type => `.${type.toLowerCase()}`).join(',')
}

export const checkFileExtension = ({
  file,
  allowedFormats,
}: {
  file: File
  allowedFormats: string[]
}): boolean => {
  const extension: string = file.name.split('.').pop() as string
  const loweredTypes = allowedFormats.map(format => format.toLowerCase())
  return loweredTypes.includes(extension.toLowerCase())
}

export const getDefaultMaxSizeInMb = (type: keyof typeof AllFileTypes) => {
  switch (type) {
    case 'audio':
      return 60
    case 'video':
      return 1000
    case 'image':
      return 30
    case 'document':
      return 3
    case 'subtitles':
      return 60

    default:
      return 0
  }
}

export const fileMeetsRequirements = async ({
  file,
  type,
  formats,
  maxSize,
  maxSizeType = 'MB',
  shouldCheckVideoDuration = false,
}: {
  file: File
  type: keyof typeof AllFileTypes
  formats: string[]
  maxSize: number
  maxSizeType?: string
  shouldCheckVideoDuration?: boolean
}) => {
  if (!file) return false

  let meetsRequirements = true
  const isWithinSizeLimit = validateFileSize({
    sizeInBytes: file.size,
    maxSizeInMb: maxSize,
  })

  if (!isWithinSizeLimit) {
    toast.error({
      message: i18n?.t('uploader.error.file_size_exceeded', {
        maxSizeInMb: `${maxSize}${maxSizeType}`,
      }),
    })
    meetsRequirements = false
  }

  const fileExtensionIsCorrect = checkFileExtension({
    file,
    allowedFormats: formats,
  })

  if (!fileExtensionIsCorrect) {
    toast.error({
      message: i18n?.t('uploader.error.incorrect_format'),
    })
    meetsRequirements = false
  }

  if (type === 'video' && shouldCheckVideoDuration) {
    const duration = await checkVideoDuration(file)

    if (duration > 30) {
      await Swal.fire({
        icon: 'warning',
        title: i18n?.t('uploader.error.video_max_duration'),
        confirmButtonColor: 'primary-500',
        confirmButtonText: i18n?.t('common.confirm_alt'),
      })
      meetsRequirements = false
    }
  }

  return meetsRequirements
}

const checkVideoDuration = async (file: File): Promise<number> => {
  const video = document.createElement('video')
  video.src = URL.createObjectURL(file)
  const durationPromise = new Promise<number>((resolve, reject) => {
    video.onloadedmetadata = () => {
      resolve(video.duration)
    }
    video.onerror = () => {
      reject(new Error('Unable to load video metadata'))
    }
  })
  const duration = await durationPromise
  return duration
}
