import { forwardRef } from 'react'
import { MediaFileUploader } from '../index'
import { MediaFileUploaderProps } from '../media-file-uploader'
import { twMerge } from 'tailwind-merge'

type MediaFileUploaderWithInfoProps = MediaFileUploaderProps & {
  label?: string | React.ReactNode
  labelProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  helperText?: string
  helperTextProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  required?: boolean
}

const MediaFileUploaderWithInfo = forwardRef((props: MediaFileUploaderWithInfoProps) => {
  const { label, labelProps, helperText, helperTextProps, required, ...mediaFileUploaderProps } =
    props
  const { error } = mediaFileUploaderProps

  return (
    <div className='flex flex-col gap-2'>
      {label && (
        <div className='text-grayscale-800  text-sm font-medium leading-4' {...labelProps}>
          {' '}
          {label}
          {required && (
            <>
              {' '}
              <span className='text-peach-500'>*</span>
            </>
          )}
        </div>
      )}
      <MediaFileUploader {...mediaFileUploaderProps} />
      {helperText ? (
        <div
          className={twMerge('text-sm', error ? 'text-peach-500' : 'text-grayscale-400')}
          {...helperTextProps}
        >
          {helperText}
        </div>
      ) : null}
    </div>
  )
})

export default MediaFileUploaderWithInfo
