import WuButton from '../button'

interface CustomModalFooterButtonsProps {
  onCancel: () => void
  onSubmit: () => void
  submitButtonText: string
  cancelButtonText: string
  submitDisabled?: boolean
}

const CustomModalFooterButtons = ({
  onCancel,
  onSubmit,
  submitDisabled,
  cancelButtonText,
  submitButtonText,
}: CustomModalFooterButtonsProps) => {
  return (
    <div className='flex gap-4 w-full justify-end'>
      <WuButton color='cancel' variant='text' onClick={onCancel}>
        {cancelButtonText}
      </WuButton>
      <WuButton disabled={submitDisabled} onClick={onSubmit}>
        {submitButtonText}
      </WuButton>
    </div>
  )
}

export default CustomModalFooterButtons
