import { CategoryButtonGroup } from './category-button-group'
import { useTranslation } from 'next-i18next'

type EditorToolPanelProps = {
  basicButtons: { text: string; iconProps: { type: string }; onClick: () => void }[]
  advancedButtons: { text: string; iconProps: { type: string }; onClick: () => void }[]
}

export const EditorToolPanel = ({ basicButtons, advancedButtons }: EditorToolPanelProps) => {
  const { t } = useTranslation()
  return (
    <div className='flex flex-col gap-4 p-6'>
      <CategoryButtonGroup title={t('brand_management.basic')} buttons={basicButtons} />
      <CategoryButtonGroup title={t('brand_management.advanced')} buttons={advancedButtons} />
    </div>
  )
}
