import type { ComponentType } from 'react'
import type { PageComponentType } from '../../../types'
import type { PageComponentProps } from './types'
import dynamic from 'next/dynamic'
import { BrandBasicInfoSkeleton } from './brand-basic-info/skeleton'
import { CarouselImagesSkeleton } from './carousel-images/skeleton'
import { CarouselVideosSkeleton } from './carousel-videos/skeleton'
import { GapSkeleton } from './gap/skeleton'
import { HtmlBlockSkeleton } from './html-block/skeleton'

export const PageComponentImpl: {
  [K in PageComponentType.ComponentKindsType]: ComponentType<PageComponentProps<K>>
} = {
  article_links: dynamic(import('./article-links').then(({ ArticleLinks }) => ArticleLinks)),
  become_a_partner: dynamic(
    import('./become-a-partner').then(({ BecomeAPartner }) => BecomeAPartner),
  ),
  bonus: dynamic(import('./bonus').then(({ Bonus }) => Bonus)),
  brand_basic_info: dynamic(
    import('./brand-basic-info').then(({ BrandBasicInfo }) => BrandBasicInfo),
    { loading: BrandBasicInfoSkeleton },
  ),
  coupon_list: dynamic(import('./coupon-list').then(({ CouponSection }) => CouponSection)),
  gap: dynamic(
    import('./gap').then(({ Gap }) => Gap),
    { loading: GapSkeleton },
  ),
  carousel_images: dynamic(
    import('./carousel-images').then(({ CarouselImages }) => CarouselImages),
    { loading: CarouselImagesSkeleton },
  ),
  picked_product_list: dynamic(
    import('./picked-product-list').then(({ PickedProductList }) => PickedProductList),
  ),
  picked_brand_page_list: dynamic(
    import('./picked-brand-page-list').then(({ PickedBrandPageList }) => PickedBrandPageList),
  ),
  html_block: dynamic(
    import('./html-block').then(({ HtmlBlock }) => HtmlBlock),
    { loading: HtmlBlockSkeleton },
  ),
  carousel_videos: dynamic(
    import('./carousel-videos').then(({ CarouselVideos }) => CarouselVideos),
    { loading: CarouselVideosSkeleton },
  ),
  header: dynamic(import('./header').then(({ HeaderWithBgImage }) => HeaderWithBgImage)),
  intro: dynamic(import('./intro').then(({ Intro }) => Intro)),
  carousel: dynamic(import('./carousel').then(({ Carousel }) => Carousel)),
  facts: dynamic(import('./facts').then(({ Facts }) => Facts)),
  faq: dynamic(import('./faq').then(({ FAQ }) => FAQ)),
  float_campaign: dynamic(import('./float-campaign').then(({ FloatCampaign }) => FloatCampaign)),
  footer: dynamic(import('./footer').then(m => m.Footer)),
  more_products: dynamic(import('./more-products').then(({ MoreProducts }) => MoreProducts)),
  partners: dynamic(import('./partners').then(({ Partners }) => Partners)),
  platform: dynamic(import('./platform').then(({ Platform }) => Platform)),
  ui_demo: dynamic(import('./ui-demo').then(({ UiDemo }) => UiDemo)),
  user_feedbacks: dynamic(import('./user-feedbacks').then(({ UserFeedBacks }) => UserFeedBacks)),
  user_feedback: dynamic(import('./user-feedback').then(({ UserFeedback }) => UserFeedback)),
  video: dynamic(import('./video').then(({ Video }) => Video)),
}
