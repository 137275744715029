import { getMaterialsEligibilities, GetMaterialsEligibilitiesParamsType } from '@wordup/apis'
import { useTranslation } from 'next-i18next'
import useSWR from 'swr'
import { apiCaller } from '../api'
import { Swal } from '@wordup/lib-portal/utils'
import axios from 'axios'

export const useGetMaterialEligibilities = (props: GetMaterialsEligibilitiesParamsType) => {
  const { t } = useTranslation()

  const { data } = useSWR(
    props.materialId ? getMaterialsEligibilities(props) : null,
    async payloads => {
      try {
        const res = await apiCaller<{
          eligibleForTeacherGrading: boolean
        }>(payloads)
        return res
      } catch (err) {
        if (axios.isAxiosError(err)) {
          Swal.fire({
            icon: 'warning',
            text: err?.message,
            confirmButtonText: t('common.ok'),
          })
        }
      }
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateOnMount: true,
    },
  )

  return {
    eligibleForTeacherGrading: data?.data?.eligibleForTeacherGrading,
  }
}
