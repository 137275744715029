import ClearIcon from '@mui/icons-material/Clear'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Chip } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import { makeStyles } from '@mui/styles'
import { isEmpty } from 'lodash-es'
import React, { useCallback } from 'react'
import { useTranslation } from 'next-i18next'

import TailwindConfig from 'tailwind.config'

import { Textfield } from '.'
import { WuTextfieldProps } from './textfield'
import { twMerge } from 'tailwind-merge'
import { Text } from '@libs-components/components/text'

const TailwindColor = TailwindConfig.theme.extend.colors

export type SelectionOptionsTypes = {
  value: string
  name: string
  icon?: React.ReactElement
  color?: keyof typeof textColorDic
}[]

interface SelectProps extends WuTextfieldProps {
  options: SelectionOptionsTypes
  onChipDelete?: ({ e, value }: { e: any; value: any }) => void
}

const textColorDic = {
  default: 'text-grayscale-800',
  error: 'text-peach-500',
}

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiSelect-select': {
      padding: 0,
      borderRadius: '8px',
    },
  },
}))

const Select = React.forwardRef(
  (
    {
      options,
      label,
      placeholder,
      SelectProps,
      value,
      onChipDelete,
      ...restProps
    }: SelectProps,
    ref,
  ) => {
    const { t } = useTranslation()

    const renderValue = useCallback(
      (value: any) => {
        if (SelectProps?.multiple) {
          if (value?.length > 0) {
            return (
              <div className='flex gap-3 flex-wrap'>
                {value?.map((value: any) => {
                  const item = options.find(e => e.value === value)
                  return (
                    <Chip
                      key={value}
                      label={item?.name ?? ''}
                      clickable
                      className='z-10 my-auto leading-tight'
                      deleteIcon={
                        <ClearIcon
                          onMouseDown={(event: any) => event.stopPropagation()}
                        />
                      }
                      sx={{
                        '& .MuiChip-deleteIcon': {
                          color: TailwindColor['grayscale-700'],
                          fontSize: '1rem',
                        },
                        height: '1.5rem',
                      }}
                      onDelete={e => {
                        e.preventDefault()
                        if (onChipDelete) {
                          onChipDelete({ e, value })
                        }
                      }}
                    />
                  )
                })}
              </div>
            )
          } else {
            return <div className='text-grayscale-300 text-sm'>{placeholder}</div>
          }
        } else {
          if (value) {
            const option = options.find(option => option.value === value)
            return option ? (
              <div className='flex gap-2 items-center'>
                {option?.icon ? <span>{option.icon}</span> : null}
                <span
                  className={twMerge(
                    'overflow-hidden text-ellipsis',
                    textColorDic[option?.color ?? 'default'],
                  )}
                >
                  {option?.name}
                </span>
              </div>
            ) : (
              <Text variant='label_s_400'>{value}</Text>
            )
          } else {
            return <div className='text-grayscale-300 text-sm'>{placeholder}</div>
          }
        }
      },
      [SelectProps, value],
    )

    const classes = useStyles()
    return (
      <Textfield
        select
        classes={{ root: classes.root }}
        InputProps={{ style: { padding: 0, fontSize: '0.875rem' } }}
        label={label}
        SelectProps={{
          displayEmpty: true,
          IconComponent: ExpandMoreIcon,
          renderValue: renderValue,
          ...SelectProps,
        }}
        value={value}
        {...restProps}
      >
        {isEmpty(options) ? (
          <MenuItem value='' disabled>
            {t('common.no_options')}
          </MenuItem>
        ) : (
          options.map((option, index) => {
            return (
              <MenuItem
                value={option.value}
                key={`select_${index}_option`}
                sx={{
                  whiteSpace: 'normal',
                }}
                className={twMerge(
                  'flex gap-2 items-center text-sm',
                  textColorDic[option.color ?? 'default'],
                )}
              >
                {option.icon}
                {option.name}
              </MenuItem>
            )
          })
        )}
      </Textfield>
    )
  },
)

export default Select
