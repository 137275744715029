import useSWR from 'swr'
import { apiCaller } from '../../api'
import { getShopPage } from '@wordup/apis'
import { PageComponentType } from '@libs-components/types'

export const useSwrGetShopPage = (slugKey: string) => {
  const { data, mutate: mutateShopPage } = useSWR(
    slugKey ? getShopPage(slugKey) : null,
    async payloads => {
      const res = await apiCaller<PageComponentType.ResponseType>(payloads)
      return res.data
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  )
  return { data, mutateShopPage }
}
