import axios from 'axios'
import { v4 } from 'uuid'

import { getFileExtension, getFilename, sanitizeFileName } from '../../../utils'

import AssetsService from './index'
import { UploaderModeTypes } from './types'

interface AssetUploadParams {
  file: File
  materialId: string
  isPublic?: boolean
  prefix?: string
  mode: UploaderModeTypes
  setProgress?: (progress: number) => void
  userId?: string
  namespace?: string
  asGuest?: boolean
}

interface VideoUploadParams {
  file: File
  materialId: string
  componentId: string
  prefix?: string
  setProgress?: (progress: number) => void
}

export const AssetUploader = () => ({
  upload: async ({
    file,
    materialId,
    isPublic = false,
    prefix = '',
    mode,
    userId,
    namespace,
    setProgress,
    asGuest,
  }: AssetUploadParams) => {
    const fileName = sanitizeFileName(file.name)
    const fileExtension = getFileExtension(fileName)
    const s3Filename = `${prefix}${v4()}.${fileExtension}`

    let urlsRes
    if (asGuest) {
      urlsRes = await AssetsService.getAsGuestPresignedUrl({
        fileName,
        mode: 'partner_entity',
        ownerType: 'guest',
      })
    } else {
      urlsRes = await AssetsService.getPresignedUrl({
        materialId,
        fileName: s3Filename,
        mode: mode,
        userId: userId,
        namespace,
      })
    }
    const presignedUrls = urlsRes.data.data.attributes.urls
    const putUrl = presignedUrls.find(url => url.type === 'put_object')?.url
    const getUrl = presignedUrls.find(
      url => url.type === (isPublic ? 'get_object_public' : 'get_object'),
    )?.url

    if (putUrl) {
      const uploadRes = await axios.put(putUrl, file, {
        headers: { 'Content-type': file.type },
        onUploadProgress: progressEvent => {
          if (setProgress === undefined || !progressEvent.loaded || !progressEvent.total) return
          const progress = progressEvent.loaded / progressEvent.total
          setProgress(progress)
        },
      })
      if (uploadRes.status >= 200 && uploadRes.status < 300) {
        if (getUrl) {
          return isPublic ? getUrl.split('?')[0] : getUrl
        }
        throw new Error('[WORDUP - asset-uploader] no find get url')
      }
      throw new Error('[WORDUP - asset-uploader] upload to s3 occured error')
    }
    throw new Error('[WORDUP - asset-uploader] no find put url')
  },
  uploadVideo: async ({ file, materialId, componentId, setProgress }: VideoUploadParams) => {
    const urlsRes = await AssetsService.getVideoPierPresignedUrl(materialId, componentId, file.name)

    const presignedUrls = urlsRes.data.data.attributes.urls
    const putUrl = presignedUrls.find(url => url.type === 'put_object')?.url
    const getUrl = presignedUrls.find(url => url.type === 'get_object')?.url
    const s3Key = urlsRes.data.data.attributes.s3Key || ''

    if (putUrl) {
      const uploadRes = await axios.put(putUrl, file, {
        onUploadProgress: progressEvent => {
          if (setProgress === undefined || !progressEvent.loaded || !progressEvent.total) return
          const progress = progressEvent.loaded / progressEvent.total
          setProgress(progress)
        },
      })
      if (uploadRes.status >= 200 && uploadRes.status < 300) {
        const convertRes = await AssetsService.toVideoConvert(materialId, componentId, s3Key)

        if (getUrl) {
          return {
            assetUrl: convertRes.data.data.url,
            displayUrl: getUrl,
          }
        }
        throw new Error('[WORDUP - video-uploader] no find get url')
      }
      throw new Error('[WORDUP - video-uploader] upload to s3 occured error')
    }
    throw new Error('[WORDUP - video-uploader] no find put url')
  },

  uploadWithUserMode: async ({
    file,
    materialId,
    mode,
    userId,
    setProgress,
    enableFileNamePrefix,
  }: {
    file: File
    materialId: string
    mode: 'user' | 'user_material'
    setProgress?: (progress: number) => void
    userId?: string
    enableFileNamePrefix?: boolean
  }) => {
    const fileName = sanitizeFileName(file.name)
    const fileExtension = getFileExtension(fileName)
    const fileNameWithoutExtension = getFilename(file.name)

    const s3Filename = `${
      enableFileNamePrefix ? `${fileNameWithoutExtension}_` : ''
    }${v4()}.${fileExtension}`

    const urlsRes = await AssetsService.getAssetUrl({
      materialId,
      fileName: s3Filename,
      mode,
      userId,
    })

    const presignedUrls = urlsRes.data.data.attributes.urls
    const putUrl = presignedUrls.find(url => url.type === 'put_object')?.url
    const getUrl = presignedUrls.find(url => url.type === 'get_object')?.url

    if (putUrl) {
      const uploadRes = await axios.put(putUrl, file, {
        headers: { 'Content-type': file.type },
        onUploadProgress: progressEvent => {
          if (setProgress === undefined || !progressEvent.loaded || !progressEvent.total) return
          const progress = progressEvent.loaded / progressEvent.total
          setProgress(progress)
        },
      })
      if (uploadRes.status >= 200 && uploadRes.status < 300 && getUrl) {
        return { filePath: urlsRes.data.data.attributes.filePath, assetUrl: getUrl }
      }
      throw new Error('[WORDUP - asset-uploader] upload to s3 occured error')
    }
    throw new Error('[WORDUP - asset-uploader] no find put url')
  },
})
