import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import type { BrandPageInputFormTypes, CarouselImagesFormTypes } from '../../../types'
import { Select } from '@wordup/lib-portal/components'
import { toast } from '@libs-components/index'
import { Icon } from '@libs-components/components/icon'
import { Text } from '@libs-components/components/text'
import { useTranslation } from 'next-i18next'
import DraggableItems from '@wordup/lib-portal/components/draggable-items'
import { Button } from '@mui/material'
import { useMemo, useState } from 'react'
import WuButton from '../../../../../components/button'
import { createEmptyCarouselImage } from '../../../utils'
import { EditForm } from './edit-form'
import { EditModal } from '../../../components/edit-modal'
import { PreviewItem } from '../components/preview-item'
import { Swal } from '../../../../../utils'

type ImagePropsTypes = {
  index: number
}

const maxCount = 10

export const ImagesForm = ({ index }: ImagePropsTypes) => {
  const { t } = useTranslation()

  const { control, setValue } = useFormContext<BrandPageInputFormTypes>()

  const { fields, move, append, remove } = useFieldArray({
    control,
    name: `inputItems.${index}.images.attributes.custom.data`,
  })

  const {
    move: movePreview,
    append: appendPreview,
    remove: removePreview,
  } = useFieldArray({
    control,
    name: `previewItems.${index}.images.attributes.custom.data`,
  })

  useWatch({ control, name: `inputItems.${index}.images.attributes.custom.data` })

  const fieldTypes = useWatch({ control, name: `inputItems.${index}.images.type` })
  const previewFields = useWatch({
    control,
    name: `inputItems.${index}.images.attributes.custom.data`,
  })

  const [targetIndex, setTargetIndex] = useState<number | null>(null)
  const [mode, setMode] = useState<'add' | 'edit' | 'null'>('null')

  const totalCounts = useMemo(() => fields.length, [fields])

  const openAddModal = () => {
    if (totalCounts >= maxCount) {
      toast.error({ message: t('brand_management.images.maximum_of_images', { count: maxCount }) })
      return
    }
    setMode('add')
    append(createEmptyCarouselImage())
    appendPreview(createEmptyCarouselImage())
    setTargetIndex(null)
  }

  const handleCloseAddModal = () => {
    setMode('null')
  }

  const handleCancel = (targetIndex: number) => {
    if (mode === 'add') {
      remove(targetIndex)
      removePreview(targetIndex)
    }

    handleCloseAddModal()
  }

  const handleSubmitProduct = () => {
    handleCloseAddModal()
  }

  const handleEditTarget = (index: number) => {
    setTargetIndex(index)
    setMode('edit')
  }

  const handleDeleteTarget = (index: number) => {
    remove(index)
    removePreview(index)
  }

  const handleMoveItem = ({ fromIndex, toIndex }: { fromIndex: number; toIndex: number }) => {
    move(fromIndex, toIndex)
    movePreview(fromIndex, toIndex)
    setTargetIndex(null)
  }

  const handleSelectType = async (value: CarouselImagesFormTypes) => {
    const updateImageFields = (firstItem: any) => {
      setValue(`inputItems.${index}.images.attributes.custom.data`, [firstItem])
      setValue(`previewItems.${index}.images.attributes.custom.data`, [firstItem])
    }

    const updateImageType = (value: CarouselImagesFormTypes) => {
      setValue(`inputItems.${index}.images.type`, value)
      setValue(`previewItems.${index}.images.type`, value)
    }

    if (fieldTypes === 'carousel_images' && fields.length > 1) {
      const res = await Swal.fire({
        icon: 'warning',
        title: t('brand_management.confirm_change_type_title'),
        text: t('brand_management.confirm_change_type_description', {
          type: t('brand_management.images.title'),
        }),
        showCancelButton: true,
        cancelButtonText: t('common.cancel'),
      })
      if (!res.isConfirmed) return
    }
    updateImageFields(fields[0])
    updateImageType(value)

    if (fields.length === 0) {
      const emptyVideo = createEmptyCarouselImage()
      append(emptyVideo)
      appendPreview(emptyVideo)
    }
  }

  const options: { name: string; value: CarouselImagesFormTypes }[] = [
    { name: t('brand_management.images.single_image'), value: 'single_image' },
    { name: t('brand_management.images.carousel_images'), value: 'carousel_images' },
  ]

  const targetFieldIndex = useMemo(() => {
    let targetFieldIndex = 0

    if (mode === 'add') {
      targetFieldIndex = fields.length - 1
    }
    if (mode === 'edit' && targetIndex !== null) {
      targetFieldIndex = targetIndex
    }

    return targetFieldIndex
  }, [targetIndex, fields])

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex flex-col gap-4'>
        <Controller
          name={`inputItems.${index}.images.type`}
          render={({ field }) => {
            return (
              <Select
                {...field}
                onChange={e => {
                  const value = e.target.value as CarouselImagesFormTypes

                  handleSelectType(value)
                }}
                label={t('common.type')}
                options={options}
              />
            )
          }}
        />

        <div className='flex items-center justify-between'>
          {fieldTypes === 'carousel_images' ? (
            <div className='flex w-full justify-between'>
              <Text variant='heading_m_500'>{t('brand_management.images.title')}</Text>
              <Button
                onClick={openAddModal}
                size='small'
                startIcon={<Icon type='add' color='primary-500' />}
                className='text-primary-500 flex items-center justify-center'
              >
                {t('brand_management.add_count', {
                  count: totalCounts,
                  maxCount,
                })}
              </Button>
            </div>
          ) : null}
        </div>
      </div>

      {fieldTypes === 'single_image' ? <EditForm index={index} nestedIndex={0} /> : null}

      {fieldTypes === 'carousel_images' ? (
        <div className='h-full max-h-[260px] w-full overflow-auto'>
          <DraggableItems
            items={fields}
            onDragEnd={handleMoveItem}
            direction='vertical'
            dragDisabled={false}
            containerClassName='w-full'
            renderItem={({ item, index }) => {
              const preview = previewFields[index] || item

              return (
                <PreviewItem
                  key={`${item.id}`}
                  handleDelete={() => {
                    handleDeleteTarget(index)
                  }}
                  handleEdit={() => {
                    handleEditTarget(index)
                  }}
                  title={preview.title}
                  description={preview.description}
                  leftItem={{
                    type: 'image',
                    imgUrl: preview.imageUrlMobile || preview.imageUrlDesktop,
                  }}
                />
              )
            }}
          />
        </div>
      ) : null}

      {fieldTypes === 'carousel_images' ? (
        <EditModal
          modalProps={{
            open: mode === 'add' || mode === 'edit',
            onClose: handleCloseAddModal,
          }}
          title={`${t('common.add')}${t('brand_management.images.title')}`}
          description={t('brand_management.images.maximum_of_images', { count: maxCount })}
          actionProps={{
            onSubmitProps: {
              onClick: handleSubmitProduct,
            },
            onCancelProps: {
              onClick: () => {
                handleCancel(targetFieldIndex)
              },
            },
            leftActionElement:
              fields.length > 1 ? (
                <WuButton
                  variant='outlined'
                  color='info'
                  onClick={() => {
                    handleDeleteTarget(targetFieldIndex)
                    setMode('null')
                  }}
                >
                  {t('brand_management.images.delete_image')}
                </WuButton>
              ) : (
                <div />
              ),
          }}
        >
          <EditForm index={index} nestedIndex={targetFieldIndex} />
        </EditModal>
      ) : null}
    </div>
  )
}
