import styled from '@emotion/styled'
import React, { FC } from 'react'
import LineEllipsis from 'react-lines-ellipsis'
import Stars from 'react-stars'

import { Icon } from '../icon'
import { useTranslation } from 'react-i18next'

const Container = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
`

const CardImage = styled.div<{ isSkeleton?: boolean }>`
  position: relative;
  outline: none;
  flex: none;
  width: 100%;
  height: 145px;

  img {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    object-fit: cover;
  }

  @media (min-width: 600px) {
    height: 160px;
  }

  ${({ isSkeleton }) => isSkeleton && `background: #f2f2f2;`}
`

const CardLink = styled.a<{ isStatic?: boolean }>`
  box-sizing: border-box;
  margin-bottom: 14px;
  display: block;
  width: 100%;
  height: auto;
  color: #383b3d !important;
  pointer-events: ${({ isStatic }) => (isStatic ? 'none' : 'fill')};
  text-decoration: none;

  img picture {
    pointer-events: ${({ isStatic }) => (isStatic ? 'none' : 'auto')};
  }
`

const CardBody = styled.div`
  box-sizing: border-box;
  flex: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 16px;
  gap: 12px;
`

const CardTitle = styled(CardLink)`
  min-width: 180px;
  font-weight: 500;
  margin: 0;

  h4 {
    margin: 0;
    font-size: 16px;
  }
`

const CardSlogan = styled.p`
  margin: 0;
  font-size: 14px;
  width: 100%;
  min-width: 180px;
  font-weight: 400;
  color: #7c8282;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const CardAuthor = styled(CardSlogan)`
  color: #9da1a1;
`

const CardRating = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 14px;

  .staring {
    display: inline-block;
    margin-right: 2px;
  }

  span {
    display: inline-block;
    margin-right: 2px;
  }
`

const CardContentSkeleton = styled.div`
  width: 100%;
  height: 14px;
  margin-bottom: 4px;
  background-color: #f2f2f2;
`

const CardLearnWith = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0;
  color: #72afff;
  font-size: 14px;
`

export interface ProductCardProps {
  isSkeleton?: boolean
  isStatic?: boolean
  className?: string

  attrs: {
    productableId: string
    name: string
    slogan?: string | null
    learnWith?: string | null
    listPrice?: string
    salePrice?: string
    copiesSold?: number | null
    allowTrial?: boolean
    showRating: boolean
    ratingCount?: number | null
    averageRating?: number | null
    shopCoverImageUrl?: string | null
    authorName: string | null
    publisherName: string | null
  }
  getPath?: (path: string) => string
}

export const ProductCard: FC<React.PropsWithChildren<ProductCardProps>> = ({
  children,
  className,
  isSkeleton = false,
  isStatic = false,
  attrs,
  getPath = id => `/products/${id}`,
}) => {
  const { t } = useTranslation()

  return (
    <Container className={className}>
      <CardImage tabIndex={0} isSkeleton={isSkeleton}>
        <CardLink isStatic={isStatic} href={getPath(attrs.productableId)}>
          {!isSkeleton && attrs.shopCoverImageUrl && (
            <img
              src={attrs.shopCoverImageUrl}
              alt={attrs.name}
              className='h-full w-full object-cover'
            />
          )}
        </CardLink>
      </CardImage>

      <CardBody>
        {!isSkeleton ? (
          <>
            <CardLearnWith>
              <Icon type='group' />
              {`${attrs.learnWith || 0} ${t(
                'crowdin:wu-components.product-card.students-learn-together',
              )}`}
            </CardLearnWith>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <CardTitle isStatic={isStatic} href={getPath(attrs.productableId)}>
                <LineEllipsis text={attrs.name} component='h4' maxLine={2} />
              </CardTitle>

              {attrs.slogan && <CardSlogan>{attrs.slogan} </CardSlogan>}
            </div>

            {attrs.showRating && (
              <CardRating>
                <div className='staring'>
                  <Stars
                    edit={false}
                    count={5}
                    value={attrs.averageRating || 0}
                    size={14}
                    color1='#e5e5e5'
                    color2='#ffc914'
                  />
                </div>
                ({attrs.ratingCount})
              </CardRating>
            )}
            {attrs.authorName && (
              <CardAuthor>{`${attrs.authorName}${
                attrs.publisherName ? `｜${attrs.publisherName}` : ''
              }`}</CardAuthor>
            )}
          </>
        ) : (
          <>
            <CardContentSkeleton />
            <CardContentSkeleton />
            <CardContentSkeleton />
          </>
        )}

        {children}
      </CardBody>
    </Container>
  )
}
