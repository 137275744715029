import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined'
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined'
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined'
import { memo } from 'react'

import { AllAcceptedFileTypes } from './media-file-uploader'
import { Icon, IconTypes } from '@libs-components/components/icon'

export type FileIconProps = {
  type: AllAcceptedFileTypes
  className?: string
  iconColor?: IconTypes['color']
}

const FileIcon = ({ type, className, iconColor = 'grayscale-800' }: FileIconProps) => {
  let Comp
  if (type === 'pdf' || type === 'csv') {
    return <Icon type='attachment' size={24} className={className} color={iconColor} />
  }

  if (type === 'png' || type === 'jpg') {
    return <Icon type='imagesmode' size={24} className={className} color={iconColor} />
  }

  switch (type) {
    case 'mp3': {
      Comp = VolumeUpOutlinedIcon
      break
    }
    case 'mp4': {
      Comp = VideocamOutlinedIcon
      break
    }

    case 'vtt': {
      Comp = CommentOutlinedIcon
      break
    }

    default: {
      Comp = VolumeUpOutlinedIcon
      break
    }
  }
  return <Comp className={className} />
}

export default memo(FileIcon)
