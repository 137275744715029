import { Button, CircularProgress, type ButtonProps } from '@mui/material'
import { twMerge } from 'tailwind-merge'
import { forwardRef, type ElementType } from 'react'

export type WuButtonProps = ButtonProps & {
  isLoading?: boolean
  component?: ElementType
  loadingCircularClassName?: string
}

const WuButton = forwardRef<HTMLButtonElement, WuButtonProps>((props, ref) => {
  const {
    component = 'button',
    isLoading,
    startIcon,
    disabled,
    className,
    loadingCircularClassName,
    style,
    ...restProps
  } = props
  return (
    <Button
      ref={ref}
      component={component}
      variant='contained'
      {...restProps}
      className={twMerge(
        'flex min-h-[2.25rem] items-center justify-center rounded-full px-3 py-2 text-sm font-normal shadow-none',
        className,
      )}
      style={{ textTransform: 'none', ...style }}
      startIcon={
        isLoading ? (
          <CircularProgress
            className={twMerge('text-grayscale-800', loadingCircularClassName)}
            size={18}
          />
        ) : (
          startIcon
        )
      }
      disabled={isLoading || disabled}
    >
      {props.children}
    </Button>
  )
})

WuButton.displayName = 'WuButton'
export default WuButton
