import { i18n } from 'next-i18next'
import Swal from '@libs-components/components/sweetalert'

export const fireNextUnitInfoAlert = async ({
  nextSectionName,
  navigateToNextUnit,
}: {
  nextSectionName?: string
  navigateToNextUnit: () => void
}) => {
  const nextSectionText = nextSectionName
    ? `${i18n?.t('material_component_navigation.next_info_alert.title_section', {
        nextSectionName: nextSectionName,
      })}`
    : ''

  const title = `${i18n?.t(
    'material_component_navigation.next_info_alert.title',
  )} ${nextSectionText}`

  const res = await Swal.fire({
    title: title,
    icon: 'info',
    showCancelButton: true,
    confirmButtonText: i18n?.t('common.confirm'),
    cancelButtonText: i18n?.t('common.cancel'),
  })
  if (res.isConfirmed) {
    navigateToNextUnit()
  }
}
