import Checkbox from '@mui/material/Checkbox'
import { produce } from 'immer'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { twMerge } from 'tailwind-merge'
import { v4 as uuidv4 } from 'uuid'

import { useConst } from '@wordup/utils'
import { DragHandlerType } from './types'
import { IconButton, IconButtonProps } from '@mui/material'
import DragHandleIcon from '@mui/icons-material/DragHandle'

export interface ItemWithIdType {
  id: string
  selected?: boolean
  [key: string]: any
}

export interface DraggableItemsProps<T extends ItemWithIdType> {
  items: T[]
  dragDisabled: boolean
  onDragEnd: ({
    fromIndex,
    toIndex,
    draggableId,
  }: {
    fromIndex: number
    toIndex: number
    draggableId: string
  }) => void
  renderItem: ({ item, index }: { item: T; index: number }) => React.ReactNode
  containerClassName?: string
  direction: 'vertical' | 'horizontal'
  dragButtonContainerClassName?: string
  draggableBlockClassName?: string
  dragHandler?: DragHandlerType
  selectable?: boolean
  onSelectChange?: (selectedItems: T[]) => void
  clickProps?: {
    onClick: ({ item, index }: { item: T; index: number }) => void
    clickedIndex?: number
  }
  hideDragButtons?: number[]
}

const DraggableItems = <T extends ItemWithIdType>({
  items,
  dragDisabled,
  onDragEnd,
  renderItem,
  containerClassName,
  direction,
  dragButtonContainerClassName,
  draggableBlockClassName,
  dragHandler = 'drag-button',
  selectable,
  onSelectChange,
  clickProps,
  hideDragButtons,
}: DraggableItemsProps<T>) => {
  const handleDrag = ({ source, destination, draggableId }: DropResult) => {
    if (!destination) return
    if (source.index === destination.index) return

    onDragEnd({
      fromIndex: source.index,
      toIndex: destination.index,
      draggableId: draggableId,
    })
  }

  const droppableId = useConst(() => `draggable-items-droppable-area-${uuidv4()}`)

  return (
    <DragDropContext onDragEnd={handleDrag}>
      <Droppable droppableId={droppableId} direction={direction} isDropDisabled={dragDisabled}>
        {provided => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={twMerge(
              `flex items-start gap-3 ${direction === 'vertical' ? 'flex-col' : ''}`,
              containerClassName,
            )}
          >
            {items &&
              items.map((item, index) => (
                <Draggable
                  key={item.id}
                  index={index}
                  draggableId={item.id}
                  isDragDisabled={dragDisabled}
                >
                  {provided => {
                    const draggableItemProps = {
                      ...provided.draggableProps,
                      ...(dragHandler === 'draggable-item' ? provided.dragHandleProps : {}),
                    }

                    const dragButtonProps = {
                      ...(dragHandler === 'drag-button' ? provided.dragHandleProps : {}),
                    }

                    return (
                      <div
                        ref={provided.innerRef}
                        {...draggableItemProps}
                        className={twMerge(
                          `group flex items-center ${direction === 'vertical' ? 'w-full' : ''}`,
                          [
                            draggableBlockClassName,
                            clickProps?.clickedIndex === index
                              ? 'border-primary-700 rounded-lg border-2'
                              : '',
                            clickProps?.onClick ? 'cursor-pointer' : '',
                          ],
                        )}
                        onClick={() => {
                          if (clickProps?.onClick) {
                            clickProps.onClick({ item, index })
                          }
                        }}
                      >
                        <div className={`flex ${direction === 'vertical' ? 'w-full' : 'flex-col'}`}>
                          {hideDragButtons && hideDragButtons?.includes(index) ? (
                            <button className='w-6 p-4' {...dragButtonProps} disabled />
                          ) : (
                            <div
                              className={twMerge(
                                `${
                                  direction === 'vertical' ? '' : 'flex items-center justify-center'
                                }`,
                                dragButtonContainerClassName,
                              )}
                            >
                              <DragButton
                                disabled={dragDisabled}
                                {...dragButtonProps}
                                className={`${item?.selected ? 'hidden' : 'flex'} ${
                                  selectable ? 'group-hover:hidden' : ''
                                }`}
                              />
                            </div>
                          )}

                          <div className='flex items-center justify-center'>
                            <Checkbox
                              className={`${item?.selected ? 'block' : 'hidden'} ${
                                selectable ? 'group-hover:block' : ''
                              } h-10 w-10`}
                              disableRipple
                              size='small'
                              checked={item?.selected}
                              onChange={e => {
                                if (onSelectChange) {
                                  onSelectChange(
                                    produce(items, draft => {
                                      draft[index].selected = e.target.checked
                                    }),
                                  )
                                }
                              }}
                            />
                          </div>
                          {renderItem({ item, index })}
                        </div>
                      </div>
                    )
                  }}
                </Draggable>
              ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default DraggableItems

export const DragButton = (props: IconButtonProps) => {
  const { className, ...restProps } = props
  return (
    <IconButton
      aria-label='drag item'
      className={twMerge('flex h-10 items-center justify-center', className)}
      {...restProps}
    >
      <DragHandleIcon className='text-grayscale-300' />
    </IconButton>
  )
}
