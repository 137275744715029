import { Textfield } from '@wordup/lib-portal/components'
import { Icon } from '@libs-components/components/icon'
import { Text } from '@libs-components/components/text'
import { Trans, useTranslation } from 'next-i18next'
import DraggableItems from '@wordup/lib-portal/components/draggable-items'
import { Button, Divider, Skeleton } from '@mui/material'
import { WuModal } from '@libs-components/components/modal'
import WuButton from '../../../../../components/button'
import { twMerge } from 'tailwind-merge'
import { PreviewItem } from '../components/preview-item'
import { useProductFormHandler } from './use-product-form-handler'
import { toShopProductPage } from '@wordup/lib-portal/routes'

type ProductFormTypes = {
  index: number
  orgId: string
}

export const ProductsForm = ({ index, orgId }: ProductFormTypes) => {
  const { t } = useTranslation()

  const {
    fields,
    openProductModal,
    searchValue,
    products,
    lastItem,
    selectedProductCount,
    isValidating,
    maxCount,
    ref,
    getIsSelected,
    updateSelectedProducts,
    openAddModal,
    handleCancel,
    handleSubmitProduct,
    setSearchValue,
    handleMoveItem,
    handleDeleteItem,
    closeAddModal,
  } = useProductFormHandler(index, orgId)

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex items-center justify-between'>
        <Text variant='heading_m_500'>{t('common.product')}</Text>

        <Button
          onClick={openAddModal}
          size='small'
          startIcon={<Icon type='add' color='primary-500' />}
          className='text-primary-500 flex items-center justify-center'
        >
          {t('brand_management.products.add_product_count', {
            count: selectedProductCount,
            maxCount,
          })}
        </Button>
      </div>

      <DraggableItems
        items={fields}
        onDragEnd={({ fromIndex, toIndex }) => {
          handleMoveItem(fromIndex, toIndex)
        }}
        direction='vertical'
        dragDisabled={false}
        containerClassName='w-full'
        renderItem={({ item, index }) => {
          return (
            <PreviewItem
              key={`${item.id}`}
              disabled={!item.shopListed}
              title={item.name}
              handleDelete={() => {
                handleDeleteItem(index)
              }}
              tooltip={
                !item.shopListed
                  ? {
                      title: t('brand_management.products.shop_unlisted'),
                      type: 'hover',
                    }
                  : undefined
              }
              leftItem={{
                type: 'image',
                imgUrl: item.coverImageUrl,
                imgSize: 'rectangle',
              }}
            />
          )
        }}
      />

      <WuModal open={openProductModal} onClose={closeAddModal} padding='0px' maxWidth='500px'>
        <div className='flex flex-col'>
          <div className='flex flex-col p-4'>
            <Text variant='heading_m_500'>{t('admin_nav.product_management.add_product')}</Text>
            <Text variant='body_m_400' color='grayscale-500'>
              {`${t('brand_management.products.max_count', {
                maxCount,
              })}，${t('brand_management.products.only_public_can_be_selected')}`}
            </Text>
          </div>
          <Divider />

          <div className='flex flex-col gap-4 p-4'>
            <Textfield
              placeholder={t('course.search_material')}
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
            />

            <div className='flex h-full max-h-[500px] w-full flex-col gap-4 overflow-auto'>
              {products?.map(product => {
                const productId = product.id

                const { name, coverImageUrl } = product?.attributes
                const isSelected = getIsSelected(productId)

                const handleClick = () => {
                  if (selectedProductCount >= maxCount && !isSelected) {
                    return
                  }

                  updateSelectedProducts({
                    productId,
                    name,
                    coverImageUrl,
                    isSelected,
                  })
                }
                return (
                  <button
                    key={productId}
                    className={twMerge(
                      'w-full items-center justify-between gap-x-2 rounded-lg border-[1px] p-4',
                      isSelected ? 'border-peach-500 ' : 'border-grayscale-100',
                    )}
                    onClick={handleClick}
                  >
                    <PreviewItem
                      title={name}
                      titleLineClamp={2}
                      rightColum={
                        productId ? (
                          <a
                            href={toShopProductPage(productId)}
                            className='col-span-3 flex-shrink-0 text-right text-sm underline'
                            target='_blank'
                            onClick={e => e.stopPropagation()}
                          >
                            {t('common.see_more')}
                          </a>
                        ) : null
                      }
                      leftItem={{
                        type: 'image',
                        imgUrl: coverImageUrl,
                        imgSize: 'rectangle',
                      }}
                    />

                    {lastItem ? <div ref={ref} /> : null}
                  </button>
                )
              })}
              {isValidating ? <Skeleton /> : null}
            </div>
          </div>
          <Divider />

          <div className='flex justify-between p-4'>
            <Text variant='body_m_400'>
              <Trans
                i18nKey='brand_management.products.has_select_product'
                values={{ count: selectedProductCount }}
                components={{ highlight: <Text variant='body_m_400' color='peach-500' /> }}
              />
            </Text>

            <div className='flex gap-3'>
              <WuButton onClick={handleCancel} variant='text'>
                {t('common.cancel')}
              </WuButton>

              <WuButton onClick={handleSubmitProduct}>{t('common.add')}</WuButton>
            </div>
          </div>
        </div>
      </WuModal>
    </div>
  )
}
