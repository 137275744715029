import { sendGtagEvent } from '../utils'

type SortType = 'descending' | 'ascending'

const sendTagAnalysisClassAnswerRateSort = (userId: string, sortType: SortType) => {
  sendGtagEvent({
    name: `tag_analysis_class_answer_rate_sort_${sortType}`,
    params: { user_id: userId },
  })
}

const sendTagAnalysisClassCorrectRateSort = (userId: string, sortType: SortType) => {
  sendGtagEvent({
    name: `tag_analysis_class_correct_rate_sort_${sortType}`,
    params: { user_id: userId },
  })
}

const sendTagAnalysisStudentAnswerCountSort = (userId: string, sortType: SortType) => {
  sendGtagEvent({
    name: `tag_analysis_student_answer_count_sort_${sortType}`,
    params: { user_id: userId },
  })
}

const sendTagAnalysisStudentCorrectRateSort = (userId: string, sortType: SortType) => {
  sendGtagEvent({
    name: `tag_analysis_student_correct_rate_sort_${sortType}`,
    params: { user_id: userId },
  })
}

export {
  sendTagAnalysisClassAnswerRateSort,
  sendTagAnalysisClassCorrectRateSort,
  sendTagAnalysisStudentAnswerCountSort,
  sendTagAnalysisStudentCorrectRateSort,
}
