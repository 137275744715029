import { BrandPageInputFormTypes } from './types'

export const DEFAULT_VALUES: BrandPageInputFormTypes = {
  pageInfo: {
    key: '',
    description: '',
    ogImage: '',
  },
  inputItems: [],
  previewItems: [],
}
