import { Controller, useFormContext } from 'react-hook-form'
import type { BrandPageInputFormTypes } from '../../types'
import { Textfield } from '@wordup/lib-portal/components'
import { Text } from '@libs-components/components/text'
import { useTranslation } from 'next-i18next'

type GapPropsTypes = {
  index: number
}

export const GapForm = ({ index }: GapPropsTypes) => {
  const { t } = useTranslation()
  const { control, setValue } = useFormContext<BrandPageInputFormTypes>()

  return (
    <div className='flex flex-col'>
      <Controller
        name={`inputItems.${index}.gap.attributes.custom.data.height`}
        control={control}
        render={({ field }) => {
          return (
            <Textfield
              {...field}
              value={field.value || ''}
              onChange={e => {
                field.onChange(e.target.value)
                setValue(`previewItems.${index}.gap.attributes.custom.data.height`, +e.target.value)
              }}
              type='number'
              label={t('brand_management.gap.input_height')}
              placeholder={t('brand_management.gap.fill_in_input_height')}
              slotProps={{
                input: {
                  style: {
                    padding: '0.688rem',
                  },
                  endAdornment: <Text variant='body_m_400'>px</Text>,
                },
              }}
            />
          )
        }}
      />
    </div>
  )
}
