import { MediaFileUploaderWithInfo, Textfield } from '@wordup/lib-portal/components'
import { useTranslation } from 'next-i18next'
import { Text } from '@libs-components/components/text'
import { Divider } from '@mui/material'
import WuButton from '@wordup/lib-portal/components/button'

import { Controller, useFormContext } from 'react-hook-form'
import type { BrandPageInputFormTypes } from '../../../types'

type PageInfoFormProps = {
  handleCancel: () => void
  handlePageInfoSubmit: () => void
  pageInfoKeyDisabled?: boolean
}

export const PageInfo = ({
  handleCancel,
  handlePageInfoSubmit,
  pageInfoKeyDisabled,
}: PageInfoFormProps) => {
  const { t } = useTranslation()
  const {
    control,
    formState: { isDirty },
  } = useFormContext<BrandPageInputFormTypes>()

  return (
    <div className='flex flex-col'>
      <div className='p-4'>
        <Text variant='body_m_500'>{t('brand_management.page_settings')}</Text>
      </div>
      <Divider />
      <div className='flex flex-col gap-2 p-4'>
        <Controller
          control={control}
          name='pageInfo.key'
          rules={{
            required: true,
            pattern: {
              value: /^[a-zA-Z0-9_]+$/,
              message: t('brand_management.page_url_validation'),
            },
          }}
          render={({ field, formState: { errors } }) => {
            return (
              <Textfield
                {...field}
                label='Page url'
                helperText={t('brand_management.page_url_validation')}
                error={
                  errors?.pageInfo?.key?.type === 'required' ||
                  errors?.pageInfo?.key?.type === 'pattern'
                }
                required
                slotProps={{
                  input: {
                    startAdornment: (
                      <Text
                        variant='body_m_400'
                        style={{
                          padding: '0.688rem 0',
                          borderRadius: '0.25rem',
                        }}
                      >
                        https://shop.wordup.com.tw/brand/
                      </Text>
                    ),
                  },
                }}
                disabled={pageInfoKeyDisabled}
              />
            )
          }}
        />

        <div className='flex items-start gap-4'>
          <Controller
            control={control}
            name='pageInfo.description'
            render={({ field }) => (
              <Textfield
                {...field}
                label={t('brand_management.web_description')}
                placeholder={t('brand_management.web_description')}
              />
            )}
          />

          <div className='w-full max-w-[50%]'>
            <Controller
              control={control}
              name='pageInfo.ogImage'
              render={({ field }) => (
                <MediaFileUploaderWithInfo
                  {...field}
                  label={'og image width 1:1'}
                  type='image'
                  formats={['png', 'jpg']}
                  materialId={''}
                  mode='shop'
                  namespace={'brand'}
                  onChange={({ url }) => {
                    field.onChange(url)
                  }}
                  initUrl={field.value ?? ''}
                />
              )}
            />
          </div>
        </div>
      </div>
      <Divider />

      <div className='flex items-center justify-between p-4'>
        <Text variant='label_s_400' color='grayscale-400'>
          {t('brand_management.publish_reminder')}
        </Text>

        <div className='flex gap-4'>
          <WuButton variant='text' color='info' onClick={handleCancel}>
            {t('common.cancel')}
          </WuButton>

          <WuButton disabled={!isDirty} onClick={handlePageInfoSubmit}>
            {t('common.save')}
          </WuButton>
        </div>
      </div>
    </div>
  )
}
