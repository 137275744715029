import { Text } from '@libs-components/components/text'
import { EditorToolButton } from '@wordup/lib-portal/components'

export type CategoryButtonTypes = {
  text: string
  iconProps: { type: string }
  onClick: () => void
}

type CategoryButtonGroupProps = {
  title: string
  buttons: CategoryButtonTypes[]
}

export const CategoryButtonGroup = ({ title, buttons }: CategoryButtonGroupProps) => {
  return (
    <div className='flex flex-col gap-2'>
      <Text variant='body_m_500'>{title}</Text>
      <div className='grid grid-cols-2 gap-3'>
        {buttons.map(button => (
          <EditorToolButton
            key={button.text}
            text={button.text}
            iconProps={button.iconProps as any}
            onClick={button.onClick}
          />
        ))}
      </div>
    </div>
  )
}
